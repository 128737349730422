$m: share_widget;
.#{$m} {
    @include border-box();

    margin: 48px 0 23px;

    @include min-screen($lg) {
        margin: 58px 15px 20px;
    }

    @include min-screen($xlg) {
        margin: 75px 15px 30px;
    }

    &__inner_wrap {
        @include border-box();
        position: relative;
        height: 100%;
        padding: 0 0 20px;
        background-color: $yellow_prim;
    }

    &__title {
        position: relative;
        top: -18px;
        width: 320px;
        margin: 0 auto;
        padding: 0;
        text-align: center;

        @include min-screen($sm) {
            width: 500px;
        }
    }

    &__title_highlight {
        position: relative;
        background: $grey_00;
        -webkit-box-decoration-break: clone;
                box-decoration-break: clone;
        font: $font_prim--bold;
        @include font-size(22);
        @include line-height(24);
        color: #fff;

        @include min-screen($lg) {
            @include font-size(28);
            @include line-height(28);
        }

        span {
            position: relative;
        }

        br {
            @include min-screen($lg) {
                display: none;
            }
        }
    }

    &__btn_wrap {

        text-align: center;

        .btn {

            @include max-screen($lg - 1) {
                &:first-child {
                    margin: 0 12px 12px;
                }
            }

            @include min-screen($lg) {
                margin: 0 13px;
            }
        }
    }
}
