$m: toaster;

.#{$m} {

    &__response {
        position: fixed;
        bottom: 0;
        left: -100%;
        z-index: 1000;
        display: block;
        width: 100%;
        margin: 0;
        padding: 26px 41px 26px 80px;
        box-sizing: border-box;
        -webkit-transition: left 0.3s ease-in-out;
                transition: left 0.3s ease-in-out;

        @include min-screen ($lg) {
            bottom: 15px;
            max-width: 500px;
            padding: 36px 41px 36px 80px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        }

        &.is_open {
            left: 0;

            @include min-screen ($lg) {
                left: 15px;
            }
        }

        &--warning {
            background: $red;
        }
    }

    &--btns {
        display: block;

        .btn {
            margin-top: 10px;
        }
    }

    &__response_icon {
        position: absolute;
        top: 50%;
        left: 15px;
        display: block;
        margin: -24px 0 0;
        width: 40px;
        height: 40px;
        text-align: center;

        [class^="icon-"], [class*=" icon-"] {
            @include font-size(48);
            color: #fff;
        }
    }

    &__response_text {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        font: $font_prim--light;
        @include font-size(16);
        @include line-height(20);
        color: #fff;

        @include min-screen ($lg) {
            @include line-height(24);
        }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        @include font-size(24);
        @include line-height(24);

        &:hover {
            cursor: pointer;
        }
    }
}
