$m: datapicker;
.#{$m} {

    position: relative;
    display: inline-block;
    padding: 0 20px 0 0;
    @include border-box();

    &__input {
        @include border-box();
        width: 100%;
        height: 40px;
        padding: 5px 10px;
        border: 1px solid #e9e9e9;
        color: $grey_00;
        @include font-size(15);
    }

    &__overlay {
        display: none;
        width: 311px;
        height: 310px;
    }
}

// Overrides
.gldp-default {

    position: absolute;
    top: 40px !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    display: block;
    width: 311px !important;
    height: 310px !important;
    background: #fff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    background: #fff;
    z-index: 2;

    .prev-arrow, .next-arrow {
        position: relative;
        display: block;
        width: 100%;
        text-indent: -9999px;

        &::after {
            @extend %iconsetup;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: block;
            content: "\e924";
            line-height: 4rem !important;
            line-height: 40px !important;
            color: #292929;
            text-align: center;
            text-indent: 0;
            text-shadow: none ;
        }
    }

    .next-arrow::after {content: "\e925" }

    .core.border.monyear {
        background: #fff;
        height: 40px !important;
        line-height: 4rem !important;
        line-height: 40px !important;

        &.title {
            color: #1e1e1e;
            font-size: 1.5rem !important;
            font-size: 15px !important;
            font-weight: 900 !important;
        }

        &::after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            height: 1px;
            background: #000;
        }
    }

    .core.border {
        position: relative;
        color: #1e1e1e;
        height: 40px !important;
        line-height: 4rem !important;
        line-height: 40px !important;
        background: transparent;

        &.outday {
            color: #ccc !important;

            .calendar--dark & {
                background: #f6f6f6;
            }
        }

        &.noday {
            color: #ccc;

            &::after {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: block;
                height: 1px;
                background: #e0e0e0;
            }

            .calendar--dark & {
                background: #f6f6f6;
            }
        }

        &.mon, &.tue, &.wed, &.thu, &.fri, &.sat, &.sun {

            &::after {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: block;
                height: 1px;
                background: #e0e0e0;
            }

            &:hover {
                color: #1197dd;
            }

            .calendar--dark & {
                background: #f6f6f6;
            }
        }

        &.today {

            .calendar--dark & {
                background: #f6f6f6;
            }

            &::before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: block;
                height: 1px;
                background: #e0e0e0;
            }

            &::after {
                content: " ";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                height: 1px;
                background: #e0e0e0;
            }
        }

        &.dow {
            text-transform: uppercase;
            color: #9c9c9c;

            background: #fff;
            height: 31px !important;
            line-height: 3rem !important;
            line-height: 30px !important;
            text-shadow: none !important;
        }

        &.selected {
            position: relative;
            background: transparent;
            color: #fff;
            font-weight: 900;
            text-shadow: none !important;

            .calendar--dark & {
                background: #f6f6f6;
            }

            &::before {
                content: " ";
                position: absolute;
                top: 5px;
                left: 5px;
                bottom: 5px;
                right: 5px;
                display: block;
                background: #1197dd;
                opacity: 0.75;
                z-index: -1;
            }

            &::after {
                content: " ";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                border-top: 1px solid #e0e0e0;
                border-bottom: 1px solid #e0e0e0;
            }
        }
    }
}
