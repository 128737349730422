$gw: gorenjska_widget;

.#{$gw} {

    @include min-screen($lg) {
        .container > .row > div[class^="gr-"]:nth-child(4n) {
            clear: left;
        }
    }

    &__card {
        margin-bottom: 30px;

        @include min-screen($lg) {
            margin-bottom: 60px;
        }

        &:hover {

            .#{$gw}__card_label {
                background-color: #5f1111;
            }
        }
    }

    &__link {
        display: block;
    }

    &__card_overtitle_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 12px;
        border-bottom: 4px solid $grey_04;

        @include min-screen($lg) {
            padding: 11px 0;
        }
    }

    &__card_overtitle {
        width: calc(100% - 100px);
        margin: 0;
        font: $font_prim--light;
        @include font-size(20);
        @include line-height(24);
        @include letter-spacing(-25);
        color: $grey_01;
        text-transform: uppercase;

        @include min-screen($lg) {
            @include font-size(24);
            @include line-height(33);
        }

        strong {
            font: $font_prim--bold;
            @include font-size(20);
            @include line-height(24);
            @include letter-spacing(-25);

            @include min-screen($lg) {
                @include font-size(24);
            }
        }
    }

    &__card_btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 33px;
        padding: 0 7px;
        background-color: $yellow_prim;
        font: $font_prim--bold;
        @include font-size(12);
        color: $grey_01;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__card_figure,
    &__iframe_wrap {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 51.25%;
        margin: 10px 0 0;
        background: grey;

        img, iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__iframe_wrap {
        background: $blue_widget;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include max-screen(368px) {
                transform: scale(0.88) translate(-57%, -57%)
            }

            @include screen($lg, $xlg) {
                transform: scale(0.88) translate(-57%, -57%)
            }
        }
    }

    &__card_text {
        padding: 0 12px;

        @include min-screen($lg) {
            padding: 0;
        }
    }

    &__card_label {
        display: inline-block;
        font: $font_prim--bold_it;
        @include font-size(12);
        @include line-height(21);
        color: $white;
        text-transform: uppercase;
        padding: 3px 10px 1px;
        margin: 20px 0 0;
        background-color: $kranj;
    }

    &__card_title {
        font: $font_prim--bold;
        @include font-size(22);
        @include line-height(26);
        color: $grey_01;

        @include min-screen($lg) {
            @include font-size(28);
            @include line-height(32);
            margin-top: 15px;
        }
    }

    &__card_description {
        font: $font_prim;
        @include font-size(15);
        @include line-height(20);
        color: $black;
        margin: 10px 0 0;
    }
}
