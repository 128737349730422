body.styleguide {
    margin-right: 34%;
}

.icons_wrap .icon,
.icons_wrap .mls {
    font-size: 18px;
}

.styleguide {
    background: #fff;

    @include min-screen($md) {
        padding: 20px 70px;
    }

    .column_sidebar--full {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &__title {
        text-transform: uppercase;
        margin: 20px 0;
        padding: 0;
    }

    &__separator {
        float: left;
        width: 100%;
        margin: 20px 0;
        border-bottom: 1px solid #e9e9e9;
    }

    .select {
        border: 1px solid #ccc;
    }

    &__btn_wrap {
        position: relative;
        padding: 15px;
        text-align: center;

        &--blue {
            background-color: #40bdea;
        }

        &--pink {
            background-color: #f067a6;
        }
    }

    .button {
        display: table;
        margin: 10px 0;
    }

    &__menu {
        width: 100%;
        height: 40px;
        background-color: $grey_03;
    }

    &__list {
        display: table;
        width: 100%;
        height: 40px;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &__list_item {
        display: table-cell;
        font: $font-prim;
        @include font-size(14);
        @include line-height(40);
        text-align: center;

        @include min-screen($lg) {
            @include font-size(16);
        }
    }

    &__list_link {
        display: block;
        cursor: pointer;
        background-color: transparent;
        transition: all 0.3s;

        &:hover {
            background-color: darken($grey_03, 3%);
        }
    }

    &__checkbox:checked + &__list_link {
        background-color: darken($grey_03, 3%);
    }

    &__section {
        display: none;
        visibility: hidden;
    }

    &__radio_select:checked + &__section {
        display: block;
        visibility: visible;
    }

    .column_content {

        .btn {
            margin-bottom: 15px;
            margin-right: 15px;
        }

        .btn--social {
            margin: 0 1px 0 0 !important;
        }

        .mls {
            margin-left: .25em;
        }

        .fs1, .fs2 {
            font-size: 24px;
        }

        pre {
            position: fixed;
            top: 100px;
            bottom: 0;
            z-index: 500;
            right: 0;
            margin-bottom: 0;
            width: 33%;
        }
    }
}
