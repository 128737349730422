.poll{

	padding: 20px;

	.alert__message {
		margin: 10px 0;
	}

	&__title {
		padding: 0;
		margin: 0;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;
	}

	&__item {
		@include clearfix();
		padding: 0 0 0 40px;
		margin: 10px 0 20px;

		&--answered {
			padding: 0 40px 0 0;
			margin: 10px 0;
		}
	}

	&__answer {

		&_title {
			padding: 2px 0;
			@include font-size(14);
		}

		&_count{
			float: right;
			color: #ccc;
			@include font-size(12);
			margin: 0 10px;
		}

		&_perc{
			float: right;
		}
	}

	&__bar {
		position: relative;
		background: #dedede;
		margin: 5px 0;
	}

	&__progressbar {
		height: 14px;
		background: $grey_00;
		@include transition(0.5s);
	}

	&__submit{
		position: absolute;
		left: -40px;
		top: -9px;
		height: 32px;
		width: 32px;
		@include border-radius(100%);
		border: 0;
		background: #1e1e1e;
		color: #fff;
		@include font-size(16);
		@include transition(0.5s);

		&:hover {
			background: $grey_04;
			color: $grey_00;
		}
	}

	&__percentage {
		position: absolute;
		width: 32px;
		right: -40px;
		top: -5px;
		@include font-size(17);
		text-align: right;
	}
}
