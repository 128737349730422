$ac: ui-accordion;
.#{$ac} {

    @include max-screen($lg - 1) {
        padding: 0 25px;
    }

    @include max-screen($sm - 1) {
        padding: 0 12px;
    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        @include font-size(17);
        @include line-height(32);
        border-radius: 0;
        background-color: transparent;
        height: 60px;
        margin: 0;
        padding: 0 56px 0 12px;
        cursor: pointer;

        @include min-screen($xs) {
            @include font-size(20);
        }

        @include min-screen($lg) {
            padding: 0;
            @include font-size(28);
            height: 100px;
            padding: 0 88px 0 45px;
        }

        &-collapsed {
            box-shadow: 0 1px 0 0 #e0e0e0;
        }

        &-active {
            box-shadow: none;
        }

        strong {
            font: $font_prim--bold;
        }

        em {
            font: $font_prim;
        }

        .ui-icon {
            display: block;
            width: 24px;
            height: 15px;
            background: url('/static/img/arrow.png') no-repeat center center;
            position: absolute;
            right: 18px;
            top: 25px;
            @include transition(0.2s);

            @include min-screen($lg) {
                right: 50px;
                top: 45px;
            }
        }

        .ui-icon-plus {
            transform: rotate(0deg);
        }

        .ui-icon-minus {
            transform: rotate(180deg);
        }
    }
    &__content {
        display: none;

        p, dl, ul {
            @include font-size(17);
            @include line-height(30);
            margin: 0 0 30px;
            padding: 0;
        }

        dd, dt, li {
            padding: 0;
            margin: 0;
        }

        dt {
            font: $font_prim--bold;
            text-transform: uppercase;
        }

        &-active {
            box-shadow: 0 1px 0 0#e0e0e0;
        }
    }

    &__gutter {
        padding: 0 12px 0.5px;
        
        @include min-screen($lg) {
            padding: 0 45px 0.5px;
        }
    }
}
