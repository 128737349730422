// Flatpages (info/static stranice)
$m: flatpages;

.#{$m} {

    margin: 0 -2px;

    @include min-screen($sm) {
        padding: 0 15px;
    }

    @include min-screen($lg) {
        padding: 0 120px 60px;
    }

    .main_tile {

        margin: 29px 0 0 0;
        @include min-screen($lg) {
            margin: 40px 0 0 0;
        }
    }

    .main_label {

        @include max-screen($lg - 1) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__content {
        @include max-screen($lg - 1) {
            margin: 0 -15px;
            padding-bottom: 20px;
        }

        @include min-screen($lg) {
            margin: 0 -15px;
            padding-bottom: 40px;
        }
    }

    &__leadblock {
        p {
            margin: 0;
            padding: 20px 0 20px;
            color: $grey_00;
            font: $font_sec--light_it;
            @include font-size(20);
            @include line-height(28);

            a {
                text-decoration: none;
            }

            @include min-screen($lg) {

                padding: 15px 0 30px;
                @include font-size(24);
                @include line-height(38);

                a {
                    position: relative;
                    color: $grey_00;
                    box-shadow: inset 0 -4px 0 $grey_04;

                    @media screen and (-webkit-min-device-pixel-ratio:0) {
                        box-shadow: inset 0 -3px 0 $grey_04;
                    }

                    &:hover {
                        text-decoration: none;
                        transition: 0.2s ease-in-out;
                        box-shadow: inset 0 -4px 0 $grey_03;

                        @media screen and (-webkit-min-device-pixel-ratio:0) {
                            box-shadow: inset 0 -3px 0 $grey_03;
                        }
                    }
                }
            }
        }
    }

    h2 {
        @include font-size(21);
    }

    h3 {
        margin: 30px 0 14px 0;
        font: $font_prim--bold;
        @include font-size(16);
        @include line-height(24);

        @include min-screen($lg) {
            @include font-size(18);
            @include line-height(26);
        }
    }

    p:not(.flatpages__lead_text),
    li,
    ol {
        display: inline-block;
        width: 100%;
        margin: 9px 0 0;
        font: $font_prim;
        @include font-size(16);
        @include line-height(28);

        @include min-screen($lg) {
            margin: 9px 0 0;
            @include font-size(16);
            @include line-height(31);
        }

        a {
            text-decoration: none;
            position: relative;
            color: $grey_00;
            box-shadow: inset 0 -4px 0 $grey_04;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_04;
            }

            &:hover {
                text-decoration: none;
                transition: 0.2s ease-in-out;
                box-shadow: inset 0 -4px 0 $grey_03;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_03;
                }
            }
        }
    }

    ul, ol {
        margin: 0;
        padding: 0 20px;
        @include min-screen($lg) {
            padding: 0 0 0 20px;
        }
    }

    ul li,
    ol li {
        display: list-item;
        margin: 0;
        padding: 16px 0 0;
        @include min-screen($lg) {
            padding: 19px 12px 0;
        }
    }

    ul + p:not(.flatpages__lead_text),
    ol + p:not(.flatpages__lead_text) {
        @include min-screen($lg) {
            margin-top: 30px;
        }
    }

    table {
        border: 0;
        width: 100% !important;
        tbody {
            border-color: #ccc;
        }
        td {
            padding: 5px;
        }
    }

    &__sidebar {
        width: 330px;
        margin: 20px auto;

        @include min-screen($lg) {
            margin: 0 auto;
        }
    }

    &__image {

        width: 100%;
        height: 325px;
        margin: 30px 0 50px 0;

        @include min-screen($lg) {
            height: 550px;
            margin: 0;
        }

        &--404 {
            background: #fff no-repeat center top / contain;
        }
    }
}

.cookie_table {

    @include max-screen($md - 1) {
        overflow-x: scroll;
    }

    table {
        width: 100%;
        min-width: 500px;
        @include font-size(12);
        background: lighten(#d06e4c, 20%);
    }

    th, td {
        padding: 5px;
        border: 1px solid #d06e4c;
    }

    th {
        text-align: left;
    }

    tr th {
        text-align: left;
    }

    tr:first-child th {
        text-align: center;
        background: #d06e4c;
    }
}

.radiobutton {

    &--group {
        margin: 0 0 30px;
    }

    &--inline {
        display: inline-block;
        margin-right: 20px;
    }
}

.didomi_preferences {
    z-index: 10;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: auto;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 74px;
    margin: 20px -10px 0;
    padding: 20px 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.26), 0px -2px 8px 0px rgba(0, 0, 0, 0.13);
    font: $font_prim;
    @include font-size(15);
    @include line-height(24);
    @include letter-spacing(0);
    background-color: #f4f4f4;

    @include min-screen($lg) {
        flex-direction: row;
        width: 100%;
        margin: 40px 0 0;
        padding: 0 20px;
    }

    &.sticky {
        top: auto;
        bottom: 0;
    }

    &--text {
        display: block;
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: 13px;
        text-align: center;

        @include min-screen($lg) {
            margin-bottom: 0;
            text-align: left;
        }
    }

    &--button {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        box-sizing: border-box;
        height: 28px;
        padding: 0 14px;
        font: $font_prim;
        text-align: center;
        color: #fff !important;
        @include font-size(13);
        @include line-height(28);
        @include letter-spacing(0);
        background-color: #1197dd;
        cursor: pointer;
    }
}
