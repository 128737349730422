.epicfail__fold {
    position: relative;

    .column_sidebar {
        position: static;
    }
}

$m: epicfail_widget;
.#{$m} {

    position: relative;
    margin: 0 0 40px 0;
    background-color: #f4f4f4;

    @include min-screen($lg) {
        margin: 0;
    }

    &__label {
        @include border-box();
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        background-color: $grey_00;

        @include min-screen($sm) {
            left: 0;
        }

        @include min-screen($xlg) {
            width: 130px;
        }
    }

    &__label_wrap {
        position: relative;
        top: 29px;
        display: block;
        height: 87px;
        width: 40px;

        span {
            position: absolute;
            left: 0;
            display: block;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(40);
            @include letter-spacing(25);
            color: #fff;
            text-transform: uppercase;
            -webkit-transform-origin: left top;
            transform-origin: left top;
            -moz-transform: rotate(-90deg) translateX(-100%) translateY(140%);
            -webkit-transform: rotate(-90deg) translateX(-100%) translateY(140%);
            transform: rotate(-90deg) translateX(-100%) translateY(0%);
            white-space: nowrap;
        }

        @include min-screen($sm) {

            span {
                @include line-height(42);
            }
        }

        @include min-screen($xlg) {
            top: 39px;
            left: 67px;
            width: 50px;
            height: 239px;

            span {
                @include font-size(50);
                @include line-height(50);
                @include letter-spacing(25);
            }
        }
    }

    &__inner_wrap {
        padding: 30px 14px 30px 40px;

        @include screen($sm, $lg - 1) {
            padding: 30px 30px 30px 40px;
        }

        @include min-screen($lg) {
            padding: 30px 30px 30px 40px;
        }

        @include min-screen($xlg) {
            padding: 40px 40px 40px 130px;
        }
    }

    &__list {
        position: relative;
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include clearfix();

        &:before {
            @include border-box();
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 15px;
            border-right: 3px solid $red;
            border-bottom: 3px solid $red;

            @include screen($sm, $lg - 1) {
                left: 30px;
            }

            @include min-screen($lg) {
                left: 30px;
            }

            @include min-screen($xlg) {
                left: 40px;
            }
        }
    }

    &__list_item {
        position: relative;
        @include border-box();
        padding: 0 14px;
        margin: 0 0 24px 0;

        @include min-screen($xlg) {
            margin: 0 0 29px 0;
        }

        &:first-child {
            margin: 23px 0 20px 0;
            padding: 15px 14px;
            background-color: $red;

            @include min-screen($xlg) {
                margin: 23px 0 23px 0;
            }
        }
    }

    &__article_link {
        display: block;
    }

    &__article_overtitle {
        position: relative;
        left: -14px;
        display: inline-block;
        height: 23px;
        padding: 0 10px 0 14px;
        background-color: #e0e0e0;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(24);
        @include letter-spacing(100);
        color: $grey_00;
        text-transform: uppercase;

        @include screen($sm, $lg - 1) {
            padding: 0 10px 0 30px;
        }

        @include min-screen($lg) {
            padding: 0 10px 0 30px;
        }

        @include min-screen($xlg) {
            padding: 0 10px 0 40px;
        }

        .epicfail_widget__list_item:first-child & {
            position: absolute;
            top: -23px;
            left: 0;
            background-color: $yellow_prim;
        }
    }

    &__article_title {
        padding: 0;
        margin: 6px 0 0 0;
        font: $font_prim--bold;
        @include font-size(16);
        @include line-height(22);
        @include letter-spacing(-10);
        color: $grey_00;

        @include screen($sm, $lg - 1) {
            padding: 0 0 0 15px;
        }

        @include min-screen($lg) {
            padding: 0 10px 0 15px;
        }

        @include min-screen($xlg) {
            padding: 0 10px 0 25px;
        }

        @include min-screen($xlg) {
            @include font-size(20);
        }

        .epicfail_widget__list_item:first-child & {
            margin: 0;
            @include font-size(20);
            @include line-height(26);
            color: #fff;

            @include min-screen($lg) {
                @include font-size(36);
                @include line-height(41);
            }

            @include min-screen($xlg) {
                @include font-size(48);
                @include line-height(54);
            }
        }
    }
}
