.vote {

	&__icon {
		display: inline-block;
		margin: 0 2px 0 0;
		padding: 0;
		@include font-size(20);
		cursor: pointer;
		@include transition(0.3s);

		&.marked {
			color: $grey_00 !important;
		}

		&.empty {
			color: #ccc;
		}
	}
}
