// ********************************
// includes/_experimental.scss
// ********************************

@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function vwWidth ( $value, $context){
    @return ( stripUnit($value) / $context * 100 ) + 0vw;
}

@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);

    @if ($length > 1) {

        $rest: ();
        @for $i from 2 through $length {
                $rest: append($rest, nth($keys, $i))
        }
        @return map-fetch($value, $rest)

    } @else {

        @return $value ;
    }
}

@mixin v-font-size ( $fsize: 30px, $context: 1200){
    font-size: $fsize;

    @media screen and ( max-width: ($context + 0px)){
        font-size: vwWidth($fsize, $context);
    }
}

@mixin vwlh-size ( $size, $context: 1200 ){
    line-height: $size;

    @media screen and ( max-width: ($context + 0px)){
        line-height: vwWidth($size, $context);
    }
}

@mixin rem($property, $values) {

    // Create a couple of empty lists as output buffers.
    $font-size: $base-font-size;
    $px-values: ();
    $rem-values: ();

    // Loop through the $values list
    @each $value in $values {

        // For each property value, if it's in rem or px, derive both rem and
        // px values for it and add those to the end of the appropriate buffer.
        // Ensure all pixel values are rounded to the nearest pixel.
        @if $value == 0 or $value == 0px {

            // 0 -- use it without a unit
            $px-values: join( $px-values, 0 );
            $rem-values: join( $rem-values, 0 );

        } @else if type-of( $value ) == number and not unitless( $value ) and ( unit( $value ) == px) {

            // px value given - calculate rem value from font-size
            $new-rem-value: $value / $font-size;
            $px-values: join( $px-values, round( $value ));
            $rem-values: join( $rem-values, #{$new-rem-value}rem );

        } @else if type-of( $value ) == number and not unitless( $value ) and ( unit( $value ) == "%") {

            // % value given - don't add px or rem
            $px-values: join( $px-values, #{$value} );
            $rem-values: join( $rem-values, #{$value} );

        } @else if $value == auto {

            // auto - don't add px or rem
            $px-values: join( $px-values, auto );
            $rem-values: join( $rem-values, auto );

        } @else {

            // unitless value - use those directly as rem and calculate the px-fallback
            $px-values: join( $px-values, round($value * $font-size) );
            $rem-values: join( $rem-values, #{$value}rem );

        }
    }

    // output the converted rules
    #{$property}: $px-values;
    #{$property}: $rem-values;
}

// Mixin that allows to specify arbitrary CSS properties with
// unitless numbers. The output has rem unit with pixel fallback.
// Shorthand assignments are supported too!
$base_line: 10;

@mixin remNew($property, $values) {
    // Placeholder variables
    $shorthand_px:  "";
    $shorthand_rem: "";

    // Parameter $values might be a list of elements
    @each $value in $values
    {
        // Current value is a valid number and greater than 0
        @if $value != auto and $value != 0
        {
            // Add 'px' and 'rm' to the current value and store in
            // placeholder variables
            $shorthand_px: #{ $shorthand_px + " " + $value * $base_line + px };
            $shorthand_rem: #{ $shorthand_rem + " " + $value + rem };
        }
        // Current value is 'auto' or 0
        @else
        {
            // Add only 'auto' or 0 to the placeholder variables
            $shorthand_px: #{ $shorthand_px + " " + $value };
            $shorthand_rem: #{ $shorthand_rem + " " + $value };
        }
    }
    // Output the CSS property in pixels and rems
    #{$property}:$shorthand_px;
    #{$property}:$shorthand_rem;
}

@mixin gradmask () {
    position: relative;

    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include gradient(rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.15));
    }
}

// keyframes mixin
// @include keyframes(slideup) {}
@mixin keyframes($name) {

    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

@mixin line-clamp($maxLines:2, $lineHeight:$line-height, $fgCol:#000, $bgCol:#fff) {

    @if (true) {

        @extend %line-clamp-common; // ne radi u mediaqueriu
        max-height: $maxLines * $lineHeight * 1px;

        .webkitlineclamp & {
            @extend %line-clamp-webkit; // ne radi u mediaqueriu
            -webkit-line-clamp: $maxLines;
        }

        html:not(.webkitlineclamp) & {

            @extend %line-clamp-notwebkit; // ne radi u mediaqueriu
            line-height: $lineHeight * 1px;

            &:before {

                @extend %line-clamp-before; // ne radi u mediaqueriu
                height: $maxLines * $lineHeight * 1px;
            }

            > span {
                @extend %line-clamp-strong; // ne radi u mediaqueriu
            }

            &:after {

                @extend %line-clamp-after; // ne radi u mediaqueriu
                top: -$lineHeight * 1px;
            }
        }
    }
}

%line-clamp-common {

    position: relative;
    overflow: hidden;

    .dev & {
        overflow: visible;
    }
}

%line-clamp-webkit {

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

%line-clamp-notwebkit {

    overflow: hidden;

    .dev & {
        overflow: visible;
    }
}

%line-clamp-before {

    content: " ";

    &.lineclamp {
        float: left;
    }

    width: 5px;

    .dev {
        background: rgba(255, 0, 0, 0.3);
    }
};

%line-clamp-strong {

    float: right;
    width: 100%;
    margin-left: -5px;
    font-weight: normal;

    .dev {
        background: rgba(0, 0, 255, 0.3);
    }
}

%line-clamp-after {

    content: "\02026";

    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;

    float: right;
    position: relative;
    left: 100%;

    width: 40%;
    margin-left: -40%;
    padding-right: 5px;

    text-align: right;

    background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);

    .dev {
        background: rgba(255, 0, 0, 0.3);
    }
}

/* ***********************************************
**********     OVO JE LG VARIJANTA     ***********
*********************************************** */
@mixin line-clamp-lg($maxLines:2, $lineHeight:$line-height, $fgCol:#000, $bgCol:#fff) {

    @if (true) {

        @extend %line-clamp-common-lg; // ne radi u mediaqueriu
        max-height: $maxLines * $lineHeight * 1px;  /* $maxLines * $lineHeight */

        .webkitlineclamp & {
            @extend %line-clamp-webkit-lg; // ne radi u mediaqueriu
            -webkit-line-clamp: $maxLines;
        }

        html:not(.webkitlineclamp) & {

            @extend %line-clamp-notwebkit-lg; // ne radi u mediaqueriu
            line-height: $lineHeight * 1px;

            &:before {

                @extend %line-clamp-before-lg; // ne radi u mediaqueriu
                height: $maxLines * $lineHeight * 1px;
            }

            > span {
                @extend %line-clamp-strong-lg; // ne radi u mediaqueriu
            }

            &:after {

                @extend %line-clamp-after-lg; // ne radi u mediaqueriu
                top: -$lineHeight * 1px;
            }
        }
    }
}

@include min-screen($lg) {

    %line-clamp-common-lg {

        position: relative;
        overflow: hidden;

        .dev & {
            overflow: visible;
        }
    }

    %line-clamp-webkit-lg {

        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    %line-clamp-notwebkit-lg {

        overflow: hidden;

        .dev & {
            overflow: visible;
        }
    }

    %line-clamp-before-lg {

        content: " ";

        &.lineclamp {
            float: left;
        }

        width: 5px;

        .dev {
            background: rgba(255, 0, 0, 0.3);
        }
    };

    %line-clamp-strong-lg {

        float: right;
        width: 100%;
        margin-left: -5px;
        font-weight: normal;

        .dev {
            background: rgba(0, 0, 255, 0.3);
        }
    }

    %line-clamp-after-lg {

        content: "\02026";

        box-sizing: content-box;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;

        float: right;
        position: relative;
        left: 100%;

        width: 40%;
        margin-left: -40%;
        padding-right: 5px;

        text-align: right;

        background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
        background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
        background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);

        .dev {
            background: rgba(255, 0, 0, 0.3);
        }
    }
}

@import './i_flexbox';
