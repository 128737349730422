$m: yellow_journalism;
.#{$m} {
    @include border-box();
    padding: 14px;

    @include screen($sm, $lg - 1) {
        padding: 14px 30px;
    }

    @include min-screen($lg) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        @include border-box();
        width: 330px;
        padding: 0 15px;
        margin: 0;
    }

    &__inner_wrap {
        position: relative;
        @include border-box();
        padding: 21px 20px;
        background-color: $grey_00;
        text-align: center;

        @include screen($sm, $lg - 1) {
            padding: 21px 30px;
        }

        @include min-screen($lg) {
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            left: 15px;
        }
    }

    &__data {
        @include min-screen($lg) {
            display: table;
            height: 100%;
        }
    }

    &__title {
        padding: 0;
        font: $font_prim--bold;
        @include font-size(36);
        @include line-height(44);
        @include letter-spacing(-10);
        color: #fff;
        text-transform: uppercase;

        @include min-screen($lg) {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .btn {
        display: block;
        margin: 21px 0 9px 0;

        &:hover {
            background-color: $blue_prim;
            color: $grey_00;
        }

        @include min-screen($lg) {
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 20px;
            margin: 0;
        }

        @include min-screen($xlg) {
            height: 50px;
            @include font-size(16);
            @include line-height(50);
        }
    }
}
