$m: newsletter;
.#{$m} {
    margin-top: 30px;

    .#{$m}__form_wrap {
        box-sizing: border-box;
        margin: 0 -5px;

        @include min-screen($xlg) {
            padding-right: 22px;
            float: left;
            width: 428px;
            margin: 27px 0 0;
        }
    }

    .#{$m}__content {
        text-align: center;

        @include min-screen($xlg) {
            text-align: left;
        }
    }

    .#{$m}__title {

        margin: -6px 5px 0;
        padding: 0 0 10px;
        border-bottom: 4px solid $grey_04;
        font: $font_prim--bold;
        @include font-size(28);
        @include letter-spacing(-25);
        color: $grey_01;
        text-transform: uppercase;
        text-align: center;

        @include max-screen($lg - 1) {
            margin: -8px 15px 0;
            padding: 0 0 12px;
            @include font-size(24);
        }

        @include max-screen($sm - 1) {
            margin: -8px 5px 0;
            padding: 0 0 12px;
        }

        @include min-screen($xlg) {
            margin: -6px 0 0;
            text-align: left;
            @include font-size(36);
        }
    }

    .#{$m}__text {

        margin: 19px 0 20px;
        font: $font_sec--light_it;
        @include font-size(18);
        @include line-height(22);
        @include letter-spacing(10);
        color: $grey_01;

        @include min-screen($sm) {
            margin: 20px 100px 20px;
        }

        @include min-screen($lg) {
            margin: 18px 265px 19px;
            @include font-size(22);
            @include line-height(28);
        }

        @include min-screen($xlg) {
            margin: 17px 0 0;
        }
    }

    .#{$m}__form {
        display: flex;
        position: relative;
        margin: 0 5px;

        @include min-screen($sm) {
            margin: 0 100px;
        }

        @include min-screen($lg) {
            margin: 0 265px;
        }

        @include min-screen($xlg) {
            margin: 19px 0 -20px;
        }

        .form__row {
            flex: 100 0;
            @include min-screen($lg) {
                flex: 100 0;
            }
        }

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            flex: 1 0;

            @include min-screen($lg) {
                position: static;
                flex: 1 0;
            }
        }

        &.is_hidden {
            display: none;
        }
    }

    .#{$m} .form__textfield {
        background: $grey_05;
        @include font-size(17);
        @include letter-spacing(0);
        font: $font_prim;
        @include font-size(15);
        color: $grey_01;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $grey_02;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $grey_02;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $grey_02;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $grey_02;
        }
    }

    .#{$m}__confirmation {

        box-sizing: border-box;
        padding: 12px 10px 10px;
        margin: 20px 5px 40px;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(16);
        @include letter-spacing(100);
        color: $grey_01;
        border: 1px solid $blue_prim;
        background: $grey_05;
        text-transform: uppercase;

        &.is_hidden {
            display: none;
        }

        @include min-screen($sm) {
            margin: 20px 100px 40px;
        }

        @include min-screen($sm) {
            margin: 20px 266px 40px;
        }

        @include min-screen($xlg) {
            width: 100%;
            margin: 20px 0 0;
        }
    }
}

.#{$m} {

    .#{$m}__cards {
        margin: 12px 0 0 8px;
        font-size: 0;

        @include min-screen($lg) {
            margin: 6px 0 0 0;
            display: flex;
        }

        @include min-screen($xlg) {
            float: left;
            width: 842px;
            margin: 42px 0 0 8px;
        }

        .card {
            overflow: visible;
        }

        @include max-screen($lg - 1) {
            margin: -4px -15px 0 ;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;

            .card {
                float: none;
                display: inline-block;
                margin-top: 12px;
                margin-right: 12px;

                &:first-child {
                    margin-left: 25px;
                }

                &:last-child {
                    margin-right: 25px;
                }
            }

            .card__data {
                white-space: normal;
            }
        }

        @include max-screen($sm - 1) {
            .card {

                &:first-child {
                    margin-left: 14px;
                }

                &:last-child {
                    margin-right: 14px;
                }
            }
        }
    }
}
