
$m_b: breaking;
$m_t: ticker;

.#{$m_b} {

    position: relative;
    height: 24px;
    background: $yellow_sec;
    background: linear-gradient(to right, $yellow_sec 50%, $yellow_prim 50%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+

    &__wrap {
        @include max-screen($lg - 1) {

            width: 100%;
            max-width: $sm;
            margin: 0 auto;
        }
    }

    &__txt{
        display: none;
    }

    &__content {
        height: 24px;
        position: relative;
        padding: 0 15px;
    }

    &__label {
        display: block;
        position: absolute;
        left: 15px;
        top: 0;
        z-index: 10;
        height: 100%;
        padding-right: 10px;
        font: $font_prim--it;
        @include font-size(11);
        @include line-height(26);
        text-transform: uppercase;
        background: $yellow_sec;

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            right: -40px;
            width: 40px;
            height: 100%;
            background: rgba($yellow_prim, 0.7);
            background: linear-gradient(to right, $yellow_prim, rgba($yellow_prim, 0));
        }
    }
}

.#{$m_t} {

    position: relative;
    background: $yellow_prim;
    overflow: hidden;

    &:after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        width: 40px;
        height: 100%;
        background: rgba($yellow_prim, 0.7);
        background: linear-gradient(to left, $yellow_prim, rgba($yellow_prim, 0));
    }


    &__list_container {
        height: 24px;
        white-space: nowrap;
        overflow: hidden;
    }

    &__list {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
    }

    &__item {
        display: inline-block;
    }

    &__link {
        display: block;
        padding: 0 10px;
        font: $font_prim--med;
        @include font-size(12);
        @include line-height(24);
    }
}

.#{$m_b}--full {

    .#{$m_b} {
        height: auto;
        background: $yellow_sec;
        background: linear-gradient(to right, $yellow_sec 50%, $yellow_prim 50%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+

        &:before {
            display: none;
        }
    }

    .#{$m_b} {

        &__wrap {
            @include max-screen($lg - 1) {

                width: 100%;
                max-width: $sm;
                margin: 0 auto;
            }
        }

        &__content {
            height: 24px;
            position: relative;
            padding: 0 15px;

            @include min-screen($lg) {
                height: 44px;
            }

            @include min-screen($xlg) {
                height: 48px;
            }
        }

        &__label {
            display: block;
            position: absolute;
            left: 12px;
            top: 0;
            z-index: 10;
            height: 100%;
            padding-right: 10px;
            font: $font_prim--it;
            @include font-size(11);
            @include line-height(26);
            text-transform: uppercase;
            background: $yellow_sec;

            @include min-screen($lg) {
                left: 15px;
                top: 0;
                @include font-size(28);
                @include line-height(44);
                @include letter-spacing(-25);
            }

            @include min-screen($xlg) {
                @include font-size(36);
                @include line-height(48);
            }

            &:after {
                content: " ";
                position: absolute;
                top: 0;
                right: -40px;
                width: 40px;
                height: 100%;
                background: rgba($yellow_prim, 0.7);
                background: linear-gradient(to right, $yellow_prim, rgba($yellow_prim, 0));
            }

            strong {
                font: $font_prim--bold_it;
                @include font-size(11);
                @include line-height(24);

                @include min-screen($lg) {
                    @include font-size(28);
                    @include line-height(44);
                }

                @include min-screen($xlg) {
                    @include font-size(36);
                    @include line-height(48);
                }
            }
        }

        &__txt {
            display: block;
            background: $grey_01;
        }

        &__title {
            display: block;
            padding: 13px 0 19px;
            color: $white;
            font: $font_prim--bold;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-25);
            text-align: center;

            @include min-screen($lg) {
                padding: 15px 0 17px;
                @include font-size(36);
                @include line-height(40);
            }

            @include min-screen($xlg) {
                padding: 10px 0 18px;
                @include font-size(48);
                @include line-height(52);
            }
        }
    }

    .#{$m_t} {
        position: relative;
        background: $yellow_prim;
        overflow: hidden;

        &:before {
            display: none;
        }

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            background: rgba($yellow_prim, 0.7);
            background: linear-gradient(to left, $yellow_prim, rgba($yellow_prim, 0));
        }

        &__list_container {
            height: 24px;
            white-space: nowrap;
            overflow: hidden;

            @include min-screen($lg) {
                height: 44px;
            }

            @include min-screen($xlg) {
                height: 48px;
            }
        }

        &__list {
            display: inline-block;
            list-style-type: none;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
        }

        &__item {
            display: inline-block;
        }

        &__link {
            display: block;
            padding: 0 10px;
            font: $font_prim--med;
            @include font-size(12);
            @include line-height(24);

            @include min-screen($lg) {
                @include font-size(14);
                @include line-height(44);
            }

            @include min-screen($xlg) {
                @include line-height(48);
            }
        }
    }
}
