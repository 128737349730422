.tt-dropdown-menu{
	background: #fff;
	width: 200px;
	@include border-box();
	padding: 0;
	background: #f1f1f1;
	border: 1px solid #e1e1e1;
}

.tt-suggestion{
	border-bottom: 1px solid #e1e1e1;
	@include font-size(14);
	@include line-height(20);
	color: $grey_00;
	text-transform: uppercase;
	padding: 0 20px;

	&:hover {
		background: #e9e9e9;
	}
}
