$side__width: 300px;
$slider__top: 70px;
$slider__bottom: 90px;
$slider__width: 943px;
$slider__height: 629px;
$slider__thumbs__height: 48px;
$slider__controllers: 170px;

@import "../includes/i_royalslider";
@import "../includes/i_royalslider_overides";

.standalone_gallery {

    .header_wrapper {
        @include max-screen($lg) {
            width: 100%;
            margin: 0;
            left: 0;
        }
    }

    .body_wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
    }

    .container {
        @include screen($sm, $lg - 1) {
            max-width: 100%;
        }

        &--content {
            @include min-screen($xlg) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: $xlg;
                height: 100%;
            }
        }
    }

    .header__logo {
        float: left;
    }

    .column_content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include min-screen($xlg) {
            right: 330px;
        }
    }

    .column_content__inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include min-screen($lg) {
            margin: 0;
        }

        @include min-screen($xlg) {
            margin: 0 330px 0 0;
        }
    }

    .column_sidebar {
        display: none;

        @include min-screen($xlg) {
            display: block;
            margin: 70px 0 0 0;
        }
    }
}

$m: gallery;

.#{$m} {

    @include border-box();
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 1260px;
    height: 100% !important;
    z-index: 1;

    @include min-screen($xlg) {
        max-width: 980px;
    }

    &__title {
        display: none;

        @include min-screen($lg) {
            display: inline-block;
            position: absolute;
            z-index: 0;
            @include border-box();
            width: 100%;
            padding: 0 300px;
            margin: 0;
            float: left;
            font: $font_prim--bold;
            @include font-size(21);
            @include line-height(56);
            color: $white;
            text-align: center;
            // Ogranicavanje fonta na 1 red:
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            max-height: 61px;
            overflow: hidden;
        }

        @include min-screen($xlg) {
            @include font-size(22);
            @include line-height(65);
        }
    }

    &__close_btn {
        display: block;
        width: 56px;
        height: 56px;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        opacity: 1;

        .icon {
            display: block;
            @include font-size(24);
            @include line-height(56);
            color: $white;
            text-align: center;
        }

        @include min-screen($lg) {
            top: 8px;
            right: 15px;
            width: 40px;
            height: 40px;
            background-color: $yellow_prim;
            @include transition(0.3s);

            &:active,
            &:hover {
                top: 8px;
            }

            &:hover {
                background-color: $yellow_sec;

                .icon {
                    color: $grey_06;
                }
            }

            .icon {
                color: $grey_01;
                @include font-size(20);
                @include line-height(40);
            }
        }

        @include min-screen($xlg) {
            top: 12px;

            &:active,
            &:hover {
                top: 12px;
            }
        }
    }

    &__social_wrap {
        position: fixed;
        bottom: 10px;
        left: 10px;

        @include min-screen($lg) {
            position: absolute;
            top: 8px;
            right: 85px;
            bottom: auto;
            left: auto;
        }

        @include min-screen($xlg) {
            top: 12px;
        }

        .btn {
            width: 40px;
            height: 40px;
            padding: 0;

            &:first-child {
                margin: 0 10px 0 0;
            }
        }

        .icon {
            display: block;
            @include line-height(40);
            @include font-size(24);
            text-align: center;
        }
    }

    &__functions {
        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        padding: 0 15px;
        @include border-box();
        background-color: $grey_00;
        z-index: 2;

        @include min-screen($lg) {
            position: absolute;
            height: 48px;
            top: 551px;
        }

        @include min-screen($xlg) {
            top: 660px;
            bottom: auto;
            right: 19px;
        }
    }

    &__paginator {
        float: left;
        font: $font-sec;
        @include font-size(14);
        @include line-height(40);
        color: #fff;
        font-variant-numeric: lining-nums;
        -moz-font-feature-settings: "lnum" 1;
        -moz-font-feature-settings: "lnum=1";
        -ms-font-feature-settings: "lnum" 1;
        -o-font-feature-settings: "lnum" 1;
        -webkit-font-feature-settings: "lnum" 1;
        font-feature-settings: "lnum" 1;

        @include min-screen($lg) {
            @include line-height(48);
        }
    }

    &__controls_wrap {
        width: 57px;
        height: 40px;
        float: right;

        @include min-screen($lg) {
            height: 48px;
        }
    }

    &__control_btn {
        display: inline-block;
        width: 20px;
        @include transition(0.2s);

        &--prev {
            float: left;
        }

        &--next {
            float: right;
        }

        &.is_hidden {
            opacity: 0.4;
        }

        .icon {
            color: #fff;
            @include font-size(22);
            @include line-height(40);

            @include min-screen($lg) {
                @include line-height(48);
            }
        }
    }

    // odmak od headera ili vrha i dna site-a
    &__slider_wrap {
        position: absolute;
        top: 74px;
        bottom: 60px;
        left: 0;
        right: 0;
        margin: auto;

        @include min-screen($xlg) {
            top: 100px;
            left: 0;
            right: 0;
        }
    }

    &__caption {
        @include border-box();
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        min-height: 40px;
        padding: 10px 15px 10px 50px;
        background-color: $grey_00;
        text-align: left;
        white-space: normal;

        .icon {
            position: absolute;
            left: 14px;
            @include font-size(22);
            @include line-height(20);
            color: #fff;
        }
    }

    &__photo_desc {
        font: $font_sec;
        @include font-size(14);
        @include line-height(20);
        @include letter-spacing(50);
        color: #fff;
    }

    &__photo_desc + &__photo_author {
        position: relative;
        padding: 0 0 0 20px;

        &:before {
            content: "-";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 6px;
            line-height: 14px;
        }
    }

    &__photo_author {
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(20);
        @include letter-spacing(100);
        color: #fff;
        text-transform: uppercase;
    }

    &__slider {
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        left: 10px;
        width: auto;
        height: auto;
        margin: auto;
        z-index: 1;

        @include min-screen($lg) {
            left: 0;
            right: 0;
        }

        @include min-screen($xlg) {
            bottom: auto;
            width: 943px;
            height: 629px;
        }

        &_item {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            font-size: 0;

            .rsVideoContainer {
                margin-left: 0 !important;
                width: 100% !important;
                height: 100% !important;
            }

            small {
                font-size: 100%;
            }

            img {
                margin-left: 0 !important;
            }
        }

        .rsThumbs {
            margin-left: -4px;

            @include min-screen($lg) {
                width: 100%;
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
            }

            @include min-screen($xlg) {
                width: $slider__width + 4;
                margin-left: -4px;
                margin-right: auto;
                margin-top: 10px;
            }
        }
    }
}
