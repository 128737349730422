$m: author;
.#{$m} {
    &__infobox {

        position: relative;
        margin: 27px 0;
        text-align: left;
        padding: 12px 12px 18px 0;
        background: $grey_01;

        @include min-screen($lg) {
            margin: 27px 0;
            padding: 12px 12px 27px 0;
        }

        @include min-screen($xlg) {
            margin: 40px 0;
            padding: 20px 20px 20px 0;
        }
    }

    &__infobox_figure {

        float: left;
        margin-right: 10px;
        padding-left: 6px;
        width: 80px;
        height: 80px;
        background: $yellow_prim;

        @include min-screen($xlg) {
            margin-right: 20px;
            padding-left: 12px;
            width: 120px;
            height: 120px;
        }
    }

    &__infobox_img {

        width: 100%;
        height: auto;
    }

    &__infobox_data {
        display: inline-block;

        @include min-screen($lg) {
            padding: 0 0 0 90px;
        }
    }

    &__title {
        display: inline-block;
        color: $white;
        text-align:left;
    }

    &__infobox_label {
        display: block;
        margin-top: 21px;
        font: $font_prim--bold;
        @include font-size(12);
        @include letter-spacing(100);
        color: $grey_03;
        text-transform: uppercase;

        @include min-screen($lg) {
            margin-top: 13px;
        }

        @include min-screen($xlg) {
            margin-top: 10px;
        }
    }


    &__infobox_name {

        display: inline-block;
        position: relative;
        font: $font_sec;
        padding: 0;
        margin: 9px 0 0;
        text-transform: none;
        @include font-size(16);
        @include line-height(20);
        @include letter-spacing(100);
        color: $white;

        @include min-screen($lg) {
            margin-top: 9px;
        }

        @include min-screen($xlg) {
            margin-top: 5px;
            @include font-size(22);
            @include line-height(28);
        }
    }

    &__social_wrap{
        @include min-screen($lg){
            float: left;
        }

        .social__btn,
        i {
            width: 20px;
            height: 20px;

        }
        .social__btn{
            margin: 4px 10px 0 0;
            float: none;

            @include min-screen($lg){
                float: left;
            }
        }
        .social__icon{

            &:before{
                @include font-size(14);
                line-height:20px;
            }
        }
    }

    &__infobox_lead {

        clear: both;
        margin: 0;
        padding: 13px 12px 0 12px;
        overflow: hidden;
        font:$font_prim;
        @include font-size(15);
        @include line-height(20);
        color: $white;

        @include min-screen($lg) {
            clear: none;
            padding: 8px 0 0;
            @include font-size(15);
            @include line-height(20);
        }

        @include min-screen($xlg) {
            padding: 6px 0 0;
            @include font-size(16);
            @include line-height(24);
        }
    }
}
