$header_height: 64;
$header_height_sm: 56;

$m: header_wrapper;
.#{$m} {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    height: $header_height_sm + 0px;
    margin: 0 0 8px 0;
    width: 100%;
    background-color: $grey_01;
    @include box-shadow(0 0 21px 1px rgba(0,0,0,.08));

    @include min-screen($sm) {
        left: 50%;
        width: 540px;
        margin-left: calc(540px / -2 + 0px);
    }

    @include min-screen($lg) {
        overflow: visible;
        left: 0;
        margin-left: 0;
        margin-bottom: 0;
        width: 100%;
    }

    @include min-screen($xlg) {
        height: $header_height + 0px;
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
    }

    &__subback {
        display: none;
        position: absolute;
        top: $header_height + 0px;
        width: 100%;
        height: 36px;
        background: #eaeaea;

        @each $site, $specs in $section_colors {
            $color: nth($specs, 1);

            .active_#{$site} & {
                background: $color;
            }
        }

        @include min-screen($lg) {
            .has_subnav & {

                top: $header_height_sm + 0px;
                display: block;
            }
        }

        @include min-screen($xlg) {
            .has_subnav & {

                top: $header_height + 0px;
                height: 40px;
            }
        }
    }

    &.active_slovenija,
    &.active_sport,
    &.active_magazin,
    &.active_zdravje,
    &.active_avto {
        .dars_scroller {
            @include max-screen($xlg - 1) {
                margin-top: 36px !important;
            }
            @include max-screen($lg - 1) {
                margin-top: 0 !important;
            }
        }
    }
}

$m: header;
.#{$m} {

    position: relative;
    z-index: 100;
    height: $header_height_sm + 0px;

    @include min-screen($xlg) {
        height: $header_height + 0px;
        padding: 0 10px 0 0;
    }

    &__wrapper {
        position: relative;
        z-index: 100;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        width: 154px;
        height: 56px;
        padding: 0 0 0 7px;
        overflow: hidden;
        transition: height 0.25s, transform 0.3s ease-in-out;
        background-color: $grey_01;

        @include min-screen($lg) {
            width: 169px;
            padding: 0 0 0 21px;
        }

        @include min-screen($xlg) {
            width: 293px;
            height: 64px;
            padding: 0 0 0 24px;
        }

        &.is-reversed {
            flex-direction: column-reverse;
            justify-content: flex-end;
        }

        &.hover {
            @include max-screen($lg - 1) {
                height: 117px;
            }

            @include min-screen($lg) {
                height: 117px;
            }

            @include min-screen($xlg) {
                height: 128px;
            }
        }

        body.has_navigation & {
            transform: translateY(-150%);

            @include min-screen($lg) {
                transform: none;
            }
        }
    }

    &__logo_toggle {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 46px;

        @include min-screen($xlg) {
            height: 64px;
        }

        .icon {
            @include font-size(24);
            color: #fff;
        }
    }

    &__logo {
        position: relative;
        z-index: 1;
        display: flex;
        min-height: 56px;
        transition: all 0.5s ease-in-out;
        @include line-height($header_height_sm);

        @include min-screen($xlg) {
            min-height: 64px;
        }

        &.header__logo_main .cls-1,
        &.header__logo_main .zu-1,
        &.header__logo_main .zug-1 {

            fill: $blue_prim;

            @each $site, $specs in $section_colors {
                $color: nth($specs, 1);

                .active_#{$site} & {

                    fill: $color;

                }
            }
        }
    }

    &__logo_separator {
        position: absolute;
        top: 56px;
        right: 46px;
        left: 7px;
        height: 1px;
        background-color: #999;
        opacity: 0.4;

        @include min-screen($lg) {
            left: 21px;
        }

        @include min-screen($xlg) {
            top: 64px;
            left: 24px;
        }
    }

    &__logo_heading {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 0;
    }

    &__logo_img {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;

        .header__logo--homepage & {

            &--big {
                display: none;

                @include min-screen($xlg) {
                    width: 216px;
                    height: 49px;
                    display: block;
                }
            }

            &--small {
                position: relative;
                display: block;
                width: 100px;
                height: 26px;

                @include min-screen($xlg) {
                    display: none;
                }
            }
        }

        .header__logo--gorenjska & {

            &--big {
                display: none;

                @include min-screen($xlg) {
                    width: 215px;
                    height: 52px;
                    display: block;
                }
            }

            &--small {
                position: relative;
                display: block;
                width: 100px;
                height: 39px;

                @include min-screen($xlg) {
                    display: none;
                }
            }
        }
    }

    &__special {
        position: absolute;
        right: 141px;
        margin: 0;
        top: 16px;
        display: inline-block;
        width: 48px;
        height: 24px;
        transition: transform 0.25s;

        body.has_navigation & {
            @include max-screen($lg - 1) {
                transform: translateY(-200%);
            }
        }

        @include min-screen($sm) {
            right: 152px;
        }

        @include min-screen($lg) {
            left: -70px;
        }

        @include min-screen($xlg) {
            position: relative;
            left: 0;
            top: 20px;
            float: left;
            margin: 0 17px 0 0;
        }

        figure {
            margin: 0;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    &__menu_utils {
        @include min-screen($lg) {
            display: none;
        }
    }

    &__icon {

        position: absolute;
        top: 18px;
        width: 20px;
        height: 20px;
        color: $white;
        z-index: 200;

        .has_subnavigation & {
            color: #fff;
        }

        > .icon {
            @include font-size(24);
        }

        &--back {
            top: 17px;
            left: -30px;
            opacity: 0;
            transition: all 0.5s;

            .has_subnavigation & {
                opacity: 1;
                left: 9px;
            }

            @include min-screen($xlg) {
                left: 870px;
                top: 33px;
            }
        }

        &--menu {
            width: 44px;
            height: 56px;
            right: 0;
            top: 0;
            text-align: center;

            @include min-screen($sm) {
                right: 0px;
            }

            @include min-screen($xlg) {
                right: 15px;
            }

            .icon-menu,
            .icon-close {
                position: absolute;
                top: 8px;
                left: 8px;
                right: 8px;
                text-align: center;
                @include line-height(40);
                @include transition(opacity 0.4s);

                @include max-screen($lg - 1) {

                    .has_subnavigation & {
                        background: none;
                    }
                }
            }

            .icon-menu {
                opacity: 1;
            }

            .icon-close {
                opacity: 0;
            }

            .has_navigation & {

                .icon-menu {
                    opacity: 0;
                }

                .icon-close {
                    opacity: 1;
                }
            }
        }

        &--user > img {
            width: 80px;
        }

        &--social {

            position: static;
            display: inline-block;
            width: 40px;
            height: 40px;
            margin: 0 -3px 8px 8px;
            @include line-height(55);

            &:first-of-type {
                margin: 0 -3px 0 0;
            }

            &_fb {
                background: $color-facebook;
            }
            &_tw {
                background: $color-twitter;
            }
        }
    }

    &__social_menu {

        position: fixed;
        bottom: -100%;
        left: 0;
        z-index: 200;
        width: 100%;
        text-align: center;
        color: $text02;
        transition: all 0.5s;

        @include min-screen($lg) {
            display: none;
        }

        .has_navigation & {
            bottom: 0;

            @include min-screen($lg) {
                display: none;
            }
        }

        .has_subnavigation & {
            bottom: -100%;
        }

        .has_subnavigation & {
            color: #fff;
        }

        > span {
            display: block;
            font: $font_prim--med;
            @include font-size(14);
            @include line-height(30);
            color: $grey_02;
            text-transform: uppercase;

            .has_subnavigation & {
                display: none;
            }
        }

        .icon {
            color: $white;
            @include font-size(25);

            .has_subnavigation & {
                display: none;
            }
        }
    }

    &__search {
        position: absolute;
        top: 8px;
        right: 90px;
        left: auto;
        z-index: 50;
        height: 40px;
        width: 40px;
        @include font-size(24);
        color: #fff;
        @include transition(0.3s);
        -webkit-transform: translateZ(0);

        @include min-screen($sm) {
            right: 101px;
        }

        @include min-screen($lg) {
            display: block;
            top: 8px;
            right: 56px;
            width: 40px;
            height: 40px;
            padding: 0;
            text-align: center;
        }

        @include min-screen($xlg) {
            top: 12px;
            right: 60px;
        }

        &:hover {
            .icon {
                color: #575757;
            }
        }

        .has_navigation & {

            @include max-screen($lg - 1) {
                transform: translateY(-100%);
            }
        }

        .icon {
            display: block;
            color: $white;
            text-align: center;

            &:before {
                line-height: 40px;
            }
        }
    }

    &__user {
        position: absolute;
        top: 8px;
        right: 46px;
        z-index: 50;
        height: 40px;
        width: 40px;
        padding: 0;
        @include font-size(24);
        color: #fff;
        transition: all 0.2s ease-in-out;

        &:hover {
            .icon {
                color: #575757;
            }
        }

        @include min-screen($sm) {
            right: 56px;
        }

        @include min-screen($lg) {
            top: 8px;
            right: 8px;
        }

        @include min-screen($xlg) {
            top: 12px;
            right: 12px;
        }

        .icon {
            display: block;
            color: $white;
            text-align: center;

            @include min-screen($lg) {

                background: $default-user;

                @each $site, $specs in $section_colors {
                    $color: nth($specs, 1);

                    .active_#{$site} & {

                        background: $color;
                    }
                }
            }

            &:before {
                line-height: 40px;
            }
        }
    }

    &__avatar {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        z-index: 50;
        @include transition(0.3s);

        .has_navigation & {

            @include max-screen($lg - 1) {
                transform: translateY(-150%);
            }
        }
    }

    &__avatar_img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 40px;
        margin: auto;
        overflow: hidden;
        @include transition(0.12s);
        box-shadow: 0 5px 12px 0 rgba(0,0,0, 0.13);

        &--authenticated {
            background: #fff;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.toggle {

    &__count {
        position: absolute;
        top: -8px;
        right: -9px;
        display: block;
        width: 20px;
        height: 20px;
        color: #fff;
        font: $font_prim;
        @include font-size(11);
        @include line-height(20);
        text-align: center;
        background: $red;
        border-radius: 50%;
        box-shadow: 0 2px 10px 0 rgba(0,0,0, 0.16);
        -webkit-transition: all 175ms ease-out 100ms;
        transition: all 175ms ease-out 100ms;
        overflow: hidden;
        z-index: 51;

        &_correction {
            position: relative;
            top: 0;
            left: 0px;
        }

        &--authenticated {
            bottom: 4px;
            left: 23px;

            @include min-screen($lg) {
                bottom: 10px;
                left: 45px;
            }
        }
    }
}
