$border-color: #ddd;
$label: #999999;
$pencil: #999999;
$privacy_bck: #eee;
$privacy_color: #3e3e3e;
$text_color: #1e1e1e;

$m: profile;
$p: profilebox;
$k: tabnav;

.user_profile {

    .main_label {
        padding: 0 0 8px;
        margin-left: 0;
        margin-right: 0;
        font: $font_prim--light;
        @include font-size(24);
        @include line-height(40);
        @include letter-spacing(-25);

        > strong {
            font: $font_prim--bold;
        }
    }

    .body_wrap {
        top: 56px;

        @include min-screen($xlg) {
            top: 80px;
        }
    }

    .main_tile__label {
        text-align: center;

        @include min-screen($lg) {
            @include font-size(24);
            padding: 24px 15px 0;
            margin: 0 0 14px 0;

            &:before {
                bottom: 10px;
            }
        }
    }

    .footer_wrapper {
        margin: 20px 0 0 0;
    }

    .container__height_wrap {
        @include min-screen($lg) {
            display: -webkit-flex; /* Safari */
            -webkit-flex-direction: row; /* Safari 6.1+ */
            display: flex;
            flex-direction: row;
        }
    }

    .column_sidebar {
        @include min-screen($lg) {
            padding: 0;
            -webkit-flex: 1;  /* Safari 6.1+ */
            -ms-flex: 1;  /* IE 10 */
            flex: 1;
        }
    }

    .js_sticky {
        @include min-screen($lg) {
            top: 87px;
        }

        @include min-screen($xlg) {
            top: 111px;
        }
    }
}

.#{$m} {
    text-align: center;

    &__privacy {
        padding:20px;
        background: $privacy_bck;
        border: 1px solid $border-color;
        color: $privacy_color;
        @include font-size(12);
        text-align: center;
    }

    &__pencil {
        float: right;
        margin: 5px 0 5px 5px;
        color:$pencil;
        @include font-size(18);
    }

    &__avatarimg {
        box-shadow:inset 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &__newsl {

        .custom__elem{
            margin-top: -2px;
        }
    }

    &__newsl_txt{
        position: relative;
        top: 4px;
        @include font-size(15);
        color:$text_color;
        font: $font_prim;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    &__head {
        position: relative;
        margin: 10px 0 0 0;
        padding: 0;
        @include cf();
        text-align: center;

        @include min-screen($sm) {
            padding: 0 15px;
        }

        @include min-screen($lg) {
            margin: 0;
            padding: 0;
        }

        .main_label {

            @include min-screen($lg) {
                margin-top: 10px;
            }

            @include min-screen($xlg) {
                margin-top: 10px;
            }
        }
    }

    &__avatar_link {
        position: relative;
        display: inline-block;
        width: 120px;
        height: 120px;
        margin: 11px auto 0;
        overflow: hidden;

        &--big {
            width: 250px;
            height: 250px;
        }

        &--info {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: block;
            color: $grey_01;
            text-align: center;
            font: $font_prim--med;
            @include font-size(14);
            text-transform: uppercase;
            background: rgba($yellow_prim, 0.8);
            overflow: hidden;

            .icon {
                display: block;
                margin: 32px 0 -3px 0;
                @include font-size(24);
            }

            > span {
                display: block;
                margin: 0;
                padding: 10px;
            }

            @include transition (opacity 150ms);
        }

        &:hover &--info {
            opacity: 0;
        }
    }

    &__head_figure_wrap {
        position: relative;
        display: block;
        width: 120px;
        height: 120px;
        overflow: hidden;
        background: #eaeaea;
        @include transition(0.12s);
        overflow: hidden;
    }

    &__head_figure {

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__title {
        position: relative;
        display: block;
        margin: 0;
        padding: 15px 0 7px;
        font: $font_prim--bold;
        @include font-size(24);
        @include line-height(40);
        clear: both;
        @include cf();

        .user_banned & {
            color: #fff;
        }
    }

    &__splitter {
        width: 100%;
        height: 1px;
        margin: 16px 0 9px 0;
        background-color: $grey_04;
    }

    &__head_link {
        color: $grey_02;

        .icon {
            @include font-size(22);
            color: $grey_02;
            margin: 0 10px 0 0;
        }
    }

    &__important {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        font: $font_prim--bold;
        @include font-size(120);
        @include line-height(120);
        color: #ce242e;
    }

    &__important_notice {
        position: relative;
        display: block;
        margin: 0;
        padding: 20px 0 0;
        font: $font_prim--bold;;
        @include font-size(30);
        @include line-height(56);
        @include letter-spacing(-25);

        .user_banned & {
            color: #fff;
        }

        span {
            color: #ce242e;
        }
    }

    &__list {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 15px;

        @include min-screen($sm) {
            padding: 0 15px;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }

    &__list_follow {
        position: relative;
        display: block;
        margin: 0;
        padding: 0 30px;
        list-style: none;
    }

    &__list_item {
        position: relative;
        display: block;
        margin: 0;
        padding: 25px 0 20px;
        @include cf();

        &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 140px;
            height: 1px;
            background: #d4d4d4;
        }

        &:first-child::after {
            display: none;
        }

        &--seen {
            background: #f5f5f5;
        }

        &--reply {
            padding: 20px 15px;
            background: #f2f2f2;
        }

        &--back {
            padding: 0;
        }

        &--admin {

            &::after {
                content: " ";
                position: absolute;
                top: 15px;
                left: 0;
                display: block;
                width: 3px;
                height: 60px;
                background: #d02127;
            }
        }

        &--back {
            padding: 0;
        }
    }

    &__list_item_follow {
        position: relative;
        display: block;
        margin: 0;
        padding: 10px 0;
        @include cf();
        border-bottom: 1px solid #e0e0e0;

        &--seen {
            background: #f5f5f5;
        }
    }

    &__list_item_back {
        position: relative;
        display: block;
        height: 40px;
        margin: 0;
        padding: 0;
        @include font-size(12);
        @include line-height(40);
        color: #ce242e;
        @include transition(0.12s);

        &:hover {
            color: #1897dd;
        }

        [class^="icon-"], [class*=" icon-"] {
            margin-right: 5px;
        }
    }

    // aktivnosti / obavijesti

    &__list_tab_wrap {

        @include min-screen($sm) {
            padding: 0 15px;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }

    &__list_tab {
        display: inline-block;
        @include border-box();
        width: 100%;
        margin: 14px 0 0 0;
        background-color: $grey_05;

        @include min-screen($lg) {
            margin: 22px 0 0 0;
        }

        @include min-screen($xlg) {
            margin: 22px 0 0 0;
        }
    }

    &__list_tab_btn {
        display: inline-block;
        width: 50%;
        height: 40px;
        float: left;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(46);
        color: $grey_02;
        text-transform: uppercase;
        text-align: center;
        box-sizing: border-box;

        .profile__list_tab--public & {
            width: 100%;
        }

        &.is_active {
            background-color: #fff;
            color: $grey_01;
            border: 3px solid $grey_04;
            border-bottom: none;
            @include line-height(40);
        }
    }

    &__checkbox {
        position: absolute;
        left: 10px;
        top: 35px;

        .profile__list_item--notification & {
            display: none;
        }
    }

    &__figure_wrap {
        position: relative;
        display: block;
        float: left;
        margin-right: 25px;
        transform: rotate(45deg);
        overflow: hidden;
        width: 40px;
        height: 40px;

        @include min-screen($lg) {
            width: 50px;
            height: 50px;
        }
    }

    &__figure {
        position: relative;
        float: left;
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 0 12px 0 0;
        padding: 0;

        .profile__list_item--notification & {
            margin: 0 16px 0 0;
        }

         img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
        }
    }

    &__list_reply_figure {
        position: absolute;
        top: 20px;
        left: 30px;
        display: block;
        margin: 0;
        padding: 0;
        width: 40px;
        height: 40px;

        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
        }
    }

    &__list_linkblock {
        display: block;
    }

    &__item_title {
        position: relative;
        display: block;
        padding: 0;
        margin: 0 0 0 100px;
        font: $font_sec;
        @include font-size(18);
        @include line-height(18);
        color: $grey_01;

        .profile__list_item--notification & {
            margin: 0;
        }

        span {
            font: $font_prim--bold;
        }

        > a {
            list-style: none;
            color: #1e1e1e;
            @include transition(0.12s);

            &:hover {
                color: #1897dd;
            }
        }

        .#{$m}__list_item_follow & {
            margin: 0 0 0 58px;
            @include line-height(40);
        }
    }

    &__item_text {
        position: relative;
        display: block;
        margin: 5px 0 0 100px;
        font: $font_prim;
        @include font-size(15);
        @include line-height(24);
        color: #000;

        @include min-screen($lg) {
            @include font-size(16);
            @include line-height(26);
        }

        &--left {
            margin-left: 0 !important;
        }

        .profile__list_item--notification & {
            margin: 5px 0 0 0;
        }

        .profile__list_item--nocheckbox & {
            margin: 5px 0 0 75px;
        }

        a {
            color: #1197dd;
        }
    }

    &__item_follow {
        position: relative;
        display: block;
        margin-top: 15px;
    }

    &__item_time {
        position: relative;
        display: block;
        height: 15px;
        @include font-size(11);
        @include line-height(15);
        @include letter-spacing(100);
        text-transform: uppercase;
        color: $grey_02;

        @include min-screen($lg) {
            display: inline-block;
            top: -13px;
            height: 30px;
            @include line-height(30);

            &:first-child {
                top: 0;
            }
        }

        .profile__list_item--nocheckbox & {
            position: absolute;
            top: 16px;
            right: 10px;
            display: block;
        }

        .profile__list_item--admin & {
            margin-left: 75px;
        }
    }

    &__item_block {
        position: relative;
        margin: 0 16px 0 0;
        @include font-size(12);
        @include line-height(15);
        color: #9c9c9c;
        cursor: pointer;

        [class^="icon-"], [class*=" icon-"] {
            color: #9c9c9c;
        }
    }

    &__item_follow {
        position: relative;
        top: 10px;
        margin: 0 16px 0 0;
        @include font-size(12);
        @include line-height(15);
        color: #9c9c9c;

        [class^="icon-"], [class*=" icon-"] {
            color: #9c9c9c;
        }
    }

    &__item_btns {
        margin-top: 12px;
        display: block;
        @include cf();

        &--avatar {
            @include min-screen($lg) {
                padding-left: 75px;
                margin-top: 20px;
            }
        }

        &--right  {
            margin-left: 75px;
            margin-bottom: 10px;
        }

        .btn {
            height: auto;
            margin-right: 16px;
            margin-bottom: 15px;
            font: $font_sec;
            @include font-size(12);
            color: #000;
            @include letter-spacing(100);
            padding: 8px 10px;
            border: 1px solid #c8c8c8;

            &:hover {
                background: #f7f6f4;
            }

            @include min-screen($lg) {
                margin-bottom: 0;
            }
        }

        [class^="icon-"], [class*=" icon-"] {
            margin-right: 5px;
            color: #1e1e1e;
        }

        .#{$m}__list_item_follow & {
            position: absolute;
            top: 4px;
            right: -16px;
            width: 50%;
            text-align: right;
        }
    }

    &__item_card {
        position: relative;
        float: right;
        top: 4px;
        display: inline-block;
        width: 10px;
        height: 14px;
        margin: 0 0 0 5px;
        border: 1px solid #d6d6d6;
        border-radius: 2px;
        background: #fff;

        &--yellow {
            top: 0;
            background: #feda10;
        }

        &--red {
            top: 0;
            background: #fd3425;
        }
    }

    &__item_reply {
        margin-top: 10px;
        margin-left: 75px;
    }

    &__controls {
        position: relative;
        margin: 0;
        padding: 0;
    }

    &__controls_list {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        border-bottom: 1px solid #e0e0e0;
    }

    &__controls_list_item {
        position: relative;
        display: inline-block;
        height: 39px;
        margin: 0;
        padding: 0 10px;
        @include font-size(12);
        @include line-height(39);
        cursor: pointer;
        @include transition(0.12s);

        &::after {
            content: " ";
            position: absolute;
            top: 10px;
            left: 0;
            bottom: 10px;
            display: block;
            width: 1px;
            background: #e0e0e0;
        }

        &:first-child {

            &::after {
                display: none;
            }
        }

        &--important, &:hover {
            color: #ce242e;
        }
    }

    &__controls_checkbox {

        .custom__elem {
            top: 10px;
            margin-right: 10px;
        }

        span {
            cursor: pointer;
        }
    }

    &__reply_form {
        position: relative;
        display: block;
        margin: 0 0 0 70px;
        padding: 0;
        text-align: right;

        textarea {
            @include border-box();
            position: relative;
            display: block;
            width: 100%;
            height: 65px;
            min-height: 65px;
            margin: 0 0 20px;
            padding: 6px 12px;
            border: 1px solid #d5d5d5;
            text-align: left;
            resize: vertical;
            @include font-size(15);
            @include line-height(23);
        }
    }

    &__reply_form_loader {
        position: relative;
        display: inline-block;
        height: 32px;
        width: 32px;
        margin: 0 10px;
        padding: 0;
        background: url('#{$imgPath}/loader.svg') center no-repeat;
        background-size: 600%;
        overflow: hidden;
    }

    &__reply_form_btn {
        position: relative;
        margin-right: 20px;
        @include font-size(12);
        cursor: pointer;
        @include transition(0.12s);

        &:hover {
            color: #1197dd;
        }

        &--sending {
            margin-right: 50px;

            &::after {
                content: " ";
                position: absolute;
                right: -45px;
                top: -8px;
                display: block;
                width: 32px;
                height: 32px;
                background: url('#{$imgPath}/loader.svg') center no-repeat;
                background-size: 555%;
            }
        }
    }

    &__reply_form_submit {

        &--sending {
            background: #ddd;
            border-color: #bbb;
            cursor: default;
            pointer-events: none;

            &:hover {
                background: #ddd;
                border-color: #bbb;
            }
        }
    }

    &__new_form {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        text-align: right;

        .form__item {
            margin: 0;
            text-align: left;
        }

        input[type="text"] {
            max-width: 300px;
            border: 1px solid #d5d5d5;
        }

        textarea {
            @include border-box();
            position: relative;
            display: block;
            width: 100%;
            height: 65px;
            min-height: 65px;
            margin: 0 0 20px;
            padding: 6px 12px;
            border: 1px solid #d5d5d5;
            text-align: left;
            resize: vertical;
            @include font-size(15);
            @include line-height(23);
        }
    }

    &__paginator {
        position: relative;
        display: block;
        margin: 20px 0;
        padding: 0;
        text-align: center;
    }

    &__avatar_figure {
        position: relative;
        display: block;
        max-height: 300px;
        margin: 0;
        padding: 0;
        overflow: hidden;

        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
        }
    }

    &__userlist {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        @include cf();
    }

    &__userlist_item {
        position: relative;
        display: block;
        float: left;
        margin: 0 0 0 5px;
        padding: 5px 0;
        font: $font_prim--bold;
        @include font-size(15);
        @include line-height(23);

        &:first-child {
            margin-left: 0;
        }
    }
}

.#{$p} {
    position: relative;
    margin: 30px 0 0;
    padding: 0;

    &--personal {
        margin-top: 20px;
    }

    &--password {
        display: none;
        margin-top: -5px;

        &.is_visible {
            display: block;
        }
    }

    &--social {
        margin-top: 13px;
        padding-top: 17px;
        text-align: center;

        @include min-screen($lg) {
            margin: 14px 0 0 0;
        }

        .main_label {
            margin-top: -16px;
            margin-bottom: 23px;
        }
    }

    &--last {
        margin-top: 31px;
    }

    .btn--socialconnect {
        height: auto;
        margin: 5px 0 6px 0;
        padding: 10px 10px;
        @include line-height(20);

        &:before {
            display: none;
        }

        @include screen($lg, $xlg - 1) {
            padding: 10px 8px;
        }

        @include screen($xlg) {
            padding: 10px 25px;
        }

        span {
            display: inline-block;

            @include screen($lg, $xlg - 1) {
                display: none;
            }
        }
    }

    &__delete_acc {
        color: $grey_02;
        margin: 0 0 6px 0;

        .icon {
            top: -1px;
            left: 5px;
            margin: 0;
            @include font-size(22);
            color: $grey_02;
        }
    }

    &__list {
        position: relative;
        display: block;
        margin: 24px 0 0;
        padding: 0;
        list-style: none;

        .form__icon {
            bottom: 24px;
        }
    }

    &__list_item {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        @include cf();

        &--first {
            margin: -5px 0 23px;
            padding: 0 0 7px;
            border-bottom: 1px solid #e0e0e0;
        }

        .form__textfield {
            font: $font_prim;
            @include font-size(14);
            color: $grey_02;

            &.is_edited,
            &:focus {
                color: $grey_01;
                border-color: $blue_prim;
            }
        }

        .radiobutton {
            margin-bottom: 15px;

            &:first-child {
                margin-right: 35px;
            }
        }

        .btn {
            margin: 8px 0 13px;

            &--icon {
                margin: 20px 0 5px 0;
                padding: 0 60px 0 20px;
            }

            &--reset_password,
            &--delete_account {
                margin: 16px 0 21px 0;
            }

            .icon {
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                @include font-size(20);
            }
        }
    }

    &__key {
        position: relative;
        float: left;
        display: block;
        width: 120px;
        @include font-size(15);
        @include line-height(30);
        color: #9c9c9c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .#{$p}__list_item--first & {
            width: auto;
            text-overflow: initial;
            margin-right: 20px;
        }
    }

    &__value {
        position: relative;
        float: left;
        display: block;
        width: 120px;
        font: $font_prim--bold;
        @include font-size(15);
        @include line-height(30);
        color: #1e1e1e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__splitter {
        position: relative;
        display: block;
        height: 1px;
        margin: 6px 0 10px;
        padding: 0;
        background: #e0e0e0;
        @include cf();
    }

    &__login_btn {
        color: $grey_02;
        margin: 0 0 10px 0;

        .icon {
            margin: 0 10px 0 0;
            @include font-size(22);
            color: $grey_02;
        }
    }

    &__checkbox {
        position: relative;
        display: block;
        margin: 13px 0;
        padding: 0;

        .custom__check--squared {

            &:checked ~ .custom__label .profilebox__checkbox_text,
            &[checked="checked"] ~ .custom__label .profilebox__checkbox_text {
                background: #ebebeb;
            }
        }

        .custom__elem {
            top: -1px;
            margin-right: 0;
        }
    }

    &__checkbox_text {
        position: relative;
        margin: 0;
        padding: 5px 20px 5px 30px;
        font: $font_prim;
        @include font-size(15);
        @include line-height(27);
        cursor: pointer;
    }
}

// Profile Message
.pmsg {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    @include cf();
    border-bottom: 1px solid #e0e0e0;

    &--nolink {
        padding: 15px 10px;
    }

    &.is_seen {
        background: #f5f5f5;
    }

    &__link {
        position: relative;
        display: block;
        margin: 0;
        padding: 15px 10px;
        text-decoration: none;
        @include transition(0.12s);

        &:hover {
            background: #000;
        }

        .pmsg.is_seen & {
            background: #f5f5f5;
        }
    }

    &__checkbox {
        position: absolute;
        left: 10px;
        top: 35px;

        .pmsg--notification & {
            display: none;
        }
    }

    &__figure {
        position: relative;
        display: block;
        margin: 0 0 0 25px;
        padding: 0;
        width: 60px;
        height: 60px;
        float: left;
        box-shadow: inset 0 0 1px rgba(0,0,0, 0.14);

        .pmsg--nocheckbox & {
            margin: 0;
        }

        .#{$m}--follow {
            width: 40px;
            height: 40px;
        }

        &--small {
            width: 40px;
            height: 40px;
            margin-left: 0;
        }

        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;

            &::after {
                content: " ";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0.35) inset;
            }
        }
    }

    &__title {
        position: relative;
        display: block;
        margin: 0 0 0 100px;
        font: $font_prim--bold;
        @include font-size(15);
        @include line-height(15);
        color: #1e1e1e;

        .pmsg--nocheckbox & {
            margin: 0 0 0 75px;
        }

        span {
            font: $font_prim;
        }

        .#{$m}__list_item_follow & {
            margin: 0 0 0 58px;
            @include line-height(40);
        }
    }

    &__text {
        position: relative;
        display: block;
        min-height: 42px;
        margin: 5px 0 0 100px;
        @include font-size(15);
        @include line-height(23);
        color: #1e1e1e;
        overflow: hidden;

        .pmsg--nocheckbox & {
            margin: 5px 0 0 75px;
        }

        a {
            color: #1197dd;
        }
    }

    &__reply {
        margin-top: 10px;
        margin-left: 100px;
    }

    &__time {
        position: absolute;
        top: 16px;
        right: 10px;
        display: block;
        height: 15px;
        @include font-size(12);
        @include line-height(15);
        color: #9c9c9c;
    }

    &__response {
        position: relative;
        display: block;
        margin: 0 -10px;
        padding: 26px 26px 26px 80px;
        background: #a9d969;

        &--warning {
            background: #e14a4a;
        }

        &--info {
            background: #64bfee;
        }
    }

    &__response_icon {
        position: absolute;
        top: 50%;
        left: 15px;
        display: block;
        margin: -24px 0 0;
        width: 40px;
        height: 40px;
        text-align: center;

        [class^="icon-"], [class*=" icon-"] {
            @include font-size(48);
            color: #fff;
        }
    }

    &__response_text {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        @include font-size(18);
        @include line-height(28);
        color: #405328;

        .pmsg__response--warning & {
            color: #551c1c;
        }

        .pmsg__response--info & {
            color: #26485b;
        }
    }
}

.#{$k} {
    position: relative;
    display: block;
    margin: -19px 0 -17px;
    padding: 0;
    list-style: none;
    font-size: 0;

    &--green {
        margin: 0;
        @include min-screen($lg) {
            display: none;
        }
    }

    &__item {
        display: inline-block;
        margin: 0;
        padding: 0;
        font-size: 0;
        width: 50%;
        text-align: center;

        .profile__tabs & {
            float: right;

            &:first-child {
                float: left;
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        margin: 0;
        padding: 0 15px;
        text-decoration: none;
        font: $font_sec;
        @include font-size(15);
        @include line-height(40);
        color: $grey_02;
        @include transition(0.12s);
        text-transform: uppercase;
        letter-spacing: 0;

        @include min-screen($lg) {
            @include font-size(15);
            @include line-height(60);
        }

        &:hover {
            color: #000;
        }

        &.is_active {
            color: $grey_01;

            &:after {
                content: " ";
                position: absolute;
                bottom: 2px;
                left: 0;
                right: 0;
                display: block;
                height: 2px;
                background: #000;
                z-index: 2;
            }

            &:hover {
                cursor: default;
            }
        }

        .profile__tabs & {

            font: $font_prim--bold;
            @include font-size(14);
            @include line-height(50);

            &::after {
                display: none;
            }
        }
    }

    &__sub {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        height: 60px;
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }

    &__sub_item {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    &__sub_link {
        position: relative;
        display: block;
        margin: 0;
        padding: 0 15px;
        text-decoration: none;
        @include font-size(14);
        @include line-height(60);
        color: #4e4e4e;

        &:hover {
            color: #1897dd;
        }

        &.is_active {
            cursor: default;
            pointer-events: none;

            &:hover {
                color: #4e4e4e;
            }

            &::after {
                content: " ";
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                display: block;
                height: 3px;
                background: #1e1e1e;
            }
        }
    }
}

//
// tabs controll
//

.profile__tabs {
    position: relative;
    display: block;
    height: 50px;
    @include border-box();
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    @include min-screen($lg) {
        display: none;
    }

    .tabnav__link {
        background: transparent;
    }
}

.activities__tabs {
    padding: 20px 0 7px;

    @include min-screen($lg) {
        padding: 28px 0 5px;
    }

    .tabnav__link {
        @include font-size(12);

        @include min-screen($lg) {
            @include font-size(15);
        }
    }
}

.profile_content {

    @include max-screen($lg - 1) {
        display: none;

        .user_profile--public & {
            display: block;
        }

        &.is_active {
            display: block;
        }
    }
}

.user_profile--public {
    .profile__avatar_link {
        margin-bottom: 20px;
    }
}

.activities_content {
    display: none;

    &.is_active {
        display: block;
    }
}

.act {
    position: relative;
    padding: 0 15px 8px 0;
    margin: 4px 0 0 0;

    &:first-child {
        margin: 0;
    }

    &:after {
        content: "";
        position: absolute;
        left: -14px;
        right: -14px;
        bottom: 0;
        height: 1px;
        background-color: $grey_04;
    }

    &--loadmore {
        padding: 0;
        margin: 20px 0 0 0;

        @include min-screen($lg) {
            margin: 30px 0 0 0;
        }

        &:after {
            display: none;
        }

        .btn {
            display: block;
        }
    }

    &__title {
        padding: 0;
        font: $font_sec;
        @include font-size(16);
        color: $grey_01;

        span {
            font: $font_prim--bold;
            @include font-size(16);
        }
    }

    &__user_link {
        display: inline-block;
        font: $font_prim--bold;
        @include font-size(16);
    }

    &__time {
        display: block;
        margin: 3px 0 7px 0;
        font: $font_prim;
        @include font-size(12);
        color: $grey_03;
        text-transform: uppercase;

        &--footer {
            display: none;
        }

        @include min-screen($lg) {
            @include font-size(12);

            &--footer {
                display: none;
                margin: 0;
                @include line-height(32);
            }

            .act__footer--button & {
                display: none;
            }
        }
    }

    &__main {
        margin: 14px 0 3px 0;
        font: $font_prim;
        @include font-size(15);
        @include line-height(20);
        color: $grey_00;
    }

    .btn--reply {
        height: auto;
        padding: 0;
        color: $grey_01;
        background: none;
        text-transform: none;

        &:hover {

            background: none;

            span {
                text-decoration: none;
                transition: 0.2s ease-in-out;
                box-shadow: inset 0 -4px 0 $grey_03;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_03;
                }
            }
        }

        span {
            display: inline-block;
            padding: 0;
            font: $font_prim;
            @include font-size(14);
            @include line-height(20);
            @include letter-spacing(0);
            box-shadow: inset 0 -4px 0 $grey_04;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_04;
            }
        }
    }

    &__footer {

        &--button {
            margin: 12px 0 0 0;
        }
    }
}
