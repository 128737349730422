.thread {

    &__list {
        list-style-type: none;
        margin: -1px 0 0;
        padding: 0;

        > li.pagination {

            border-bottom: 0;
            padding: 0;
            margin: 0;
        }
    }

    &__sublist {
        display: none;
        padding: 4px 0 0 0;
        margin-left: 50px;

        @include min-screen($lg) {
            margin-left: 51px;
            padding-top: 0;
        }

        @include min-screen($xlg) {
            margin-left: 89px;
        }

        &.is_visible {
            display: block;
        }
    }

    &__message {
        display: block;
        margin-bottom: 7px;

        font: $font_prim;
        @include font-size(16);
        @include line-height(24);
        @include letter-spacing(0);

        @include min-screen($lg) {
            margin-bottom: 8px;
        }

        @include min-screen($xlg) {
            margin-bottom: 7px;
            @include font-size(17);
            @include line-height(30);
        }

        &--title {
            padding: 7px 0 4px 0;
            margin-top: -10px;
            font: $font_prim--bold;
            color: $grey_00;
            @include font-size(24);
            @include line-height(5);
            @include letter-spacing(-25);
            text-transform: none;
            text-align: center;

            @include min-screen($sm) {
                padding: 7px 0 4px 0;
                margin-top: -15px;
            }

            @include min-screen($lg) {
                padding: 18px 0 3px 0;
                @include font-size(24);
                @include line-height(5);
            }

            @include min-screen($xlg) {
                padding: 17px 0 7px 0;
                @include font-size(36);
                @include line-height(15);
            }
        }

        &--content {
            font: $font_prim;
            @include font-size(16);
            @include line-height(31);
            @include letter-spacing(10);

        }
    }

    &__btns {

        @include min-screen($lg) {
            margin-top: -10px;
        }

        @include min-screen($xlg) {
            margin-top: 0;
        }

        &.no_posts {
            @include min-screen($lg) {
                margin-top: -40px;
            }
        }

        .btn {
            margin: 0 1px;

            @include min-screen($lg) {
                margin: 0 4px;
            }
        }
    }

    &__separator {
        border-bottom: 1px solid $grey_04;
    }
}

.thread_teaser {
    padding-top: 0;

    .main_label {
        margin-left: 0;
        margin-right: 0;
    }
}

// overrides
.thread_teaser {

    .commbox__wrap.commbox__wrap {
        padding-left: 0;
    }
}
