.cookie_notice {
    @include border-box();
    position:fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    background: $yellow_prim;
    padding: 20px;
    z-index: 50;
    transform: translateX(-50%);
    color: $grey_00;
    font: $font_prim;
    @include font-size(13);
    @include line-height(22);

    box-shadow: 0 1px 21px rgba(0, 0, 0, 0.1);
    text-align: center;

    a {
        padding: 0px 3px;
        background-color: $grey_00;
        color: #fff;
        text-decoration: none;
    }

    @include min-screen($sm) {
        width: 540px;
    }

    @include min-screen($lg) {
        @include font-size(16);
        @include line-height(30);
        padding: 30px 195px 30px 30px;
        width: 980px;
    }

    @include min-screen($xlg) {
        width: 1061px;
    }

    &__text {
        text-align: left;
    }

    .btn {
        @include font-size(14);
        @include line-height(40);
        color: white;
        background-color: $grey_00;
        text-decoration: none;
        width: 135px;

        @include min-screen($lg) {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translatey(-50%);
        }
    }
}
