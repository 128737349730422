// Default forms styling
$m: form;
.#{$m} {

    // error messages
    &__error {
        position: relative;
        display: block;
        @include font-size(12);
        @include line-height(20);
        color: $red;
        text-transform: uppercase;
        padding: 5px 0 5px 20px;

        &:before {
            position: absolute;
            left: 0;
            top: 7px;
            @extend %iconsetup;
            @include font-size(16);
            content: "\ea07";
        }
    }

    &__row {
        position: relative;
        margin: 0 !important;
        @include clearfix();
        font-size: 0;

        .form__type--date & {
            float: left;
            width: 33.3333%;
            padding: 0 $gutter 20px;
            @include border-box();
        }

        &--search {
            height: 106px;
        }

        .checkbox_group &,
        .radiobutton_group & {
            max-height: 96px;
            min-height: 64px;
            height: auto;
        }
    }

    &__type--date {
        @include clearfix();
        margin: 0 (-$gutter);
    }

    &__type--radio {
        @include clearfix();
        margin: 0 (-$gutter);

        .radio {
            float: left;
            width: 33.3333%;
            padding: 0 $gutter;
            @include border-box();
        }

        .form__label {
            margin: 0 $gutter;
        }
    }

    &__label {
        display: block;
        padding: 0 12px;
        margin: 0;
        font: $font_prim--med;
        @include font-size(12);
        @include line-height(32);
        @include letter-spacing(0);
        color: $grey_01;
        text-transform: uppercase;

        a {
            color: $grey_04;
        }
    }

    &__textfield,
    &__textarea,
    &__select {
        @include border-box();
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border: 1px solid $grey_04;
        font: $font_prim;
        @include font-size(14);
        @include line-height(40);
        color: $grey_01;
        border-radius: 0;
        box-shadow: none;
        -webkit-appearance: none;

        .has_error & {
            border: 1px solid $red;
        }
    }

    &__textfield,
    &__textarea {
        display: block;
        line-height: normal;
        vertical-align: middle;
        background-color: $grey_05;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $grey_02;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $grey_02;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $grey_02;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $grey_02;
        }

        &:focus {
            border-color: $grey_01;
        }

        &:disabled {
            border: none;
            background-color: #f4f4f4;
        }

        &::-ms-clear {
            display: none;
        }

        &.has_error {
            border-color: $red !important;
        }

        &.has_hint:focus {
            border-color: $blue_prim;
        }

        .form__row--search & {
            @include min-screen($xlg) {
                height: 50px;
                @include font-size(16);
                @include line-height(50);
            }
        }
    }

    &__hint_wrap {
        position: relative;
        min-height: 24px;
        padding: 0 10px;
        @include border-box();
        visibility: hidden;
        font: $font_sec;

        .form__textfield:focus + &,
        .form__row.has_error + &,
        .form__textfield.has_error + &,
        .form__textarea:focus + & {
            visibility: visible;
        }

        .form__row.has_error + &,
        .form__textfield.has_error + & {

            .form__input_error {
                visibility: visible;
            }
        }
    }

    &__input_hint {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        padding: 4px 10px;
        @include border-box();
        background-color: $blue_prim;
        @include font-size(12);
        @include line-height(16);
        color: $grey_00;
        z-index: 1;
        visibility: hidden;

        .form__textfield:focus ~ .form__hint_wrap &,
        .form__textarea:focus ~ .form__hint_wrap & {
            visibility: visible;
        }
    }

    &__input_error {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        padding: 4px 10px;
        @include border-box();
        background-color: $red;
        font: $font_sec;
        @include font-size(12);
        @include line-height(16);
        color: #fff;
        z-index: 2;
        visibility: hidden;

        &--relative {
            position: relative;
        }

        &--visible,
        .form__row.has_error & {
            visibility: visible;
        }
    }

    &__input_icon {
        position: absolute;
        bottom: 24px;
        right: 0;
        width: 40px;
        height: 40px;
        color: $grey_02;
        text-align: center;

        &:before {
            @include font-size(22);
            line-height: 40px;
        }

        // change icon color when field has focus
        .form__textfield:focus ~ & {
            color: $grey_00;
        }

        // search icon
        .form__row--search & {

            background-color: $grey_00;
            color: $white;

            &:hover {
                color: $yellow_prim;
                cursor: pointer;
            }

            @include min-screen($xlg) {
                width: 50px;
                height: 50px;

                &:before {
                    line-height: 50px;
                }
            }
        }

        // reset icon color on input focus
        .form__row--search .form__textfield:focus ~ & {
            color: $white;
        }
    }

    &__icon {
        position: absolute;
        bottom: 24px;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        @include line-height(40);
        border: 0 none;
        background: transparent none repeat scroll 0 0;
        border-radius: 0;
        outline: 0 none;
        text-align: center;
        color: $grey_02;

        .icon  {
            @include font-size(24);
            position: relative;
            top: 10px;
        }

        .not_editing {
            display: block;
        }
        .is_editing {
            display: none;
        }
    }
    &__textfield.is_edited,
    &__textarea.is_edited,
    &__textfield:focus,
    &__textarea:focus {
        ~ .form__icon .not_editing {
            display: none;
        }
        ~ .form__icon .is_editing {
            display: block;
        }
    }

    &__submit {
        margin-bottom: 20px;

        @include min-screen($md) {
            margin: 0;
        }
    }
}

.select {
    @include font-size(14);
}

.custom_select {
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
    @include transition(0.5s);
    background: #fff;
    @include line-height(40);

    &:after {
        position: absolute;
        right: 14px;
        top: 2px;
        width: 10px;
        height: 40px;
        text-align: right;
        line-height: 40px;
        @extend %iconsetup;
        content: "\ea36";
        pointer-events: none;
        @include font-size(8);
        color: $grey_00;
    }

    &__input {

        position: relative;
        width: 100%;
        color: $grey_00;
        height: 40px;
        border: 0;
        padding: 0 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-transform: uppercase;
        background: transparent;

        @include min-screen($lg) {
            display: none;
        }
    }

    &__part {
        overflow: hidden;
        max-height: 0;

        @include min-screen($lg) {
            display: block;
            padding: 0 52px 0 20px;
            max-height: 999px;
        }

        .no-touch &:hover > .select__list,
        .select__list.is_visible {

            max-height: 999px;
            @include opacity(1);
        }

        .no-touch &:hover > .select__list--scroll,
        .select__list--scroll.is_visible {
            max-height: 478px;
            overflow-y: scroll;
            @include opacity(1);
        }
    }

    &__button {
        text-transform: uppercase;
        @include font-size(14);
    }

    &__list {

        display: none;
        position: absolute;
        left: -1px;
        top: 38px;
        padding: 0;
        margin: 0;
        max-height: 0;
        width: 100%;
        background: #f1f1f1;
        list-style: none;
        overflow: hidden;
        z-index: 9;
        border: 1px solid #e1e1e1;
        @include opacity(0);

        @include min-screen($lg) {
            display: block;
        }
    }

    &__item {
        border-bottom: 1px solid #e1e1e1;
        @include font-size(15);
        @include line-height(20);
        color: $grey_00;
        padding: 10px 20px;

        &:hover {
            background: #e9e9e9;
        }
    }
}

// VIDJETI OVO
// Custom form elements

.radiobutton_group,
.checkbox_group {

    &__error_wrap {
        display: none;
        width: 100%;
        height: 24px;
        padding: 0 10px;
        @include border-box();
        background-color: $red;

        .modal--register & {
            position: absolute;
            top: 53px;
        }
    }

    &.has_error &__error_wrap {
        display: block;
    }

    &__error {
        margin: 0;
        font: $font_sec;
        @include font-size(12);
        @include line-height(24);
        color: #fff;
    }
}

.radiobutton,
.checkbox {

    display: inline-block;

    &__input {
        display: none;
    }

    &__label {
        position: relative;
        display: block;
        padding: 0 0 0 40px;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(40);
        color: $grey_01;

        &:hover {
            color: #575757
        }

        .modal--register & {
            padding-top: 12px;
            @include font-size(12);
            @include line-height(16);
            @include letter-spacing(0);
        }
    }

    &__input:disabled + &__label {
        color: #969696;

        .radiobutton__icon:before,
        .checkbox__icon:before {
            color: #969696;
        }

        &:hover {

            .radiobutton__icon:before,
            .checkbox__icon:before {
                color: #969696;
            }
        }
    }
}

// radiobutton
.radiobutton {

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        text-align: center;

        &:before {
            @extend %iconsetup;
            content: "\e920";
            @include font-size(24);
            line-height: 40px;
            color: $grey_00;
        }

        .radiobutton__label:hover &:before {
           color: #575757;
        }
    }

    // pointer only on non-disabled radio buttons
    &__input:not(:disabled) + &__label {
        cursor: pointer;
    }

    // if radio is selected - color it
    &__input:checked + &__label {

        .radiobutton__icon:before {
            content: "\e921";
            color: #1e1e1e;
        }
    }
}

// checkboxes
.checkbox {

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        text-align: center;

        &:before {
            @extend %iconsetup;
            content: "\e908";
            @include font-size(24);
            line-height: 40px;

            .has_error & {
                color: #c3423f;
            }
        }

        .checkbox__label:hover &:before {
           color: #575757;
        }
    }

    // pointer only on non-disabled checkboxes
    &__input:not(:disabled) + &__label {
        cursor: pointer;
    }

    // if checkbox is selected - color it
    &__input:checked + &__label {

        .checkbox__icon:before {
            content: "\e909";
            color: #1e1e1e;
        }

        .checkbox__icon {
            display: block;
        }
    }
}
