.ck_image_in_article {

    $sizes: ( size: (25, 50, 75, 100) );

    $list: (
        positions: (
            left: $sizes,
            right: $sizes,
            default: $sizes
        )
    );

    $positions: map-get($list, positions);

    @each $position, $specs in $positions {

        $sizes: map-get($specs, size);

        @each $size in $sizes {

            &--#{$position}#{$size} {
                overflow: hidden;

                @if ( $position == default ){
                    margin: auto;
                } @else {
                    position: relative;
                    float: #{$position};

                    $marginSide: "right";
                    @if ( $position == right ) {
                        $marginSide: "left";
                    }
                    
                    margin-#{$marginSide}: 25px;
                }

                // mobile alway full no matter of float
                width: 100%;

                @include min-screen(1024px) {
                    top: 11px;
                    width: $size+0%;
                }

                @include min-screen(1310px) {
                    top: 11px;
                    width: $size+0%;
                }

                > span {
                    position: relative;
                    word-break: break-all;

                    @include min-screen(1024px) {
                          padding-right: 0;
                    }

                    &.article__figure_caption-author {
                        word-break: none;
                    }
                }
            }
        }
    }

    img {
        max-width: 100%;
    }

    &__authors {
        @include font-size(11);
    }
}

.imagelightbox-on-hover {
    box-shadow: none;

    img {
        transition: all .3s ease-in-out;
    }

    &:hover {
        box-shadow: none;
        cursor: zoom-in;

        img {
            transform: scale(1.01);
        }
    }
}

.ck_image_in_article--left50,
.ck_image_in_article--full {
    line-height: 0;
    float: none;

   @include min-screen($xlg) {
       max-width: none;
   }
}

.ck_image_in_article--left50 {

    @include min-screen($lg) {
        float: left;
        width: 282px;
    }

    @include min-screen($xlg) {
        width: 358px;
    }
}
