$m: special_widget;
body.section--special {
    .main_label {
        margin-left: 0;
        margin-right: 0;
    }

    .card--10 .card__data {
        padding-left: 0;
    }
    .card--10:last-child .card__wrap {
        border-bottom: none;
    }
}
body.section--special .content__wrap:before {
    display: none;

    @include min-screen($md) {
        display: none;
    }
}

.#{$m} {

    position: relative;

    &__outer_wrap {

        @include min-screen($lg) {
            padding: 0 15px;
        }

        &--with_sponsors {
            padding: 0;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center center;

        @include min-screen($lg) {
            background-position: left top;
        }

        @include min-screen($xlg) {
            background-size: cover;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
            background: rgb(0,0,0); /* Old browsers */
            background: -moz-linear-gradient(45deg,  rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(45deg,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
            opacity: 0.56;
        }
    }

    &__inner_wrap {
        position: relative;
        z-index: 1;
        @include border-box();
        height: 0;
        padding: 0 0 100%;
        text-align: center;

        @include screen($sm, $lg - 1) {
            padding: 0 0 56.25%;
        }

        @include min-screen($lg) {
            position: static;
            text-align: left;
            padding: 0 30px calc((423/994) * 100%);
        }

        @include min-screen($xlg) {
            padding: 0 60px calc((545/1280) * 100%);
        }

        .btn {
            display: none;
        }

        @include max-screen($lg - 1) {
            .btn {
                display: inline-block;
                position: absolute;
                bottom: 20px;
                left: 50%;
                z-index: 15;
                transform: translateX(-50%);
            }
        }
    }

    &__title_wrap {
        position: relative;
        padding: 20px 12px 0;
        text-align: center;
        z-index: 10;
        margin: 0 auto;

        @include min-screen($sm) {
            padding: 20px 24px 0;
        }

        @include min-screen($lg) {
            padding: 28px 0 0;
            max-width: 644px;
        }

        @include min-screen($xlg) {
            padding: 40px 0 0 0;
            max-width: 844px;
        }

        .#{$m}--with_sponsors & {
            width: calc(100% - 24px);
            max-width: 100%;
            padding: 3px 12px 7px;
            border-bottom: 4px solid $grey_04;
            box-sizing: border-box;

            @include min-screen($lg) {
                width: 100%;
                padding: 3px 15px 14px;
            }
        }
    }

    &__overtitle {
        margin: 0 auto;
        display: inline-block;
        height: 25px;
        padding: 0 12px;
        background: $yellow_prim;
        font: $font_prim--bold_it;
        @include font-size(12);
        @include line-height(27);
        @include letter-spacing(100);
        text-transform: uppercase;
        color: $grey_01;

        @include min-screen($xlg) {
            height: 25px;
            padding: 0 12px;
            @include font-size(12);
            @include line-height(27);
            @include letter-spacing(100);
        }
    }

    &__title {

        @include screen($lg, $xlg - 1) {
            padding: 12px 0 0;
        }

        @include max-screen($lg - 1) {
            padding-top: 7px;
        }

        strong {

            display: inline;
            font: $font_prim--bold;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-25);
            color: $white;
            background: $grey_01;
            transition: background 0.3s;

            &:hover {
                background: $grey_06;
            }

            @include min-screen($lg) {
                margin: 0 0 5px;
                padding: 0 0 5px;
                @include font-size(36);
                @include line-height(40);
            }

            @include min-screen($xlg) {
                margin: 0 0 30px;
                padding: 0 0 5px;
                @include font-size(48);
                @include line-height(52);
            }
        }

        span {
            position: relative;
        }

        .#{$m}--with_sponsors & {
            font: $font_prim--bold;
            @include font-size(24);
            @include line-height(40);
            @include letter-spacing(-25);
            text-transform: uppercase;

            @include min-screen($lg) {
                @include font-size(36);
            }
        }
    }

    &__description {

        margin: 0 auto;

        @include min-screen($lg) {
            max-width: 664px;
            margin-top: 3px;
        }

        @include min-screen($xlg) {
            max-width: 655px;
            margin-top: 9px;
        }

        @include max-screen($lg - 1) {
            display: none;
        }

        em {

            display: inline;
            margin: 0;
            padding: 0 10px;
            font: $font_sec--light_it;
            @include font-size(28);
            @include line-height(36);
            @include letter-spacing(10);
            color: $white;
            background: $grey_01;

            @include min-screen($lg) {
                @include font-size(18);
                @include line-height(22);
            }

            @include min-screen($xlg) {
                margin-top: 15px;
                @include font-size(24);
                @include line-height(28);
            }
        }

        span {
            position: relative;
        }
    }

    &__list_wrap {

        text-align: center;

        @include max-screen($lg - 1) {
            background: $grey_01;

            .with_border {
                @include max-screen($lg) {
                    padding-bottom: 27px;
                    border-bottom: 1px solid #ededed;
                }
            }
        }

        @include min-screen($lg) {
            position: absolute;
            z-index: 10;
            bottom: 0;
            left: 0;
            right: 0;
            height: 194px;
        }

        @include min-screen($xlg) {
            height: 224px;
        }

        @include max-screen($lg - 1) {
            .btn {
                display: none;
            }
        }

        .#{$m}--with_sponsors & {
            position: relative;
            height: auto;
            margin-top: 39px;
            padding: 0 0 48px 0;
            text-align: left;
            background: #fff url("#{$imagesPath}/special-bg.png") repeat-y;

            @include min-screen($lg) {
                margin-top: 0;
                padding: 45px 0 58px 0;
                background: url("#{$imagesPath}/special-bg.png") no-repeat center;
                border-left: 1px solid #ededed;
                border-right: 1px solid #ededed;
            }
        }
    }

    .article_list {

        display: inline-block;
        list-style-type: none;
        margin: 0 auto;
        padding: 0;
        font-size: 0;

        @include min-screen($lg) {
            padding-bottom: 7px;
        }

        @include min-screen($xlg) {
            padding-bottom: 1px;
        }

        &__item {

            @include min-screen($lg) {

                position: relative;
                overflow: hidden;
                display: inline-block;
                width: 200px;
                height: 116px;
                margin-right: 12px;
                background: rgba($grey_01, 0.9);

                &:last-child {
                    margin-right: 0;
                }
            }

            @include min-screen($xlg) {
                width: 256px;
                height: 128px;
            }

            .icon {
                @include font-size(24);
                color: $yellow_prim;
                position: relative;
                top: 11px;

                transition: color 0.3s;

                @include min-screen($lg) {
                    position: absolute;
                    left: 50%;
                    margin-left: -12px;
                }
            }

            @include max-screen($lg - 1) {

                .icon {
                    display: none;
                }

                &:first-child .icon {
                    display: inline-block;
                }
            }
        }

        &__link {
            display: block;
            position: relative;

            @include min-screen($lg) {

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                &:before {
                    content: " ";
                    position: absolute;
                    top: 43px;
                    left: 28px;
                    display: block;
                    width: 144px;
                    height: 0;
                    border-bottom: 1px solid rgba($white, 0.4);
                }
            }

            @include min-screen($xlg) {
                &:before {
                    left: 56px;
                }
            }

            &:hover {
                .article_list__title {
                    color: $grey_04;
                }

                > .icon {
                    color: $yellow_sec;
                }
            }
        }

        &__title {
            margin-top: 16px;
            padding: 5px 40px;
            font: $font_prim;
            @include font-size(16);
            @include line-height(22);
            @include letter-spacing(-25);
            color: #fff;
            transition: color 0.3s;

            @include min-screen($lg) {
                height: 45px;
                margin-top: 52px;
                padding: 5px;
                @include font-size(18);
                @include line-height(20);
            }

            @include min-screen($xlg) {
                height: 60px;
                margin-top: 50px;
                @include font-size(22);
                @include line-height(28);
                overflow: hidden;
            }

            @include max-screen($lg - 1) {
                &:before {
                    content: " ";
                    position: absolute;
                    top: -8px;
                    left: 50%;
                    margin-left: -72px;
                    display: block;
                    width: 144px;
                    height: 0;
                    border-bottom: 1px solid rgba($white, 0.4);
                }
            }
        }

        @include max-screen($lg - 1) {

            &__item:first-child .article_list__title {
                margin-top: 27px;

                &:before {
                    top: 43px;
                }
            }
        }

        .special_sponsor {

            position: relative;
            background: $white;
            overflow: visible;
            box-sizing: border-box;
            padding: 16px;
            top: -16px;

            @include max-screen($lg - 1) {
                margin: 29px auto 20px;
                width: 200px;
                top: 0;
            }

            &__link {
                display: block;
            }

            &__label {
                position: absolute;
                top: -12px;
                left: 50%;
                z-index: 10;
                transform: translateX(-50%);
                display: inline-block;
                height: 24px;
                padding: 0 5px;
                font: $font_prim;
                @include font-size(11);
                @include line-height(26);
                color: $grey_01;
                background: $yellow_prim;
                text-transform: uppercase;
            }

            &__figure {
                position: relative;
                text-align: center;

                img {
                    display: inline-block;
                    width: 100%;
                    max-width: 84px;
                    height: auto;

                    @include min-screen($xlg) {
                        max-width: 96px;
                    }
                }
            }
        }
    }

    .btn {
        height: 40px;
        margin-top: 10px;
        padding: 0 20px;
        font: $font_prim--bold;
        @include font-size(14);
        @include line-height(40);
        background: $yellow_prim;

        @include min-screen($lg) {
            margin-top: -5px;
        }

        @include min-screen($xlg) {
            height: 50px;
            margin-top: 3px;
            @include font-size(14);
            @include line-height(52);
        }
    }

    &--with_sponsors {

        @include max-screen($lg) {
            margin-top: 20px;
        }

        .#{$m}__button_wrap {
            position: relative;
            z-index: 10;
            width: 100%;
            margin: 0 auto 24px;
            padding: 0 12px;
            border-top: 4px solid $grey_04;
            box-sizing: border-box;

            @include min-screen($lg) {
                margin: 0 auto 58px auto;
                padding: 0 15px;
            }

            .btn {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -2px;
                padding: 0 30px;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__sponsor {
        position: relative;
        box-sizing: border-box;
        padding: 0 0 10px;
        text-align: center;
        background: #fff;

        @include min-screen($lg) {
            padding: 0;
        }
    }

    &__label {
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;
        background: $yellow_prim;
        padding: 6px 5px;
        font: $font_prim;
        @include font-size(11);
        text-transform: uppercase;

        .#{$m}__sponsor.hide_label & {
            @include max-screen($lg) {
                display: none;
            }
        }
    }
}
