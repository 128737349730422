$m: pieces_widget;
.#{$m} {
    padding: 0 14px;
    margin: 40px 0 50px 0;

    @include screen($sm, $lg - 1) {
        padding: 0 30px;
    }

    @include min-screen($lg) {
        padding: 0;
        margin: 80px 0 0 0;
    }

    &__item {
        margin: 0;
    }

    &__desc {
        @include font-size(15);
        @include line-height(25);

        @include min-screen($lg) {
            margin: 80px 0 0;
        }
    }

    &__figure {
        padding: 10px;
        background-color: #f4f4f4;

        &:hover {
            .pieces_widget__figure_more:after {
                @include rotate(0deg);
            }
        }
    }

    &__figure_more {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 60px;
        width: 60px;
        margin: auto;
        padding: 0;

        &:after {
            position: absolute;
            content: '';
            width: 60px;
            height: 60px;
            background: $yellow_prim;
            z-index: 0;
            @include transition(1s);
        }

        i {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            float: none;
            z-index: 1;
            color: $grey_00;
            @include font-size(44);
            line-height: 60px;
            text-align: center;
        }
    }

    &__img {
        width: 100%;
        height: auto;
    }

    .btn {
        display: block;
        width: 100%;
        margin: 30px 0 0 0;
        text-align: center;
    }
}
