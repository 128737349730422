$m: sidebar;

.#{$m} {

    &__feed {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    &__feed--nano {
        &:after {
            @include min-screen($lg) {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                height: 20px;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
            }
        }
    }
}
