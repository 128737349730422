/* FAKE Banners */

.banner_mob {
    display: inline-block;
    @include min-screen($lg) {
        display: none;
    }
}

.banner_desk {
    display: none;
    @include min-screen($lg) {
        display: inline-block;
    }
}

.dfp_banner--Halfpage {
    .banner_mob {
        display: inline-block;
        @include min-screen($xlg) {
            display: none;
        }
    }

    .banner_desk {
        display: none;
        @include min-screen($xlg) {
            display: inline-block;
        }
    }
}

#divAIO1, #divAIO2 {
    height: 0;
    overflow: hidden;
}

.dfp_banner--sticky_footer {
    div[id$="__container__"],
    div[id$="_ad_container"] {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 200;
        margin: auto;
        transform: translateY(100%);
        transition: transform 0.3s;
    }

    &.is-visible {

        div[id$="__container__"],
        div[id$="_ad_container"] {
            transform: translateY(0);
        }
    }
}

.dfp_banner--Leaderboard {

    margin-top: 20px;

    @include min-screen($lg) {
        margin-top: 30px;
        margin-bottom: 10px;

        .article & {
            margin-top: 40px;
        }
    }

    @include min-screen($xlg) {
        margin-top: 40px;

        .article & {
            margin-top: 40px;
        }
    }
}

.article_bottom--banner .dfp_banner--Leaderboard {

    margin-top: 0;
    margin-bottom: 0;

    @include min-screen($lg) {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @include min-screen($xlg) {
        margin-top: 25px;
        margin-bottom: 10px;
    }
}

.fold_column_widget .dfp_banner--Leaderboard {

    margin-top: 40px;

    @include min-screen($lg) {
        margin-top: 40px;
    }

    @include min-screen($xlg) {
        margin-top: 70px;
    }
}

.dfp_banner {
    text-align: center;
    @include cf();

    .column_sidebar & {

        div[id$="__container__"],
        div[id$="_ad_container"] {
            margin-top: 20px;
            margin-bottom: 33px;

            @include min-screen($sm) {
                margin-top: 30px;
            }
        }
    }

    .colunm_sidebar--baseline & {
        @include min-screen($lg) {
            margin-top: 81px;
        }
    }
}

.fold_section {

    &_mid--banner {

        .dfp_banner--Leaderboard {
            margin: 0;

            & > div {
                margin: 17px 0 20px;

                @include min-screen($lg) {
                    margin: 20px 0 10px;
                }

                @include min-screen($xlg) {
                    margin: 30px 0;
                }
            }
        }
    }

    &_bot--banner {

        .dfp_banner--Leaderboard {
            margin: 0;

            & > div {
                margin: 7px 0 0;

                @include min-screen($lg) {
                    margin: 40px 0 5px;
                }

                @include min-screen($xlg) {
                    margin: 60px 0 10px;
                }
            }
        }
    }
}

// Rubrika, podrubrika i specijal - margine za bannere Mid i Bot
.fold_subsection {

    &_mid--banner {

        .dfp_banner--Leaderboard {
            margin: 0;

            & > div {
                margin: 7px 0 -2px;

                @include min-screen($lg) {
                    margin: 30px 0 -10px;
                }

                @include min-screen($xlg) {
                    margin: 60px 0 -30px;
                }
            }
        }
    }

    &_bot--banner {

        .dfp_banner--Leaderboard {
            margin: 0;

            & > div {
                margin: 7px 0 0;

                @include min-screen($lg) {
                    margin: 40px 0 5px;
                }

                @include min-screen($xlg) {
                    margin: 60px 0 10px;
                }
            }
        }
    }
}

.fold_special {

    &_mid--banner {

        .dfp_banner--Leaderboard {
            margin: 0;

            & > div {
                margin: 40px 0 29px;

                @include min-screen($xs) {
                    margin: 40px 0 -2px;
                }

                @include min-screen($lg) {
                    margin: 60px 0 -10px;
                }

                @include min-screen($xlg) {
                    margin: 60px 0 -30px;
                }
            }
        }
    }

    &_bot--banner {

        .dfp_banner--Leaderboard {
            margin: 0;

            & > div {
                margin: 40px 0 0;

                @include min-screen($xlg) {
                    margin: 60px 0 10px;
                }
            }
        }
    }
}

// Clanak in article i below article
.fold_article {

    &__in_article {

        iframe {
            width: auto !important;
        }

        .dfp_banner {
            margin: 0;

            & > div {
                margin: 12px 0 29px;

                @include min-screen($xlg) {
                    margin: 12px 0 -1px;
                }
            }
        }
    }

    .dfp_banner--BellowLead {
        @include min-screen($lg) {
            display: none;
        }

        div[id$="__container__"],
        div[id$="_ad_container"] {
            margin: -5px auto 20px;
        }
    }

    &__bellow_content {

        .dfp_banner {
            margin: 0;

            & > div {
                margin: 31px 0 29px;

                @include min-screen($xs) {
                    margin: 31px 0 7px;
                }

                @include min-screen($xlg) {
                    margin: 51px 0 9px;
                }
            }
        }
    }

    .author__infobox + &__bellow_content {

        .dfp_banner {
            margin: 0;

            & > div {
                margin: 13px 0 29px;

                @include min-screen($xs) {
                    margin: 13px 0 7px;
                }

                @include min-screen($lg) {
                    margin: 13px 0 9px;
                }

                @include min-screen($xlg) {
                    margin: 20px 0 9px;
                }
            }
        }
    }
}
