$xlg_sec: 1530px;

$m: footer;
.#{$m} {

    &__logo {

        display: inline-block;
        position: absolute;
        left: 10px;
        top: 0;
        z-index: 30;
        transition: all 0.5s ease-in-out;


        @include min-screen($lg) {
            left: 10px;
        }

        @include min-screen($xlg) {
            left: 0;
            z-index: 20;
        }

        @include max-screen($lg - 1) {
            position: static;
            margin: 1px 0 18px;
        }

        img {
            vertical-align: middle;
        }

        &_heading {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
            font-size: 0;

            @include min-screen($lg) {
                margin: 20px 0 0 -2px
            }

            @include min-screen($xlg) {
                margin: 6px 0 0 12px
            }
        }

        &_img {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;

            &--big {
                display: none;

                @include min-screen($xlg) {
                    width: 216px;
                    height: 49px;
                    display: block;
                }
            }

            &--small {
                display: block;
                top: 12px;
                width: 114px;
                height: 26px;

                @include min-screen($xlg) {
                    display: none;
                }
            }
        }

        @include max-screen($lg - 1) {

            transition: left 0.3s ease-in-out 0.1s, transform 0.3s ease-in-out 0.1s;

            .has_navigation & {
                left: 50%;
                z-index: 1;
                transform: translateX(-50%);
                transition-delay: 0;
            }
        }
    }
}

$m: footer;
.#{$m} {

    position: relative;
    z-index: 100;
    padding: 0;

    @include max-screen($lg - 1) {
        left: 50%;
        width: 540px;
        margin-left: -270px;
    }

    @include max-screen($sm - 1) {
        left: 0;
        width: 100%;
        margin-left: 0;
    }

    footer {
        position: relative;
    }

    &_wrapper{
        margin: 0;
        @include cf();

        @include min-screen($lg) {
            margin: 50px 0 0;
        }
    }

    &__meta {
        display: block;
        position: absolute;
        top: 7px;
        right: 12px;

        @include min-screen($lg) {
            margin: 15px 0 11px 0;
        }

        @include min-screen($xlg) {
            margin: 6px 0 11px 0;
        }

        @include max-screen($lg - 1) {
            position: static;
            margin-bottom: 25px;
        }
    }

    &__social {
        text-align: center;
        @include font-size (15);

        @include min-screen($lg) {
            float: left;
            padding: 0;
        }

        @include min-screen($xlg) {
            height: 40px;
            padding-top: 0;
            @include line-height(40);
        }
    }

    &__social_item {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 0 4px;
        @include line-height(40);
        background: $color_tw;
        color: $white;

        &:first-of-type {
            background: $color_fb;
        }

        &:hover {
            .icon {
                color: #fff;
            }
        }

        @include min-screen($lg) {
            margin-left: 12px;
            margin-right: 0;
            .icon {
                @include line-height(24);
            }
        }

        &--text {
            display: block;
            width: 100%;
            height: 32px;
            font: $font_prim--med;
            @include font-size(14);
            @include line-height(32);
            color: $grey_02;
            margin: 0;
            background: none;
            text-transform: uppercase;

            &:first-of-type {
                margin: 0;
                background: none;
            }

            @include min-screen($lg) {
                display: inline-block;
                width: auto;
                height: 40px;
                margin: 0;
                float: left;
                @include line-height(40);
                @include font-size(13);

                &:first-of-type {
                    margin: 0;
                }
            }

            @include min-screen($xlg) {
                @include font-size(15);

                &:first-of-type {
                    margin: 0;
                }
            }
        }

        > .icon {
            position: relative;
            color: $white;
            @include font-size(24);
            @include line-height(40);
            @include transition(0.2s);
        }
    }

    .footer__copy {
        display: inline-block;
        position: absolute;
        bottom: 5px;
        right: 12px;
        margin: 8px 0;
        clear: both;
        font: $font_sec;
        @include font-size(14);
        @include line-height(40);
        color: #fff;

        @include max-screen($lg - 1) {
            position: static;
            display: block;
            background: $grey_00;
            text-align: center;
            margin: 0;
        }
    }

    &__partners_label,
    &__partners_btn {
        font: $font_sec;
        @include font-size(14);
        @include line-height(38);
    }

    &__partners_label {
        display: inline;
        color: $grey_02;

        @include min-screen($lg) {
            display: inline;
            padding-right: 17px;
         }
    }

    &__partners_btn {
        @include border-box();
        display: block;
        text-align: center;
        color: #fff;

        @include min-screen($lg) {
            display: none;
         }
    }
}

.footer_navigation {

    background-color: $grey_00;

    &--site {

        padding: 0 30px;
        background: $grey_01;
        text-align: center;


        @include min-screen($lg) {
            margin: 0;
        }

        @include min-screen($xlg) {
            height: 64px;
            @include line-height(64);
        }

        .footer_nav {

            display: block;
            margin: 8px 0 12px;

            @include min-screen($lg) {
                display: inline-block;
                margin: 18px 0 16px 0;
            }

            @include min-screen($xlg) {
                display: inline-block;
                margin: 3px 0 0 0;
            }
        }

        .footer_nav__list {
            @include max-screen($lg - 1) {
                column-count: 2;
            }
        }

        .footer_nav__item {
            display: block;
            width: 100%;
            padding: 0 17px;
            margin: 0 !important;
            position: relative;

            &:first-child {

                &:before {
                    display: none;
                }
            }

            @include min-screen($lg) {
                display: inline-block;
            }

            @include min-screen($xlg) {
                padding: 0 10px;

                &:first-child {
                    padding: 0 10px 0 0;
                }
            }
        }

        .footer_nav__span {
            font: $font_prim--med;
            text-transform: uppercase;
            color: $white;
            @include letter-spacing(0);
            @include font-size(14);
            @include line-height(40);

            @include min-screen($lg) {
                @include font-size(14);
                @include line-height(24);
            }
        }

        .footer_nav__link:hover .footer_nav__span {
            color: $yellow_prim;
        }
    }

    &--footer {
        position: relative;
        @include border-box();
        height: 65px;
        background: $grey_00;
        text-align: center;

        @include min-screen($lg) {
            text-align: left;
            margin: 0;
            padding: 0 12px;
            @include line-height(65);
        }

        .main_nav {
            display: inline-block;

            @include min-screen($lg) {
                display: inline-block;
            }

            &.is_opened {
                display: block;
            }
        }

        .footer_nav {
            @include max-screen($lg - 1) {
                display: block;
                text-align: center;
                margin-top: -4px;
            }
        }

        .footer_nav__list {
            @include max-screen($lg - 1) {
                display: inline-block;
            }
        }

        .footer_nav__list .footer_nav__item {
            display: inline-block;
            padding: 0 5px;
            margin: 0;

            @include min-screen($lg) {
                width: auto;
                margin: 0 13px 0 0;
                float: none;

                &:last-child {
                    margin: 0;
                }
            }

            @include min-screen($xlg) {
                padding: 0;
                margin: 0 25px 0 0;
                @include font-size(14);

                &:last-child {
                    margin: 0;
                }
            }
        }

        .footer_nav__link .footer_nav__span {
            font: $font_prim--bold;
            @include font-size(14);
            color: $white;
            text-transform: uppercase;

            @include max-screen($lg - 1) {
                @include line-height(40);
            }

            @include min-screen($lg) {
                display: inline-block;
                @include font-size(13);
                @include line-height(29);
            }

            @include min-screen($xlg) {
                @include font-size(14);
                @include line-height(30);
            }
        }

        .footer_nav__link:hover .footer_nav__span {
            color: #a3a3a3;
        }
    }

    .footer_nav {

        @include min-screen($lg) {
            display: inline-block;
        }

        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            text-align: left;

            @include min-screen($lg) {
                display: inline-block;
                max-width: 900px;
            }

            @include min-screen($xlg) {
                line-height: inherit;
                max-width: 1000px;
            }

            @include min-screen($xlg) {
                line-height: inherit;
                max-width: 1100px;
            }

            @include min-screen(1600px) {
                max-width: 1600px;
            }
        }

        &__item {
            @include border-box();
            margin: 0 18px 0 0;
            font-size: 0;

            @include min-screen($lg) {
                width: auto;
                margin: 0 18px 0 0;
            }
        }

        &__link {
            text-decoration: none;
        }

        &__span {
            display: block;
            margin: 0;
            padding: 0;
            text-align: center;
            color: #fff;
            @include transition(0.3s);
        }
    }

    .main_nav {

        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        &__item {
            position: relative;
            display: inline-block;

            &:first-child .main_nav__span {

                padding-left: 0;

                &:before {

                    display: none;
                }
            }
        }

        &__link {
            text-decoration: none;

            &:hover {
                .main_nav__span {
                    color: #6b6b6b;
                }
            }
        }

        &__span {
            display: block;
            padding: 0 10px;
            font: $font_prim--bold;
            @include font-size (14);
            color: $white;
            position: relative;
            text-transform: uppercase;
        }
    }
}

.powered_by {

    background-color: $grey_00;

    @include min-screen($lg) {
        position: absolute;
        overflow: hidden;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        height: 60px;
        width: 249px;
        background-color: transparent;
    }

    @include max-screen($lg - 1) {
        position: static;
        padding-top: 10px;
    }

    &__inner_wrap {
        height: 76px;
        width: 249px;
        margin: 0 auto;
        clear: both;

        @include max-screen($lg - 1) {
            text-align: center;
            height: auto;
        }
    }

    span {
        float: left;
        font: $font_prim;
        @include font-size(14);
        @include line-height(76);
        color: $grey_02;

        @include max-screen($lg - 1) {
            display: block;
            float: none;
            @include line-height(1);
            padding-top: 20px;
        }
    }

    img {
        display: inline-block;
        float: left;
        width: 163px;
        height: auto;
        margin: 11px 0 0 0;
        transition: opacity .3s;
    }

    &_link {
        display: inline-block;

        &:hover img {
            opacity: .8;
        }
    }
}
