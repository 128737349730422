$m: cal;

.#{$m} {
    position: relative;
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    font: $font_prim--bold;

    &__header {
        position: relative;
        display: block;
        height: 42px;
        margin: 0;
        padding: 0;
        @include cf();
        background: #000;
        color: #fff;
    }

    &__nav {
        position: relative;
        display: block;
        width: 42px;
        height: 42px;
        margin: 0;
        padding: 0;
        text-decoration: none;

        &--left {
            float: left;
        }

        &--right {
            float: right;
        }

        [class^="icon-"], [class*=" icon-"] {
            @extend %iconsetup;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            @include font-size(24);
            line-height: 42px !important;
            line-height: 4.2rem !important;
            text-align: center;
            color: #fff;
        }
    }

    &__date {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: block;
        font: $font_prim--bold;
        text-align: center;
        @include font-size(15);
        @include line-height(42);
        cursor: default;
        text-transform: uppercase;
    }

    &__days {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        @include cf();
    }

    &__day {
        position: relative;
        display: block;
        float: left;
        width: 14.28500%;
        @include font-size(12);
        @include line-height(32);
        color: #a3a3a3;
        text-transform: uppercase;
        text-align: center;
        cursor: default;
    }

    &__table {
        position: relative;
        display: block;
        margin: 0;
        padding: 5px;
        background: #f6f6f6;
    }

    &__row {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        @include cf();
        border-top: 1px solid #d4d4d4;

        &:first-child  {
            border-top: 0;
        }

        > a {
            position: relative;
            display: block;
            float: left;
            margin: 0;
            padding: 0;
            width: 14.28500%;
            @include font-size(15);
            @include line-height(40);
            text-transform: uppercase;
            text-align: center;
            color: $grey_00;
            cursor: default;

            &.active:not(.today) {
                color: $grey_00;
                cursor: pointer;
                @include transition(0.12s);

                &:hover {
                    color: $grey_00;
                }
            }

            &.off {
                color: $grey_00;
                cursor: default;

                &:hover {
                    color: $grey_00;
                }
            }

            &.invis {
                text-indent: -9999px;
            }

            &.today {
                color: #fff;

                &::before {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin-top: -16px;
                    margin-left: -16px;
                    background: $grey_00;
                    z-index: 1;
                }
            }

            > span {
                position: relative;
                top: -1px;
                z-index: 5;
            }
        }
    }
}
