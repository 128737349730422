$m: footer;
.#{$m} {

    &_wrapper{
        margin: 0;
        @include cf();

        @include min-screen($lg) {
            margin: 35px 0 0;
        }

        @include min-screen($xlg) {
            margin: 50px 0 0;
        }
    }
}

$m: footer_new;
.#{$m} {

    &--up {

        display: flex;
        padding: 20px 0;
        background: $grey_01;

        @include min-screen($xlg) {
            padding: 12px 0;
        }
    }

    &__logo {
        flex: 0 0 auto;
        align-self: center;
        margin: 0px 35px 0 24px;
    }

    &__logo_img {

        &--big {
            display: none;

            @include min-screen($xlg) {
                display: inline-block;
                width: 214px;
                height: 25px;
            }
        }

        &--small {
            display: inlineblock;
            width: 114px;
            height: 26px;

            @include min-screen($xlg) {
                display: none;
            }
        }
    }

    &__services {
        flex: 1 1 50%;
        display: flex;

        .footer_nav {

            display: flex;
            align-self: center;

            &__list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            &__span {
                display: inline-block;
                height: 40px;
                padding: 0 17px;
                font: $font_prim--med;
                @include font-size(14);
                @include line-height(40);
                text-transform: uppercase;
                color: $white;

                @include min-screen($lg) {
                    height: 24px;
                    padding: 0 10px;
                    @include font-size(14);
                    @include line-height(28);
                    white-space: nowrap;
                }
            }
        }
    }

    &__social_wrap {
        flex: 0 1 230px;
    }

    &__social {

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 8px 0 30px;
    }

    &__social_label {

        font: $font_prim--med;
        @include font-size(14);
        color: $grey_02;
        text-transform: uppercase;

        @include min-screen($lg) {
            flex: 1 0 auto;
            white-space: nowrap;
            margin-right: 10px;
            @include font-size(13);
        }

        @include min-screen($xlg) {
            @include font-size(15);
        }
    }

    &__social_item {

        flex: 0 0 40px;
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 0 4px;
        @include font-size(24);
        @include line-height(44);
        text-align: center;
        color: $white;
        background: $color_fb;

        &--tw {
            background: $color_tw;
        }
    }

    &--down {

        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height: 64px;
        background: $grey_00;
    }

    &__partners_wrap {
        flex: 0 0 33%;
        display: flex;
    }

    &__partners {
        display: flex;
        align-items: center;
        margin-left: 22px;

        .footer_nav {

            display: flex;
            align-self: center;
            margin-left: 8px;

            &__list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            &__span {
                display: inline-block;
                height: 40px;
                padding: 0 17px;
                font: $font_prim--med;
                @include font-size(14);
                @include line-height(40);
                text-transform: uppercase;
                color: $white;

                @include min-screen($lg) {
                    height: 24px;
                    padding: 0 10px;
                    @include font-size(14);
                    @include line-height(28);
                    white-space: nowrap;
                }
            }
        }
    }

    &__partners_label {

        display: inline-block;
        font: $font_prim;
        @include font-size(14);
        @include line-height(25);
        color: $grey_02;

        @include min-screen($lg) {
            @include font-size(13);
        }

        @include min-screen($xlg) {
            @include font-size(15);
        }
    }

    &__powered_wrap {
        flex: 0 0 33%;
    }

    &__powered {

        display: flex;
        justify-content: center;

        img {

            height: 38px;
            width: auto;
            margin: 21px 0 0 0;
        }

        &_item {
            display: inline-block;
            @include min-screen($lg) {
                margin-left: 8px;
            }
        }

        &_link {

            display: inline-block;
            &:hover img {
                opacity: .8;
            }
        }
    }

    &__powered_label {

        font: $font_prim;
        @include font-size(14);
        @include line-height(76);
        color: $grey_02;
    }

    &__copyright {
        flex: 0 0 33%;
        text-align: right;
        font: $font_sec;
        @include font-size(14);
        @include line-height(40);
        color: $white;

        @include min-screen($lg) {
            margin: 0 22px 0 0;
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &--disclaimer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        background: $grey_00;
        text-align: center;
        font: $font_prim--it;

        p {
            max-width: 750px;
            color: $white;
        }

        p, a {
            @include font-size(12);
        }

        &__link_wrap {
            a {
                color: $grey_02;
                text-decoration: underline;
            }
        }
    }
}

.#{$m} {

    @include max-screen($lg - 1) {

        width: 100%;
        max-width: 540px;
        margin: 0 auto;

        &--up,
        &--down {
            flex-wrap: wrap;
            flex-align: center;
            height: auto;
        }

        &__logo,
        &__services,
        &__social_wrap,
        &__partners_wrap,
        &__powered_wrap,
        &__copyright {
            flex: 1 0 100%; // 100% širine
            margin: 0;
        }

        &__logo {
            margin-top: -7px;
            text-align: center;
        }

        &__services {

            margin-top: 13px;
            padding: 0;

            .footer_nav__item {
                flex: 0 0 50%;
                text-align: center;
            }
        }

        &__social_wrap {
            margin-top: 20px;
            text-align: center;
        }

        &__social {
            margin: 0;
            display: inline-block;
        }

        &__social_label {
            margin: 0 0 10px;
            display: block;
        }

        &__partners_wrap {
            margin-top: 10px;
            text-align: center;
        }

        &__partners {
            display: inline-block;
            margin: 0 auto;

            .footer_nav {

                &__list {
                    justify-content: center;
                }
            }
        }

        &__powered_wrap {
            margin-top: 15px;
            text-align: center;
        }

        &__powered {
            display: inline-block;
            margin: 0;

            &_item {
                margin-top: -7px;
            }
        }
        &__powered_label {
            display: block;
            line-height: 1;
        }

        &__copyright {
            margin-top: 2px;
            text-align: center;
        }
    }

    @include max-screen($sm - 1) {
        &__services {

            margin-top: 13px;
            padding: 0;

            .footer_nav__item {
                flex: 0 0 100%;
                text-align: center;
            }
        }
    }
}
