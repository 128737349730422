/////////////////////////////////////////////////////
// Notifications wigdet
/////////////////////////////////////////////////////
.btn--prijava {
    margin: 10px;
}

.btn--facebook_prijava {
    margin: 10px;
}

.prijava__separator {
    position: relative;
    top: -16px;
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 40px;
    font: $font_prim--it;
    @include font-size(16);
    @include line-height(40);
    @include letter-spacing(40);
    color: #636363;
    text-align: center;
}

.ntfs {
    display: none;
    opacity: 0;
    position: fixed;
    top: 56px;
    right: 20px;
    z-index: 400;
    width: auto;
    min-width: 320px;
    margin-left: 185px;
    padding: 0;
    background: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.13);
    -webkit-transition: transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out;

    -webkit-transform: rotateX(-30deg);
    transform: rotateX(-30deg);

    @include max-screen(400px) {
          left: 0;
          width: 100%;
          right: auto;
          margin: 0;
          height: 640px;
    }

    &.header--small {
        top: 70px;
    }

    @include min-screen($sm) {
        right: 0;
    }

    @include min-screen($xlg) {
        top: 80px;
    }

    &.is_opened {

        display: block;
        -webkit-transform-origin: center top;
                transform-origin: center top;
        -webkit-animation: 0.3s ntfs_open;
                animation: 0.3s ntfs_open;
        -webkit-animation-fill-mode: forwards; /* Chrome, Safari, Opera */
                animation-fill-mode: forwards;
    }

    @include keyframes(ntfs_open) {
        0%   { -webkit-transform: rotateX(-30deg); transform: rotateX(-30deg);   }
        80%  { -webkit-transform: rotateX(0deg); transform: rotateX(0deg); opacity: 1; }
        100% { -webkit-transform: rotateX(0deg); transform: rotateX(0deg); opacity: 1; }
    }

    &__flyout {
        -webkit-perspective: 500px; /* Chrome, Safari, Opera */
                perspective: 500px;
        perspective-origin: 70% 50%;
        position: relative;
        z-index: 200;
    }

    &__header {
        position: relative;
        display: block;
        @include border-box();
        padding: 12px 20px 12px 12px;
        margin: 0;
        background: #fff;
        text-align: left;
        @include cf();
        overflow: hidden;
        box-shadow: inset 0px 21px 21px -21px rgba(0, 0, 0, 0.08);

        &--unlogged {
            padding: 12px 25px 12px 12px;
        }

        .btn {

            padding: 0 12px;

            &:first-child {
                margin-right: 6px;

                @include min-screen($sm) {
                    margin-right: 12px;
                }
            }

            .icon {
                color: $white;
            }
        }
    }

    &__header_figure_wrap {
        position: relative;
        display: block;
        float: left;
        width: 52px;
        height: 52px;
        margin: 0 12px 0 0;
        padding: 0;
        overflow: hidden;
    }

    &__header_figure {
        position: relative;
        display: block;
        margin: 0 0;
        padding: 0;
        overflow: hidden;

        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
        }
    }

    &__header_name {
        @include border-box();
        position: relative;
        overflow: hidden;
        display: block;
        height: 30px;
        margin: 0;
        padding: 0;
        font: $font_prim--bold;
        @include font-size(16);
        @include line-height(30);
        color: $grey_01;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;
    }

    &__header_link {
        position: relative;
        font: $font_prim;
        @include font-size(12);
        text-transform: uppercase;
        color: $grey_01;
        padding-bottom: 1px;
        box-shadow: inset 0 -4px 0 $grey_04;

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            box-shadow: inset 0 -3px 0 $grey_04;
        }
    }

    &__header--above_nano {
        position: relative;
        display: block;
        height: 11px;
        margin: 0;
        padding: 0 12px;
        @include font-size(11);
        @include line-height(11);
        @include letter-spacing(50);

        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $grey_04;
        }

        span {
            font: $font_prim--bold;
            display: inline-block;
            height: 30px;
            color: $grey_01;
            z-index: 10;
            @include font-size(14);
            @include line-height(32);
            @include letter-spacing(0);
            text-transform: uppercase;
        }

        a {
            position: relative;
            display: inline-block;
            font: $font_prim;
            text-transform: none;
            color: $grey_02;
            float: right;
            @include font-size(12);
            @include line-height(32);
            @include letter-spacing(0);

            box-shadow: none;

            &:hover {

                &:after {
                    height: 13px;
                }
                &:before {
                    height: 0px;
                }
            }
        }
    }

    &__cards {
        position: absolute;
        right: 10px;
        bottom: 0;
        height: 14px;
        font-size: 0;
    }

    &__card {
        position: relative;
        top: 3px;
        display: inline-block;
        width: 10px;
        height: 14px;
        margin: 0 0 0 5px;
        border: 1px solid #d6d6d6;
        border-radius: 2px;
        background: #fff;

        &--yellow.is_active {
            top: 0;
            background: #feda10;
            border: 1px solid #dfbf0e;
        }

        &--red.is_active {
            top: 0;
            background: #fd3425;
            border: 1px solid #d62518;
        }
    }

    &__header_close {
        position: absolute;
        top: 0;
        right: -2px;
        display: block;
        width: 40px;
        height: 40px;
        margin: auto 0;
        color: $grey_00;
        @include font-size(24);
        @include line-height(44);
        text-align: center;
        @include transition(0.12s);
        cursor: pointer;
    }

    &__content {
        position: relative;
        top: -2px;
        margin: 0;
        padding: 0;
    }

    &__list {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list_item {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        @include cf();
        border-bottom: 1px solid $grey_04;

        &:first-child {
            border-top: 1px solid $grey_04;
        }
    }

    &__link {
        position: relative;
        display: block;
        min-height: 52px;
        margin: 0;
        padding: 12px;
        text-decoration: none;
        @include transition(0.12s);
        background: $grey_05;

        &--seen {
            background: #fff;
        }

        &--admin {

            &::after {
                content: " ";
                position: absolute;
                top: 10px;
                left: 0;
                bottom: 10px;
                display: block;
                width: 3px;
                background: $red;
            }
        }

        &:hover {
            background: #fffec9;
        }
    }

    &__figure_wrap {
        position: relative;
        display: block;
        float: left;
        width: 52px;
        height: 52px;
        margin: 6px 18px 0 6px;
        padding: 0;
        overflow: hidden;
        background: #fff;
        border: 2px solid #fff;
        overflow: hidden;
    }

    &__figure {
        position: relative;
        display: block;
        width: 52px;
        height: 52px;
        margin: 0 12px 0 0;
        float: left;

        &::after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
        }

        &--banned {
            font: $font_prim--bold;
            @include font-size(40);
            text-align: center;
            @include line-height(50);
            color: #fff;
            background: $red;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__text {
        @include border-box();
        position: relative;
        top: -3px;
        display: block;
        margin: 0;
        padding: 0;
        overflow: hidden;
        @include font-size(15);
        @include line-height(20);
        color: #1e1e1e;

        strong {
            font: $font_prim--bold;
            margin-right: 10px;

            &:empty {
                display: none;
            }
        }
    }

    &__title {
        margin: 0;
        font: $font_prim--bold;
        @include font-size(14);
        @include line-height(19);
        color: $grey_00;
    }

    &__text_content {
        margin: 0;
        font: $font_sec;
        @include font-size(14);
        @include line-height(19);
        color: $grey_00;
    }

    &__time {
        @include border-box();
        position: relative;
        top: 1px;
        display: block;
        height: 10px;
        margin: 0;
        padding: 0;
        text-decoration: none;
        font: $font_prim;
        @include font-size(12);
        @include letter-spacing(0);
        color: #8f8f8f;
        text-transform: uppercase;
    }

    &__icon {
        position: absolute;
        bottom: 8px;
        right: 10px;
        display: block;

        > span {
            position: relative;
            margin: 0;
            padding: 0;
            display: block;
            width: 16px;
            height: 16px;
            @include line-height(16);

            &::after {
                @extend %iconsetup;
                content: " ";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                @include font-size(16);
                color: #1197dd;
                text-align: center;

                .ntfs__link--seen & {
                    color: #9c9c9c;
                }
            }
        }

        &--reply > span::after { // comment
            content: "\f265";
        }

        &--message > span::after { // mail
            content: "\e907";
        }

        &--follow > span::after { // plus
            content: "\f277";
        }

        &--ban > span::after {
            content: "\f119";
            color: #eb0000;
        }

        &--eye > span::after {
            content: "\f15c";
        }

        &--redcard > span { // violation // ban
            position: relative;
            right: 2px;
            display: block;
            top: 3px;
            width: 10px;
            height: 14px;
            border: 1px solid #d62518;
            border-radius: 2px;
            background: #fd3425;

            ::after {
                display: none;
            }
        }

        &--yellowcard > span { // violation // ban
            position: relative;
            right: 2px;
            display: block;
            top: 3px;
            width: 10px;
            height: 14px;
            border: 1px solid #dfbf0e;
            border-radius: 2px;
            background: #feda10;

            ::after {
                display: none;
            }
        }
    }

    .google_sign_in_wrap {
        display: inline-block;
        position: relative;
        top: 16px;

        @include max-screen($lg - 1) {
            left: -10px;
            margin-bottom: 10px;
        }
    }
}
