.users {
    @include clearfix();
    padding: 20px 0;
    margin: 0;
    @include font-size(16);

    &__title {
        padding: 0 0 10px;
        margin: 0;
        @include font-size(15);
        @include line-height(22);
    }

    &__separator {
        margin: 0 -20px;
        padding: 0 0 20px;
    }

    &__avatar_help {
        background: #1e1e1e;
    }

    &__list {
        list-style: none;
        margin: 20px 0;
        padding: 0;
    }
}
