$c: commbox;

.commbox {
    position: relative;
    overflow: hidden;
    width: 100%;
    @include font-size(16);
    @include line-height(26);

    &__wrap {
        padding-bottom: 25px;
    }

    &__avatar {
        float: left;
        margin-right: 25px;
    }

    &__avatar_img,
    &__avatar_img > img {
        display: block;
    }

    &__interaction {
        font-size: 0;
        position: relative;
    }

    &__replies,
    &__reply,
    &__time,
    &__flag {
        display: inline-block;
        margin-right: 10px;
    }

    &__replies {
        margin-right: 10px;

        @include min-screen($lg) {
            margin-right: 20px;
        }

        .post__thread & {
            display: none;
        }
    }

    &__time {
        @include font-size(16);
        @include line-height(26);
    }

    &__flag {

        @include font-size(24);
        @include line-height(40);

    }

    &__rating {
        float: right;
        display: inline-block;
        margin: -3px 5px 0 0;

        .thread__sublist & {
            float: none;
        }

        .vote--positive {
            margin-left: -2px;
        }

        &:after {
            clear: both;
        }
    }

    &__vote {

        display: inline-block;
        @include font-size(24);
        @include line-height(40);

        .vote {

            &__value {
                position: relative;
                top: -5px;
            }

            &--invert {
                @include transition(0.12s);
            }
        }
    }
}

// upper dio komentara//
.comments__upper {
    margin: 0;

    @include max-screen($lg) {
        padding: 0 12px;
    }

    .fake {
        &__form_select_wrap {
            position: relative;
            padding: 0;
            float: right;
        }

       &__form_select_box {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

         &__form_select_list {
            position: relative;
            display: none;
            margin: 0;
            padding: 0;
            list-style: none;
            background: transparent;
            z-index: 11; // paginacija ima veći

            &.is_visible {
                display: block;
                position:absolute;
            }
        }
    }
}

/* TEMA */
$th: th_zurnal;

.comments__upper--#{$th} {

    .pagination {
        margin: 0 -12px 0;

        @include min-screen($sm) {
            margin: 0 -27px 0;
        }

        @include min-screen($lg) {
            margin: 0 0 7px 0;
        }
    }

    .comments__filters_wrap {
        float: none;
    }

    .comments__label {

        display: block;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 15px;
        font: $font_prim--light;
        @include font-size(24);
        @include line-height(28);
        @include letter-spacing(-25);
        text-transform: uppercase;
        color: $grey_01;

        @include min-screen($lg) {
            margin-top: 35px;
            margin-bottom: 3px;
            @include font-size(26);
            @include line-height(28);
        }

        strong {
            font: $font_prim--bold;
            @include font-size(24);
            @include line-height(28);

            @include min-screen($lg) {
                @include font-size(26);
                @include line-height(28);
            }
        }
    }

    .comments__show_answers {
        margin-left: 5px;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(40);
        @include letter-spacing(0);
        text-transform: uppercase;
        color: $grey_01;
    }

    .fake {

        &__form_select_wrap {
            display: inline-block;
            height: 40px;
            margin-right: 0;
            float: none;
            text-align: left;
            background: $grey_05;
        }

        &__form_select {

            &_btn,
            li {
                font: $font_prim--med;
                @include font-size(14);
                @include line-height(40);
                @include letter-spacing(0);
                text-transform: uppercase;
                color: $grey_01;
            }

            &_btn {
                position: relative;
                display: inline-block;
                padding: 0 40px 0 10px;

                &:hover {
                    cursor: pointer;
                }

                .icon {
                    @include font-size(25);
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }
            }

            li {
                padding: 0 10px;
                @include line-height(36);

                &:hover {
                    background-color: $grey_04;
                    cursor: pointer;
                }
            }
        }

        &__form_select_list {
            text-align: left;

            &.is_visible {
                right: 0px;
                top: -3px;
                left: auto;
                background-color: white;
                box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
                width: 100%;
                box-sizing: border-box;
                padding: 5px 0;

                @include min-screen($lg) {
                    width: 150px;
                    right: -1px;
                    top: -3px;
                }
            }
        }
    }

    .comments__show_answers {
        display: none;

        @include min-screen($lg) {
            display: inline-block;
            height: 40px;
            padding-left: 10px;
            padding-right: 10px;
            @include line-height(40);
            background: $yellow_prim;
        }

        &:hover {
            color: $grey_01;
        }
    }
}

.thread__unlogged {
    .btn_line {
        font-size: 0;

        > .btn {
            margin: 0 2px;

            @include min-screen($md) {
                margin: 0 4px;
            }
        }
    }
}

.commbox--#{$th}  {
    overflow: visible;
}

.commbox {

    .thread__sublist &,
    &.--self {

        overflow: visible;
        position: relative;
        z-index: 10;

        &:after {
            width: 10px;
            right: auto;
            background: $grey_00;
        }
    }

    &__wrap {
        padding: 0;

        @include min-screen($lg) {
            padding: 20px 20px 0 10px;
        }

        @include min-screen($xlg) {
            padding: 20px 20px 8px 10px;
        }
    }

    &__avatar {

        width: 40px;
        height: 40px;
        margin-right: 12px;

        @include min-screen($xlg) {
            width: 64px;
            height: 64px;
            margin-right: 24px;
        }

        img {
            width: 100%;
        }

        .thread__sublist & {
            width: 40px;
            height: 40px;
        }
    }

    &__avatar_img_wrap {
        position: relative;
        display: block;
        overflow: normal;
    }

    &__avatar_img {

        > img {
            width: 100%;
            height: auto;
        }
    }

    &__user {
        display: inline-block;
        font: $font_prim--bold;
        color: $grey_01;
        @include font-size (16);

        margin-top: 1px;

        @include min-screen($xlg) {
            @include font-size (18);
        }

        @include max-screen($lg - 1) {
            overflow: hidden;
            width: 80%;
            margin-right: -60px;
        }
    }

    &__time {

        display: block;
        padding: 5px 0 6px;
        font: $font_prim;
        @include font-size (12);
        color: $grey_03;
        text-transform: uppercase;

        @include min-screen($lg) {
            display: inline-block;
            margin-left: 18px;
            padding: 0 0 0 20px;
            @include font-size (12);
            @include line-height (19);
        }

        &--top {
            @include min-screen($lg) {
                float: none;
                padding: 0;
            }
        }
    }

    &__content {
        position: relative;
        margin-top: 20px;
        font: $font_prim;
        @include font-size(15);
        @include line-height(20);
        color: $grey_01;

        @include min-screen($lg) {
            overflow: hidden;
            margin-top: 0;
        }

        @include min-screen($xlg) {
            margin-top: 2px;
            @include font-size(16);
            @include line-height(24);
        }
    }

    &__post_content {
        margin: 9px 0 0;
        padding: 0;

        @include min-screen($lg) {
            margin-top: 8px;
            overflow: hidden;
        }

        @include min-screen($xlg) {
            margin-top: 5px;
        }

        &--hidden {
            display: none;
        }
    }

    &__more {
        font: $font_prim;
        @include font-size(14);
        box-shadow: inset 0 -4px 0 $grey_04;

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            box-shadow: inset 0 -3px 0 $grey_04;
        }

        &:hover {
            text-decoration: none;
            transition: 0.2s ease-in-out;
            box-shadow: inset 0 -4px 0 $grey_03;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_03;
            }
        }
    }

    &__interaction {
        margin-top: 3px;
    }

    &__flag {

        color: $grey_03;

        &:hover {
            color: $grey_06;
        }

        .icon {
            @include font-size(24);
            @include line-height(40);
        }

        &.btn--invert {
            color: $red;
        }
    }

    &__vote {

        color: $grey_03;

        &:hover {
            color: $grey_06;
        }

        &.btn--invert {
            color: $grey_01;
        }

        @at-root .vote {

            .icon {
                @include font-size(24);
                @include line-height(40);
            }

            &__value {
                position: relative;
                top: -6px;
                font: $font_prim;
                @include font-size(14);
            }

            &--positive {
                margin-right: 17px;

                @include min-screen($lg) {
                    margin-left: 10px;
                    margin-right: 0;
                }
            }

            &--negative {

                @include min-screen($lg) {
                    margin-left: 18px;
                }
            }

            &--invert {
                @include transition(0.12s);
            }
        }
    }

    &__reply,
    &__replies {
        height: auto;
        padding: 0;
        color: $grey_01;
        background: none;
        text-transform: none;

        &:hover {

            background: none;

            span {
                text-decoration: none;
                transition: 0.2s ease-in-out;
                box-shadow: inset 0 -4px 0 $grey_03;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_03;
                }
            }
        }

        span {
            display: inline-block;
            padding: 0;
            font: $font_prim--med;
            @include font-size(14);
            @include line-height(20);
            @include letter-spacing(0);
            box-shadow: inset 0 -4px 0 $grey_04;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_04;
            }
        }
    }

    &__replies.is_active span {

        box-shadow: inset 0 -4px 0 $yellow_prim;

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            box-shadow: inset 0 -3px 0 $yellow_prim;
        }
    }

    &__reply  {

        margin-right: 18px;

        span {
            font: $font_prim;
            @include font-size(14);
            @include line-height(20);
            box-shadow: inset 0 -4px 0 $grey_04;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_04;
            }
        }
    }

    &__reply.has_commentWindow span {

        box-shadow: inset 0 -4px 0 $yellow_prim;

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            box-shadow: inset 0 -3px 0 $yellow_prim;
        }
    }
}

.onecomment--#{$th} {
    position: relative;

    &.onecomment__new_comment  {
        background-color: $white;
        margin-top: 23px;

        @include min-screen($lg) {
            margin-top: 24px;
        }

        @include min-screen($xlg) {
            margin-top: 28px;
        }

        .thread__item & {

            margin-left: 52px;
            margin-bottom: 15px;

            @include min-screen($lg) {
                margin-left: 51px;
                margin-bottom: 15px;
            }

            @include min-screen($xlg) {
                margin-left: 89px;
                margin-bottom: 15px;
            }

            background-color: $white;

            &:before {
                content: " ";
                display: block;
                position: absolute;
                top: -23px;
                right: 0;
                left: 0;
                height: 0;
                border-bottom: 1px solid $grey_04;
            }

            .commbox__avatar {
                width: 40px;
                height: 40px;
                margin-right: 11px;

                @include min-screen($lg) {
                    margin-right: 10px;
                }

                @include min-screen($xlg) {
                    margin-right: 20px;
                }

                img {
                    width: 100%;
                }
            }

            .onecomment__username {
                @include max-screen($sm - 1) {
                    position: relative;
                    top: -2px;
                }

                @include min-screen($lg) {
                    margin-left: 4px;
                }
            }

            .onecomment__content {
                margin-left: 0;

                @include min-screen($lg) {
                    margin-left: 53px;
                }

                @include min-screen($xlg) {
                    margin-left: 63px;
                }
            }

            .onecomment__counter {
                display: block;
                text-transform: uppercase;
                @include min-screen($lg) {
                    display: inline;
                    right: 15px;
                }
            }
        }
    }

    .onecomment {

        &__content {

            @include min-screen($lg) {
                margin-left: 52px;
            }

            @include min-screen($xlg) {
                margin-left: 89px;
            }
        }

        &__avatar_figure {
            margin-top: -2px;
        }

        &__username {
            font: $font_prim--bold;
            @include font-size(16);
            @include line-height(24);
            color: $grey_01;
            margin-right: 20px;

            @include min-screen($xlg) {
                @include font-size(18);
            }
        }

        &__form_textarea {
            padding: 9px 10px;
            margin: 14px 0 10px;
            box-sizing: border-box;
            resize: none;
            width: 100%;
            border: 1px solid $grey_04;
            background: $grey_05;
            font: $font_prim;
            @include font-size(14);
            @include line-height(20);
            color: $grey_02;
            overflow: hidden; // IE 11 fix
            border-radius: 0;
            box-shadow: none;
            -webkit-appearance: none;

            @include min-screen($sm) {
                margin: 10px 0px;
            }

            @include min-screen($xlg) {
                padding: 7px 10px;
                @include font-size(16);
                @include line-height(24);
            }

            &:focus {
                border: 1px solid $grey_01;
                color: $grey_01;
            }

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: $grey_02;
            }
            &::-moz-placeholder { /* Firefox 19+ */
              color: $grey_02;
            }
            &:-moz-placeholder { /* Firefox 18- */
              color: $grey_02;
            }
            &:-ms-input-placeholder { /* IE 10+ */
              color: $grey_02;
            }
        }

        &__counter {

            font: $font_prim;
            @include font-size(12);
            color: $grey_03;
            text-transform: uppercase;
        }

        &__form_link {

            position: relative;
            top: 9px;
            font: $font_prim;
            @include font-size(14);
            color: $grey_01;

            @include min-screen($lg){
                @include font-size(14);
            }

            box-shadow: inset 0 -4px 0 $grey_04;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_04;
            }

            &:hover {
                text-decoration: none;
                transition: 0.2s ease-in-out;
                box-shadow: inset 0 -4px 0 $grey_03;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_03;
                }
            }
        }

        &__form_link--cancel {
            float: right;
            margin-right: 20px;

            @include max-screen($lg) {
                display: none;
            }

            &:hover {
                text-decoration: none;
                transition: 0.2s ease-in-out;
                box-shadow: inset 0 -4px 0 $grey_03;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_03;
                }
            }
        }

        &__new_comment {
            @include max-screen($lg) {
                .onecomment__avatar {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .btn--reply {
        float: right;
        height: auto;
        padding: 9px 8px 6px;
        font: $font_prim--med;
        text-transform: none;
        @include font-size(14);
        @include letter-spacing(0);
        background-color: $grey_01;
        color: white;

        span {
            color: white;
        }

        &:hover {
            background-color: $grey_01;
            span {
                color: white;
            }
        }

        .thread__item & {
            background-color: $grey_01;
            color: white;

            &:hover {
                background-color: $grey_01;
            }
        }
    }
}

// ovo nam služi za dovođenje korisnika nakon dodavanja komentara
.paginacija_anchor {
    display:block;
    height: 0;
    opacity: 0;
    position: relative;
    top:-140px;
    width: 100%;
}

.comments__page {

    .btn_line.btn_line {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 37px;
        height: 18px;
    }

    .pagination__item--prev {
        margin: 0 0 0 5px;
    }

    .pagination__item--next {
        margin: 0 5px 0 0;
    }
}
