$color-text: #1e1e1e;
$color-active: #1197dd;
$color-border: #e0e0e0;

$m: search;
$k: customSelect;

.#{$m} {

    .column_sidebar {
        padding: 0 5px;
    }

    .main_tile {
        margin: 30px 0 0 0;

        @include min-screen($sm) {
            padding: 0 15px;
        }

        @include min-screen($lg) {
            padding: 0;
            margin: 0 0 30px 0;
        }

        &__label {

            span {
                color: $grey_02;
            }
        }
    }

    .main_label {
        margin-top: 20px;
        @include font-size(27);

        @include screen($sm, $lg - 1) {
            margin: 31px 10px 12px;
        }
    }

    .card:last-child .card__wrap {
        margin-bottom: 0;
    }

    .search_results .card.card--10:last-child .card__wrap {
        margin-bottom: 20px;
    }

    .main_tile__icon_wrap {
        position: relative;
        width: 100%;
        height: 192px;
        margin: 26px 0 6px 0;
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 80px;
            margin: auto;
            background-color: $yellow_prim;
        }

        .icon {
            position: relative;
            @include font-size(192);
            @include line-height(192);
            color: $grey_00;
        }

        @include min-screen($lg) {
            &:before {
                width: 296px;
            }
        }

        @include min-screen($xlg) {
            margin: 168px 0 174px 0;
        }
    }

    .pagination {
        @include min-screen($sm) {
            margin: 20px 0 0;
        }

        @include min-screen($lg) {
            margin:0;
        }
    }

    &__header {
        position: relative;
        padding-bottom: 20px;
        z-index: 11;

        @include min-screen($sm) {
            padding: 0 10px 20px;
        }

        @include min-screen($lg) {
            padding: 10px 5px 40px;
        }

        @include max-screen($sm - 1) {
            > .gr-12 {
                padding: 0 12px;
            }
        }
    }

    &__img_placeholder--empty {

        img {
            position: relative;
            display: block;
            width: 100%;
            max-width: 625px;
            margin: 0 auto;
            padding: 0;
        }
    }

    &__header_wrap {
        position: relative;
        margin: 0 auto;
        background: $blue_prim;

        @include screen($sm, $lg - 1) {
            width: 540px;
        }

        @include min-screen($lg) {
            margin: 0 -5px;
        }
    }

    &__header_arc {
        display: none;
    }

    &__form {
        @include min-screen($lg) {
            position: relative;
        }

        @include min-screen($xlg) {
            margin: 0 -2.4px;
        }
    }

    &__form_input {
        @include border-box();
        position: relative;
        appearance: none;
        display: block;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        background: #fff;
        font: $font_prim;
        @include font-size(14);
        @include line-height(39);
        color: $grey_01;
        border: none;
        @include border-radius(0);
        -webkit-border-radius: 0px;

        &:focus {
            border: 1px solid $grey_01;
            padding: 0 9px;
            @include line-height(36);
        }

        @include min-screen($lg) {
            height: 52px;
            @include font-size(15);

            &:focus {
                border: 1px solid $grey_01;
                padding: 0 9px;
                @include line-height(38);
            }
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $grey_02;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $grey_02;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $grey_02;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $grey_02;
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 40px;
        padding: 0;
        background: $grey_00;
        border: 0;
        outline: 0;
        border-radius: 0;

        @include min-screen($lg) {
            width: 52px;
        }

        .icon {
            position: relative;
            display: block;
            @include font-size(24);
            @include line-height(40);
            color: #fff;
        }

        &:hover .icon {
            color: $yellow_sec;
        }
    }

    &__form_label {
        position: relative;
        display: block;
        margin-top: 24px;
        padding: 0 0 6px 12px;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(20);
        @include letter-spacing(100);
        text-align: left;
        text-transform: uppercase;
        color: $grey_00;

        @include min-screen($xlg) {
            margin-top: 24px;
        }
    }

    &__form_item {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        @include cf();

        &--big {
            margin-left: -16px;
            margin-right: -16px;
        }

        &--query {
            background: #000;
        }
    }

    &__form_select_wrap {

        position: relative;
        z-index: 12;
        width: 100%;
        margin-bottom: 15px;
        padding: 0;
        background: #fff;
        border-radius: 3px;

        @include min-screen($sm) {
            width: 48%;
        }

        @include min-screen($lg) {
            width: 18%;
            margin-right: 20px;
            margin-bottom: 0;
        }

        &::after {
            content: " ";
            position: absolute;
            top: 5px;
            right: -6px;
            display: block;
            width: 1px;
            height: 30px;
        }

        &--40 {
            width: 40%;
        }
    }

    &__form_select {
        position: relative;
        display: block;
        height: 40px;
        margin: 0;
        padding: 0;
        @include cf();
    }

    &__form_select_btn {
        position: relative;
        display: block;
        padding-left: 16px;
        padding-right: 30px;
        background: #000;
        border: 1px solid #636363;
        font: $font_sec;
        @include font-size(14);
        @include letter-spacing(60);
        color: #fff;
        @include line-height(38);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include min-screen($lg) {
            @include line-height(40);
        }
    }

    &__form_select_btn_after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 40px;
        text-align: center;
        @include font-size(20);
        line-height: 4rem !important;
        line-height: 40px !important;
        z-index: 12;
        pointer-events: none;

        .icon {

            display: inline-block;
            @include font-size(20);
            position: relative;
            top: 3px;
            color: #7d7d7d;

            &.icon--close {
                display: none;
                top: 3px;
            }
        }
    }

    &__form_select_list.is_visible + &__form_select_btn_after {

        .icon--close {
            display: block;
        }

        .icon--open {
            display: none;
        }
    }

    &__form_select_list {
        position: relative;
        top: -40px;
        left: 0;
        right: 0;
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        background: #fff;
        z-index: 11; // paginacija ima veći
        border-radius: 3px;
        box-shadow: 0 2px 5px rgba(0,0,0, 0.26);

        &.is_visible {
            display: block;
        }
    }

    &__form_select_item {
        position: relative;
        display: block;
        margin: 0;
        padding: 10px 16px;
        font: $font_sec;
        @include font-size(14);
        @include line-height(20);
        @include letter-spacing(60);
        cursor: pointer;
        @include transition(0.12s);
        color: #414142;

        &:hover {
            background: #eaeaea;
        }

        a {

            &:hover {
                background: #eaeaea;
            }
        }
    }

    &__form_select_box {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &.focusable {

            &:active, &:focus {
                clip: auto;
                height: auto;
                margin: 0;
                overflow: visible;
                position: static;
                width: auto;
            }
        }
    }

    &__form_calendar_wrap {
        position: relative;
        width: 100%;
        clear: both;

        @include min-screen($lg) {
            @include border-box();
            width: 50%;
            float: left;
            clear: none;
            padding: 0 20px;
            margin: 32px 0 0 0;
        }

        @include min-screen($xlg) {
            width: 46.8965%;
        }
    }

    &__form_calendar_inner_wrap {
        z-index: 10;
        width: 48%;
        margin: 16px 0 15px 0;
        padding: 0;
        @include cf();
        background: #fff;
        color: $grey_00;
        border: none;
        font: $font_prim;

        &--start {
            float: left;
        }

        &--end {
            float: right;
        }

        @include min-screen($lg) {
            position: relative;
            width: 50%;
            margin: 0;

            &:first-child {
                margin: 0 20px 0 -20px;
            }
        }

        > div:first-child {
            position: relative;
        }
    }

    &__form_calendar_label {
        position: relative;
        display: block;
        float: left;
        margin-left: 12px;
        font: $font_prim;
        @include font-size(14);
        @include line-height(40);
        color: $grey_02;

        @include min-screen($sm) {
            margin-left: 15px;
        }

        @include min-screen($lg) {
            margin: 0 6px 0 10px;
        }
    }

    &__form_calendar {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: block;
        height: 40px;
        float: right;
        z-index: 1;

        input[type="text"], input[type="date"], input[type="password"] {
            @include border-box();
            position: relative;
            display: block;
            width: 100%;
            height: 40px;
            padding: 0 35px;
            font: $font_prim;
            @include font-size(14);
            @include line-height(40);
            color: $grey_00;
            border-radius: 0;
            border: none;
            background-color: transparent;
            font-variant-numeric: lining-nums;
            -moz-font-feature-settings: "lnum" 1;
            -moz-font-feature-settings: "lnum=1";
            -ms-font-feature-settings: "lnum" 1;
            -o-font-feature-settings: "lnum" 1;
            -webkit-font-feature-settings: "lnum" 1;
            font-feature-settings: "lnum" 1;
            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
                display: none;
                -webkit-appearance: none;
            }
        }

        .icon {
            position: absolute;
            right: 0;
            z-index: 2;
            width: 40px;
            @include font-size(24);
            line-height: 40px;
            pointer-events: none;
            color: #8b8b8b;
            text-align: center;
        }
    }

    &__form_calendar_overlay {
        position: absolute;
        top: 48px !important;
        left: 53px !important;
        right: auto !important;
        bottom: auto !important;
        display: none;
        width: 311px !important;
        height: 310px !important;
        background: #fff;
        box-shadow: 0 3px 8px rgba(0,0,0,.2);
        z-index: 2;
    }

    &__form_submit {
        margin: 9px 0 4px 0;
        padding: 0 12px;
        float: right;
        border: none;

        &:hover {
            background-color: $yellow_sec;
            color: $grey_00;
        }

        @include max-screen(359px) {
            padding: 0 12px;
            @include font-size(13);
        }

        @include min-screen($lg) {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 48px 0 0 0;
            height: 40px;
            @include line-height(40);
        }

        @include min-screen($xlg) {
            margin: 45px 0 0 0;
        }
    }

    &__form_clear_filters {
        position: relative;
        margin: 14px 0 0 0;
        padding: 0 0 0 32px;
        float: left;
        display: inline-block;
        color: #fff;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(32);
        @include letter-spacing(60);
        text-transform: uppercase;
        @include transition(0.3s);

        > .icon {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 32px;
            height: 32px;
            @include font-size(24);
            @include line-height(32);
            text-align: center;
        }

        &:hover {
            color: $grey_00;
        }

        @include max-screen(359px) {
            @include font-size(10);
        }

        @include min-screen($lg) {
            position: absolute;
            bottom: 40px;
            right: 143px;
            margin: 0;
            color: #fff;
            @include line-height(48);

            > .icon {
                @include line-height(48);
            }
        }

        @include min-screen($xlg) {
            right: 141px;
        }
    }

    &__tags {
        margin-bottom: 30px;
        @include cf();
    }

    &__tags_title {
        position: relative;
        margin: 0 0 25px;
        padding: 0;
        @include font-size(24);
        @include line-height(24);
    }

    &__paginator {
        position: relative;
        display: block;
        margin: 20px 0;
        padding: 20px 0 40px;
        text-align: center;

        &--no_top_border {
            border-top: 0;
        }
    }

    &__extra {
        position: relative;
        height: 38px;
        width: 40px;
        padding: 0;
        @include line-height(38);
        cursor: pointer;

        [class^="icon-"], [class*=" icon-"] {
            position: relative;
            top: 5px;
            @include font-size(24);
        }
    }

    .content__wrap {
        @include min-screen($lg) {
            margin: 30px auto 0 auto;
        }

        @include min-screen($xlg) {
            margin: 30px auto 0 auto;
        }
    }

    &__options_wrap {
        width: 100%;
        margin: 16px 0 0 0;

        @include min-screen($lg) {
            @include border-box();
            padding-right: 122px;
        }
    }

    &__dropdowns_wrap {
        @include min-screen($lg) {
            @include border-box();
            width: 50%;
            padding: 0 20px;
            float: left;
        }

        @include min-screen($xlg) {
            width: 53.1034%;
        }
    }

    // prebrikavanje fake form select
    .fake_form_select__wrap {
        @include min-screen($lg) {
            width: 50%;

            &:first-child {
                @include min-screen($lg) {
                    margin: 0 20px 0 -20px;
                }
            }
        }
    }

    .fake_form_select__wrap + .fake_form_select__wrap {
        margin: 16px 0 0 0;

        @include min-screen($lg) {
            margin: 32px 0 0 0;
        }
    }
}

// Override (neki bug sa pluginom i pozicioniranjem)
.gldp-default {
    position: absolute;
    top: 70px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: auto !important;
    display: block;
    width: 311px !important;
    height: 305px !important;
    margin: auto;
    box-shadow: 0 3px 8px rgba(0,0,0,.2);
    background-color: transparent;
    z-index: 2;

    @include min-screen($lg) {
        .search__form_calendar_inner_wrap--start & {
            left: 0 !important;
            right: auto !important;
        }
    }

    @include min-screen($lg) {
        .search__form_calendar_inner_wrap--end & {
            right: 0 !important;
            left: auto !important;
        }
    }

    .core.border.monyear.title {
        background-color: $grey_00;
        font: $font_prim--bold;
        @include font-size(14);
        color: #fff;
        text-transform: uppercase;
        text-shadow: none;
    }

    .core.border.dow {
        font: $font_prim--bold;
        @include font-size(12);
        color: #a3a3a3;
    }

    .core.border:not(.dow) {
        background-color: $grey_00;
        font: $font_prim--bold;
        @include font-size(16);
        background-color: #f4f4f4;

        &.selected:before {
            background-color: $grey_00;
            opacity: 1;
        }
    }

    .prev-arrow,
    .next-arrow {
        background-color: $grey_00;
        @include font-size(24);
    }

    .prev-arrow:after {
        content: "\e90b";
        color: #fff;
    }

    .next-arrow:after {
        content: "\e90c";
        color: #fff;
    }
}
