$m: weather_widget;
.#{$m} {

    padding: 0 14px;

    @include screen($sm, $lg - 1) {
        padding: 0 30px;
    }

    @include min-screen($lg) {
        padding: 0;
        margin: 80px 0 0 0;
    }

    @include min-screen($xlg) {
        margin: 0;
    }

    &__select_wrap {
        display: inline-block;
        width: 220px;
        margin: 0px auto;
        position:relative;
        z-index:2;

        option {
            font: $font_sec;
            @include font-size(16);
            @include line-height(26);
            text-transform: none;
            padding: 5px 10px;
            text-transform: none;
        }
      }

    &__label {
        color: $grey_00;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 50%;
        right: 10px;
        transform: translate(0,-50%);

        @include min-screen($lg) {
            width: 26px;
            height: 26px;
        }
    }

    &__select {
        @include border-box;
        appearance: none;
        text-indent: 1px;
        text-overflow: '';
        width: 220px;
        height: 40px;
        color: $grey_00;
        @include font-size(18);
        @include line-height(22);
        @include letter-spacing(25);
        text-transform: uppercase;
        padding: 9px 40px 9px 10px;
        background-color: $grey_04;
        border: 1px solid $grey_03;
        -webkit-border-radius: 0px;

        &:hover {
            cursor: pointer;
        }
        /* hide select arrow for IE */
        &::-ms-expand {
            display: none;
        }
    }

    &__today {
        position: relative;
        padding: 0 0 26px 0;
        border-bottom: 1px solid $grey_03;

        .#{$m}__icon_wrap {
            position: absolute;
            top: 0;
            bottom: 26px;
            @include border-box();
            width: 50%;
            max-width: 200px;
            padding: 0 20px 0 0;
            float: left;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                left: 0;
                height: 50px;
                margin: auto;
                background-color: $yellow_prim;
            }
        }

        .#{$m}__icon_figure {
            display: block;
            margin: 0;
            width: 100%;
            height: 100%;
            @include border-box();

            img,
            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: auto;
            }
        }

        .#{$m}__data_wrap {
            width: 50%;
            float: right;
        }

        .#{$m}__now {

            p {
                margin: 0;
                font: $font_prim--bold;
                @include font-size(80);
                @include line-height(76);
                @include letter-spacing(-30);
                color: $grey_00;
                font-variant-numeric: lining-nums;
                -moz-font-feature-settings: "lnum" 1;
                -moz-font-feature-settings: "lnum=1";
                -ms-font-feature-settings: "lnum" 1;
                -o-font-feature-settings: "lnum" 1;
                -webkit-font-feature-settings: "lnum" 1;
                font-feature-settings: "lnum" 1;
            }

            small {
                font: $font_sec;
                @include font-size(12);
                @include line-height(20);
                @include letter-spacing(100);
                text-transform: uppercase;
                color: $grey_02;
            }
        }

        .#{$m}__pressure,
        .#{$m}__humidity {
            margin: 8px 0 0 0;

            small {
                font: $font_sec;
                @include font-size(12);
                @include line-height(20);
                @include letter-spacing(100);
                text-transform: uppercase;
                color: $grey_02;
            }

            p {
                margin: 2px 0 0 0;
                font: $font_prim--bold;
                @include font-size(18);
                @include line-height(20);
                color: $grey_00;
                font-variant-numeric: lining-nums;
                -moz-font-feature-settings: "lnum" 1;
                -moz-font-feature-settings: "lnum=1";
                -ms-font-feature-settings: "lnum" 1;
                -o-font-feature-settings: "lnum" 1;
                -webkit-font-feature-settings: "lnum" 1;
                font-feature-settings: "lnum" 1;
            }
        }

        .#{$m}__humidity {
            margin: 14px 0 0 0;
        }
    }

    &__later {
        position: relative;
        padding: 25px 0 48px 0;

        .#{$m}__icon_wrap {
            position: absolute;
            top: 25px;
            bottom: 26px;
            @include border-box();
            width: 50%;
            max-width: 200px;
            padding: 0 20px 0 0;
            float: left;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                left: 0;
                height: 50px;
                margin: auto;
                background-color: $yellow_prim;
            }
        }

        .#{$m}__icon_figure {
            display: block;
            margin: 0;
            width: 100%;
            height: 100%;
            @include border-box();

            img,
            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: auto;
            }
        }

        .#{$m}__data_wrap {
            width: 50%;
            float: right;
        }

        .#{$m}__now {

            p {
                margin: 0;
                font: $font_prim--bold;
                @include font-size(56);
                @include line-height(60);
                @include letter-spacing(-10);
                color: $grey_00;
                font-variant-numeric: lining-nums;
                -moz-font-feature-settings: "lnum" 1;
                -moz-font-feature-settings: "lnum=1";
                -ms-font-feature-settings: "lnum" 1;
                -o-font-feature-settings: "lnum" 1;
                -webkit-font-feature-settings: "lnum" 1;
                font-feature-settings: "lnum" 1;
            }

            small {
                font: $font_sec;
                @include font-size(12);
                @include line-height(20);
                @include letter-spacing(100);
                text-transform: uppercase;
                color: $grey_02;
            }
        }

        .#{$m}__pressure,
        .#{$m}__humidity {
            margin: 8px 0 0 0;

            small {
                font: $font_sec;
                @include font-size(12);
                @include line-height(20);
                @include letter-spacing(100);
                text-transform: uppercase;
                color: $grey_02;
            }

            p {
                margin: 2px 0 0 0;
                font: $font_prim--bold;
                @include font-size(18);
                @include line-height(20);
                color: $grey_00;
                font-variant-numeric: lining-nums;
                -moz-font-feature-settings: "lnum" 1;
                -moz-font-feature-settings: "lnum=1";
                -ms-font-feature-settings: "lnum" 1;
                -o-font-feature-settings: "lnum" 1;
                -webkit-font-feature-settings: "lnum" 1;
                font-feature-settings: "lnum" 1;
            }
        }

        .#{$m}__humidity {
            margin: 14px 0 0 0;
        }
    }

    .btn {
        width: 100%;
        text-align: center;
    }
}
