$p: print_edition;

.#{$p} {
    margin: 20px 0 0 0;
    padding: 0 14px;

    @include min-screen($sm) {
        padding: 0 30px;
    }

    @include min-screen($lg) {
        padding: 0 0 60px;
        margin: 40px 0 0 0;
    }

    @include min-screen($xlg) {
        margin: 40px 0 0;
    }

    .main_tile {

        @include min-screen($lg) {
            padding: 0 15px;
        }
    }

    &--single {
         .main_tile__label {
            margin: 0 0 25px 0;

            @include min-screen($lg) {
                margin: 0 0 33px 0 !important;
            }
        }
    }

    .main_tile__label span {
        display: block;

        @include min-screen($lg) {
            display: inline-block;
        }
    }

    &__wrap {
        @include min-screen($lg) {
            display: flex;
            justify-content: center;
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    &__inner_wrap {
        @include border-box();
        padding: 0 16px;
        text-align: center;

        .print_edition__previous_editions & {
            position: relative;
            padding: 0;

            @include min-screen($lg) {
                width: 50%;
                display: table-cell;
            }
        }

        .print_edition__previous_editions & + & {
            @include max-screen($lg - 1) {
                margin: 30px 0 0 0;
            }
        }
    }

    &__image {
        @include border-box();
        display: inline-block;
        padding: 10px;
        background-color: $grey_04;
        text-align: center;

        img {
            max-width: 280px;
            width: 100%;
            height: auto;
        }

        @include min-screen($xlg) {
            padding: 20px;

            img {
                max-width: 400px;
            }

            .print_edition__previous_editions & {
                padding: 10px;

                img {
                    max-width: 280px;
                }
            }
        }
    }

    &__controls_wrap {
        @include min-screen($xlg) {
            width: 72%;
            margin: 0 auto;
        }
    }

    &__controls {
        position: relative;
        width: 100%;
        margin: 30px 0 0 0;

        @include min-screen($lg) {
            width: 80px;
            float: right;
        }
    }

    &__nav {
        height: 40px;
        float: right;
        background-color: $grey_00;

        .icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            @include font-size(24);
            @include line-height(40);
            color: #fff;
            text-align: center;

            &--disabled {
                color: $grey_02;
            }
        }

        @include min-screen($lg) {
            height: 36px;
            width: 75px;

            .icon {
                width: 36px;
                height: 36px;
                @include line-height(36);
            }
        }
    }

    &__mcalendar_wrap {
        width: 190px;
        height: 40px;
        float: left;

        @include min-screen($lg) {
            display: none;
            visibility: hidden;
        }

        input {
            display: none;
            visibility: hidden;
        }

        input:checked + .calendar {
            display: block;
        }

        .calendar {
            position: absolute;
            left: 0;
            bottom: 40px;
            width: 100%;
            background-color: #fff;
            display: none;
        }
    }

    &__mcalendar {
        position: relative;
        @include border-box();
        display: block;
        width: 100%;
        height: 40px;
        padding: 0 50px 0 10px;
        border: 1px solid $grey_03;
        font: $font_prim;
        @include font-size(14);
        @include line-height(40);
        color: $grey_02;

        .icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            @include font-size(24);
            @include line-height(40);
            text-align: center;
        }
    }

    .social__wrap {
        margin: 30px 0 0 0;
    }

    &__previous_editions {
        margin: 40px 0 0 0;

        @include min-screen($xlg) {
            width: 72%;
            margin: 40px auto 0;
        }
    }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 60px;
        margin: auto;
        background-color: $yellow_prim;
        text-align: center;

        .icon {
            @include font-size(46);
            @include line-height(60);
            color: $grey_00;
        }
    }

    /* comments teaser ispod članka */

    .article__comments {

        @include min-screen($lg) {
            padding: 33px 0 33px 33px;
        }

        .thread {
            &__form {
                padding: 0;

                @include min-screen($lg){
                    padding-left: 75px;
                }

                &.is_authenticated {
                    position: relative;

                    span {
                        @include min-screen($lg) {
                            box-sizing: border-box;
                            display: inline-block;
                            margin-bottom: 0;
                            width: 100%;
                            padding-right: 200px;
                        }
                    }

                    .thread__comment_btn {
                        @include min-screen($lg) {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translatey(-50%);
                        }
                    }
                }

                &.no_posts {
                    @include min-screen($lg){
                        padding-left: 10px;
                    }
                }
            }

            &__message {
                &--title {
                    @include min-screen($lg) {
                        @include font-size(26);
                        @include line-height(30);
                        @include letter-spacing(-10);
                        padding: 0;
                    }
                }

                &--content {
                    @include font-size(14);
                    @include line-height(26);
                }

                &--unlogged {
                    margin: 0 0 20px;
                }
            }

            &--thread_teaser {
                padding: 0;
            }
        }

        .commbox__wrap {
            @include min-screen($lg) {
                padding: 0px 20px 30px 0px;
            }
        }
    }
}
