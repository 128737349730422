$m: article;

.#{$m} {
    .hidden_mobile {
        display: inline;

        @include max-screen($lg) {
            display: none;
        }
    }

    .hidden_desktop {
        display: inline;

        @include min-screen($xlg) {
            display: none;
        }
    }

   /* dodatni padding na md rezoluciji */
    .padding_wrapper {
        padding-left: 0px;
        padding-right: 0px;

        @include min-screen($sm) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include min-screen($lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__header {
        position: relative;
        overflow: hidden;
        margin: 0;
        z-index: 1;
        padding: 0 12px;
        @include font-size(12);
        @include letter-spacing(60);

        i {
            @include transition(0.2s);
            margin: 0 5px 0 0;
            @include font-size(24);
            @include line-height(24);
            vertical-align: middle;
        }

        @include min-screen($sm) {
            padding: 0 30px;
        }

        @include min-screen($lg) {
            padding: 0;
        }

        @include min-screen($xlg) {
            overflow: visible;
        }
    }

    &__back {
        margin-top: 0;
        display:block;
    }

    &__header_back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        font: $font_prim--bold;
        text-align: center;

        @include min-screen($lg) {
            left: 15px;
            right: 15px;
        }

        @include max-screen($xlg) {
            box-sizing: border-box;
            background-color: $grey_05;
        }

        @include min-screen($xlg) {
            top: auto;
            bottom: 0;
            left: -84px;
            right: auto;
            margin-bottom: -247px;

            .has_paginator & {
                margin-bottom: -226px;
            }
        }

        .icon_wrap {
            width:20px;
            height: 20px;
            display: inline-block;
            @include transition(0.2s);
            margin-right: 8px;

            @include min-screen($xlg) {
                display: block;
                width: 60px;
                height:60px;
                background-color: $grey_05;
                margin-bottom: 10px;
                margin-right: 0;
            }

            &:hover {
                @include min-screen($xlg) {
                    background-color: $grey_01;
                    color: white;
                }
            }

            .icon {
                @include font-size(20);
                @include line-height(40);
                margin-right: 0;
                top: 0;

                @include min-screen($lg) {
                    @include font-size(24);
                    @include line-height(50);
                }
                @include min-screen($xlg) {
                    @include font-size(60);
                    @include line-height(60);
                }
            }
        }

        span {
            @include max-screen($xlg){
                @include line-height(50);
            }

            @include font-size(12);
            @include line-height(20);
            @include letter-spacing(100);
            text-transform: uppercase;
        }
    }

    &__header_data {
        padding: 2px 0 2px 0;
        margin-top: 10px;
        border-bottom: 4px solid $grey_04;

        @include min-screen($lg){
            padding: 18px 0 8px 0;
            margin-top: 2px;
        }

        @include min-screen($xlg){
            margin-top: 2px;
            padding: 37px 0 8px 0;
        }
    }

    &__sections {
        display: none;
        font-size: 0;
        white-space: nowrap;

        @include min-screen($lg) {
            display: inline-block;
            text-transform: uppercase;
        }
    }

    &__sections_link {
        margin: 0 8px;
        font: $font_prim--light;
        @include font-size(24);
        @include line-height(40);
        @include letter-spacing(-25);

        color: $grey_01;

        &:first-child {
            margin-left: 7px;
            margin-right: 8px;
        }

        i {
            @include font-size(25);
            position: relative;
            top: -2px;
            margin: 0;
        }

        &:hover {
            color: #000;

            i {
                color: #000 !important;
            }
        }
    }

    &__sections_separ  {
        padding: 0 3px;
        font: $font_prim--light;
        @include font-size(24);
        @include line-height(40);
        @include letter-spacing(-25);
        color: $grey_03;
    }

    &__views {
        font: $font_prim;
        @include font-size(12);
        @include line-height(24);
        @include letter-spacing(100);
        font-feature-settings: "lnum";
        font-variant-numeric: lining-nums;
        color: $grey_01;

        strong {
            font: $font_prim--bold;
        }

        @include min-screen($lg) {
            float: right;
            position: relative;
            top: 8px;
        }

        .icon-eye-outline {
            display: none;

            @include min-screen(360px) {
                display: inline;
            }
        }
    }

    &__authors {
        display: inline-block;
        text-transform: uppercase;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(20);
        @include letter-spacing(100);
        color: $grey_03;

        @include min-screen($lg) {

            margin: 13px 0 15px 0;

            a {
                @include font-size(12);
            }
        }

        a {
            position: relative;
            font: $font_sec;
            margin: 0 0 0 5px;
            text-transform: none;
            @include font-size(16);
            @include line-height(20);
            @include letter-spacing(50);
            color: $grey_01;

            &:first-of-type {
                margin-left: 5px;
            }
        }
    }

    &__time {
        position: relative;
        display: none;
        margin: 0 0 0 11px;
        padding: 0 0 0 20px;
        font: $font_prim--bold;

        @include font-size(12);
        @include line-height(20);
        @include letter-spacing(100);
        text-transform: uppercase;
        color: $grey_03;

        @include min-screen($lg) {
            display: inline-block;
            @include font-size(12);
            @include line-height(20);
            @include letter-spacing(100);
        }
    }

    &__time_small {
        display: block;
        float: right;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(24);
        text-transform:uppercase;
        color: $grey_03;

        @include min-screen($lg) {
            display: none;
        }
    }

    &__title {
        margin: 0;
        padding: 14px 0 11px 0;
        font: $font_prim--bold;
        @include font-size(29);
        @include line-height(34);
        @include letter-spacing(0);
        color: $grey_01;

        @include min-screen($lg) {
            padding: 21px 0 6px;
            @include font-size(38);
            @include line-height(44);
            @include letter-spacing(-25);
        }

        @include min-screen($xlg) {
            padding: 24px 0px 13px;
            @include font-size(46);
            @include line-height(54);
        }
    }

    &__leadtext {
        position: relative;
        margin: 22px 0 26px;
        padding: 0;
        font: $font_sec--light_it;
        @include font-size(20);
        @include line-height(28);
        @include letter-spacing(10);
        color: $grey_00;

        @include min-screen($lg) {
            margin: 22px 0 8px ;
            padding-left: 0px;
            @include font-size(20);
            @include line-height(28);
        }

        @include min-screen($xlg) {

            margin: 36px 0 18px;
            @include font-size(24);
            @include line-height(38);

            &:before {
                left: -70px;
            }
        }

        &:before{
            content: " "; /* 1 */
            display: table;
            clear:both;
        }
    }

    &__data {
        padding: 20px 0;
    }

    &__back {
        display: block;

        &--desk {
            color: #000;

            [class^="icon-"], [class*=" icon-"] {
                position: relative;
                top: 0;
                margin-right: 0;
                @include font-size(40);
            }
        }
    }

    &__figure {
        position: relative;
        margin: 18px 0 0;
        background: $grey_04;
        overflow: hidden;

        @include min-screen($lg){
            margin: 9px 0 0;
        }

        &--empty {
            padding-bottom: 0;
        }
    }

    &__figure_img {
        display: block;
        width: 100%;
        height: auto;

        img {
            width: 100%;
        }
    }

    &__figure_caption {
        position: relative;
        display: inline-block;
        padding: 9px 12px 12px 45px;
        font: $font_sec;
        float: left;
        word-break: break-all;
        @include font-size(14);

        @include min-screen($lg) {
            padding: 12px 25px 12px 47px;
            @include font-size(12);
            @include line-height(12);
            @include letter-spacing(50)
        }

        i, .icon {
            position: absolute;
            top: 13px;
            left: 8px;
            display: block;
            margin: 0;
            @include font-size(24);
            @include line-height(16);
            vertical-align: middle;
            color:$grey_00;
            font-style: normal;

            .ck_image_in_article--left50 & {
                color: $grey_03;
            }
        }

        &-text{
            color: $grey_01;
            @include line-height(16);
        }

        &-author{
            font: $font_prim--bold;
            @include font-size(12);
            @include line-height(24);
            @include letter-spacing(100);
            color: $grey_01;
            text-transform: uppercase;
            word-break: none;
            padding-right: 6px;
            @include line-height(16);

            .ck_image_in_article--left50 & {
                color: $grey_03;
            }
        }
    }

    &__figure_more {
        position: absolute;
        bottom: 0px;
        right: 0px;
        height: 40px;
        padding: 0px 0px 0px 20px;
        background: $grey_00;
        font: $font_sec;
        @include font-size(13);
        @include line-height(40);
        @include letter-spacing(100);
        color: #fff;

        a {
            color: white;
            font: $font_sec;
            @include font-size(14);
            @include line-height(20);
        }

        i, .icon {
            vertical-align: middle;
            margin: 0 5px;
            @include opacity(0.5);
            @include font-size(24);
            font-style: normal;
        }

        .active {
            @include opacity(1);
        }

        &:hover {
            @include opacity(1);
        }

        @include min-screen($lg) {
            @include border-box;
            @include font-size(15);
        }

        @include min-screen($xlg) {
            @include font-size(15);
            bottom: 0;
            right: 0;
        }
    }

    &__body {
        margin: 0;
        @include max-screen($sm - 1) {
            padding: 0 12px;
        }

        @include min-screen($lg){
            padding: 0 40px;
        }
        @include min-screen($xlg) {
            padding: 0 50px;
        }

        .main_tile__label {

            &:before {
                display: none;
            }

            @include max-screen($lg) {
                margin: 0;
            }

            @include min-screen($xlg) {
                padding: 26px 0 27px 30px;
                margin-bottom: 0;
            }
        }

        .main_tile__content {
            position: relative;

            @include min-screen($lg) {
                padding: 15px 0;
            }
        }
    }

    &__aditionl_content {

        iframe {
            width: 100%;
        }

        .js_iframeWrapper {
            position: relative;
            height: 0;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }
        }
    }

    &__sponsor{
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(20);
        @include letter-spacing(100);
        text-transform: uppercase;
        margin-top: 20px;
        float: left;

        @include min-screen($lg){
            float: right;
            @include flexbox;
            @include align-items(center);
            margin-top: 0;
        }

        &--text {
           margin-right: 17px;
        }
        &--img {
            width: 105px;
            height: auto;
        }
    }
    &__content {
        color: $grey_00;
        position: relative;
        font: $font_prim;

        strong, b {
            font: $font_prim--bold;
        }

        em, i {
            font: $font_prim--it;
        }

        @include font-size(16);
        @include line-height(28);

        @include min-screen($lg) {
            @include font-size(16);
            @include line-height(28);
        }

        @include min-screen($xlg) {
            padding: 0;
            @include font-size(17);
            @include line-height(30);
        }

        p {

            margin: 0 0 16px;
            padding: 0;

            @include min-screen($lg) {
                margin: 0 0 17px;
                &:first-of-type{
                    padding-top:20px;
                }
            }

            @include min-screen($xlg) {
                margin: 0 0 20px;
            }
        }

        a {

            position: relative;
            color: $grey_01;
            box-shadow: inset 0 -4px 0 $grey_00;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_00;
            }

            &:hover {
                text-decoration: none;
                transition: 0.2s ease-in-out;
                box-shadow: inset 0 -4px 0 $grey_03;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_03;
                }
            }

            @each $site, $specs in $section_colors {
                $color: nth($specs, 1);

                .article.article--#{$site} & {

                    box-shadow: inset 0 -4px 0 $color;

                    @media screen and (-webkit-min-device-pixel-ratio:0) {
                        box-shadow: inset 0 -3px 0 $color;
                    }

                    &:hover {
                        $colorHover: nth($specs, 2);
                        box-shadow: inset 0 -4px 0 $colorHover;

                        @media screen and (-webkit-min-device-pixel-ratio:0) {
                            box-shadow: inset 0 -3px 0 $colorHover;
                        }
                    }
                }
            }
        }

        .js_lightbox > a {
            box-shadow: none !important;
        }

        u {
            text-decoration: none;
            border-bottom: 3px solid $yellow_prim;
        }

        h2 {
            font: $font_prim--bold;
            padding: 10px 0 18px 0;
            @include font-size(20);
            @include line-height(28);

            @include min-screen($lg) {
                padding: 9px 0 19px 0;
                @include font-size(20);
                @include line-height(28);
            }

            @include min-screen($lg) {
                padding: 4px 0 18px 0;
                @include font-size(24);
                @include line-height(38);
            }
        }

        h3 {
            font: $font_prim--bold;
            padding: 11px 0;
            @include font-size(18);
            @include line-height(28);

            @include min-screen($lg) {
                padding: 9px 0 11px;
                @include font-size(20);
                @include line-height(30);
            }
        }

        h4 {
            margin-bottom: 0;
            padding: 7px 0 0;
            font: $font_prim--bold;
            @include font-size(16);
            text-transform: uppercase;

            @include min-screen($lg) {
                @include font-size(17);
                @include line-height(30);
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        iframe {
            max-width: 100%;
            width: 100%;

            @include min-screen($lg) {
                margin-bottom: 30px;
            }
        }

        blockquote{
            position: relative;
            margin-top: 37px;
            margin-bottom: 42px;

            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(26);
            text-align: center;

            @include min-screen($lg) {
                margin-bottom: 60px;
                margin-top: 43px;
            }

            &:before,
            &:after{
                content: '';
                display: block;
                width: 80%;
                height: 3px;
                background-color: $yellow_prim;
                position: absolute;
                left: 10%;
                bottom: auto;
                top: -5px;
            }

            &:after{
                @extend .cf;
                bottom: -25px;
                top: auto;
            }
        }

        .quote {
            @include line-height(26);
            @include font-size(20);
            font: $font_prim--bold;
            margin: 20px 0;

            @include min-screen($md) {
                @include font-size(18);
                @include line-height(26);
            }

            &.right,
            &.left {

                @include min-screen($md) {
                    float: left;
                    margin: 0 40px 8px -70px;
                    width: 36%;
                }
            }

            p {
                margin: 0;
            }
        }

        table {
            border: 0;
            width: 100% !important;

            tbody {
                border-color: #ccc;
            }

            td {
                padding: 5px;
            }
        }

        ul {
            padding: 0 20px;
            margin: 0 0 13px;

            @include min-screen($lg) {
                margin: 0 0 15px;
                padding: 0 30px;
            }
        }

        ul li {
            margin: 0;
            padding: 0 10px 0;

            @include min-screen($lg) {
                padding: 20px 20px 0;
            }
        }
    }

    &__tag {

        margin: 25px 0 15px 0;

        @include min-screen($lg) {
            margin: 25px 210px 40px 0;
        }

        @include min-screen($xlg) {
            margin:  25px 210px 40px 0;
        }
    }

    .btn_line {
        margin-left: -0;
        margin-right: -0;
        height: 18px;

        &--kewords {
            position: relative;
            height: auto;

            @include max-screen($lg - 1) {
                &.has_correction {
                    height: auto;
                    padding-bottom: 20px;
                    margin-bottom: 34px;
                }
            }
        }

        @include min-screen($lg) {
            margin-left: -40px;
            margin-right: -40px;

            &--kewords {
                height: 10px;
                padding-left: 40px;
            }
        }

        @include min-screen($xlg) {
            margin-left: -50px;
            margin-right: -45px;

            &--kewords {
                padding-left: 50px;
            }
        }

        &--empty {
            margin-top: -8px;
        }
    }

    &__tag_name {
        display: inline-block;
        height: 24px;
        padding: 0 7px;
        margin-right: 4px;
        margin-bottom: 4px;
        float: left;
        font: $font_prim--med;
        @include font-size(11);
        @include line-height(24);
        @include letter-spacing(100);
        color: $white;
        text-transform: uppercase;
        background: $grey_01;
        @include transition(0.2s);

        span {
            color: $grey_02;
            margin-right: 2px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__correction {

        position: absolute;
        left: 0;
        bottom: -22px;

        @include min-screen($lg) {
            top: -7px;
            right: 40px;
            left: auto;
            bottom: auto;
        }

        @include min-screen($xlg) {
            top: -7px;
            right: 50px;
            left: auto;
            bottom: auto;
        }

        .icon {
            @include font-size(24);
        }
    }

    &__video {
        @include aspect-ratio(16, 9);

        // youtube iframe
        iframe {
            display: block;
            margin: 10px 0;
            width: 100%;
            height: 100%;
        }
    }

    &__related {
        display: none;
        float: left;
        width: 190px;
        margin: 0 40px 20px -70px;
        background: #f7f7f7;
        height: 0;

        @include min-screen($md) {
            display: inline-block;
            height: auto;
        }
    }

    &__extra_content {
        clear: both;
        margin: 40px 0;
    }

    // Styling za kolumnu
    &__column {
        &_data {
            display: inline-block;
            margin: 10px 0;
            height: 64px;
            background: #FFF;
            overflow: hidden;
            @include border-box();
            @include min-screen($md) {
                margin: 20px 0 0 0;
            }
        }

        &_author {
            float: left;
            height: 64px;
            padding: 10px 15px;
            overflow: hidden;
            @include border-box();

            &_image {
                float: left;
                width: 64px;
                height: 64px;
            }
            &_label {
                @include font-size(16);
                @include line-height(22);
                color: $grey_01;
                font-style: italic;
            }
            &_name {
                @include font-size(16);
                @include line-height(22);
                color: $grey_04;
                font-style: italic;
            }
        }
    }

    .container--latestfromsection {

        .main_tile__label {
            margin-bottom: 15px;

            @include min-screen($lg) {

                margin-bottom: 33px;

            }
        }

        .main_tile__content {
            margin-top: 10px;

            @include min-screen($lg) {
                margin-top: 20px;
            }
        }
    }

    .thread {

        margin: -35px 0 0;

        @include min-screen($sm){
            margin: -40px 0 0;
        }

        @include min-screen($lg){
            margin: -48px -41px 0;
        }
        @include min-screen($xlg) {
            margin: -58px -50px 0;
        }
    }

    .magnifier {
        position: relative;
        bottom: 35px;
        background-color: $grey_01;
        color: $white;
        border: none;
        font-size: 1.1rem;
        letter-spacing: 0.1em;
        font-weight: bold;
        display: flex;
        align-items: center;

        .icon-magnify {
            font-size: 2.1rem;
            font-style: normal;
        }

        &.close_button {
            position: absolute;
            right: 12px;
            top: 12px;
            height: 40px;
            width: 40px;
            display: block;
            font-size: 2rem;
            color: $grey_01;
            background-color: $yellow_prim;
            padding: 0;

            & .icon-close {
                display: block;
                line-height: 4rem;
            }
        }
    }

    .mfp-container.mfp-s-ready.mfp-inline-holder {
        padding: 12px;
    }

    .mfp-content {
        text-align: center;
    }
}

body.article {

    .thread__item {

        .commbox__wrap   {
            padding: 0;
            padding: 20px 0 18px 0;

            @include min-screen($lg) {
                padding: 20px 40px 18px;
            }

            @include min-screen($xlg) {
                padding: 24px 55px;
            }
        }

        .commbox__content {
            margin-top: 0;
        }
    }
}

.article {

    .topnews_widget {
        padding: 0;

        .main_label {

            @include min-screen($lg) {
                margin-top: 20px;
            }

            @include min-screen($xlg) {
                margin-top: 39px;
            }
        }
    }
}

.article_paginator {
    height: 32px;
    margin: 50px 0 0;
    padding: 0;
    position: relative;

    @include max-screen($sm) {
        margin: 0 -15px;
    }

    &__continued {
        display: inline-block;
        margin: 20px 0 0;
        font: $font_sec;
        @include font-size(16);
        @include line-height(32);
        @include letter-spacing(25);

        @include min-screen($lg) {
            margin-top: 16px;
        }

        strong {
            font: $font_prim--bold;
        }

        + .article__content p:first-of-type {
            padding-top: 0;
            margin-top: 21px;
        }
    }

    &__label {
        display: none;
        height: 32px;
        margin-left: 7px;
        font: $font_sec;
        @include font-size(13);
        @include line-height(32);
        @include letter-spacing(25);

        @include min-screen($lg) {
            display: inline;
        }
    }

    &__list {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        font-size: 0;
        list-style-type: none;
        background: #f4f4f4;

        @include min-screen($lg) {
            float: right;
        }
    }

    &__previous,
    &__next,
    &__number {
        display: inline-block;
        height: 32px;
        @include line-height(32);
        text-align: center;
    }

    &__previous,
    &__next {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        color: $grey_01;
        z-index: 2;

        .icon {
            @include font-size(22);
            position: relative;
            top: 5px;
        }
    }

    &__number {
        @include border-box();
        width: 100%;
        padding: 0 40px;
        margin: 0;
        font: $font_prim--bold;
        @include font-size(14);
        @include line-height(32);
        @include letter-spacing(10);
        color: $grey_01;
        z-index: 1;

        @include min-screen($lg){
            padding: 0;
            width: 200px;
        }
    }

    &__next {
        left: auto;
        right: 0;
    }
}

div.table{
    &__tablet {
        @include min-screen($lg){
            display: table;
            width: 100%;
        }

        .table__cell{
            @include min-screen($lg) {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    &--center{
        vertical-align: middle;
    }

    &__cell{

        &--left{
            text-align: left;
        }
        &--right{
            text-align: right;
        }
    }
}

.article_paginator + .article__tag {
    margin: 25px 0 15px 0;

    @include min-screen($lg) {
        margin: -32px 210px 40px 0;
    }

    @include min-screen($xlg) {
        margin:  -32px 210px 40px 0;
    }
}

// article sidebar overrides
.main_tile--top_news {
    .main_tile__content {

        @include screen($sm, $lg - 1) {
            margin-top: 25px;
            padding: 0 105px;
        }
    }
}

.fold_article__latest_articles,
.fold_article__related_articles,
.article__content_exchange {
    margin-bottom: 20px;

    padding-left: 12px;

    @include min-screen($sm) {
        margin-bottom: 0;
        padding-left: 30px;
    }

    @include min-screen($lg) {
        margin-bottom: 30px;
        padding-left: 0;
    }

    .main_label,
    .cat_title {

        margin-left: 0;
        margin-right: 12px;

        @include min-screen($sm) {
            margin-right: 30px;
        }
        @include min-screen($lg) {
            margin-right: 0;
        }
    }
}

.article__content_exchange {

    .cat_title {

        margin-left: 0;
        margin-right: 12px;

        @include min-screen($sm) {
            margin-right: 25px;
            margin-top: 22px;
        }

        @include min-screen($lg) {
            margin-right: 0;
            margin-top: 22px;
        }

        @include min-screen($xlg) {
            margin-right: 0;
            margin-top: 42px;
            padding-bottom: 8px;
        }
    }
}

.fold_article__latest_articles,
.fold_article__related_articles {

    .main_label {

        margin-right: 12px;

        @include min-screen($sm) {
            margin-right: 25px;
            margin-top: 0;
            margin-bottom: 15px;
        }

        @include min-screen($lg) {
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 15px;
        }

        @include min-screen($xlg) {
            margin-top: 16px;
        }
    }

    @include max-screen($lg - 1) {

        .card--09,
        .card--11 {

            border-bottom: none;
            position: relative;

            &:after {
                content: " ";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 25px;
                height: 0;
                border-bottom: 1px solid $grey_04;
            }

            .card__data,
            .card__label {
                padding-left: 0;
            }
        }
    }

    @include max-screen($sm - 1) {

        .card--09,
        .card--11 {

            &:after {
                right: 12px;
            }
        }
    }

    .gr-12:last-child .card--11 {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;

        &:after {
            display: none;
        }
    }
}

.fold_article__latest_articles {
    .main_label {
        margin-bottom: 0;

        @include min-screen($lg) {
            margin-bottom: 15px;
        }

        @include min-screen($xlg) {
            margin-bottom: 31px;
        }
    }
}

@include max-screen($lg - 1) {

    .fold_article__related_articles {

        margin: 0 -15px;

        .main_tile__content {
            margin: 0;
        }
    }
}

.fold_article__related_articles {
    margin-bottom: 30px;

    .main_tile__content {
        @include max-screen($lg - 1) {
            margin: 0;
        }
        @include max-screen($sm - 1) {
            margin: 0
        }
    }

    .card:last-child {
        border-bottom: 0;
        &:after {
            display: none;
        }
    }
}

.social__wrap {

    &.social_top,
    &.social_bottom {
        text-align: left;
        margin: 15px 0 0 0;

        @include min-screen($lg) {
            margin: 25px 0 0 0;
        }
    }

    &.social_bottom {
        margin: 25px 0 0 0px;

        @include min-screen($lg) {
            margin: 42px 0 0 0px;
        }

        @include min-screen($xlg) {
            margin: 54px 0 0 0px;
        }
    }
}

.fb_wrap,
.tw_wrap {
    display: inline-block;
    position: relative;
}

.fb_wrap {

    &.fb_like_page {
        top: 9px;
        float: right;
        @include max-screen($lg - 1) {
            display: none;
        }
    }
}

.tw_wrap {
    top: 4px;
    left: 5px;

    &:not(*:root) {
        top: 5px;
    }

    /* IE 10+ */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
       top: 0px;
    }

    @supports (-ms-accelerator:true) {
        top: 2px;
    }
}

.article .card--11 {
    .card__overtitle {
        margin-bottom: 11px;
    }
}

#article_correction {

    .article__correction_text {
        padding: 5px;
        @include line-height(20);
        min-height: 52px;
    }

    .modal__footer {
        text-align: right;
    }

    .modal__link {
        margin-right: 10px;
    }
}
