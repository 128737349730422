.leadblock {

    position: relative;
    z-index: 11;
    font-size: 0;

    &__wrap {
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        height: 512px;

        @include min-screen($lg) {
            margin: 0 -5px 0 -5px;
            height: 436px;
        }
        @include min-screen($xlg) {
            margin: 8px -5px 0 -5px;
            height: 817px;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    &__text_wrap {
        padding: 0 30px;
        margin: 21px 0 5px 0;
        float: left;

        @include min-screen($lg) {
            padding: 0 30px 0 15px;
            margin: 16px 15px 92px 0;
            border-left: 1px solid #fff;
        }
    }

    &__data_wrap {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;

        @include min-screen($xlg) {
            bottom: 40px;
        }
    }

    &__data {
        position: relative;
        z-index: 2;
        text-align: center;
    }

    &__overtitle {
        display: inline-block;
        margin: 0 0 5px;
        padding: 0 10px;
        font: $font_prim--bold_it;
        text-transform: uppercase;
        @include font-size(12);
        @include line-height(25);
        @include letter-spacing(100);
        color: $grey_00;
        background-color: $yellow_prim;

        @include min-screen($lg) {
            margin: 0 0 15px;
            padding: 0 8px;
        }

        @include min-screen($xlg) {
            margin: 0 0 19px;
            padding: 0 20px;
        }
    }

    &__title {
        clear: both;
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        font: $font_prim--bold;
        @include font-size(28);
        @include line-height(28);
        @include letter-spacing(-25);

        @include min-screen($lg) {
            @include font-size(48);
            @include line-height(52);
        }

        @include min-screen($xlg) {
            @include font-size(56);
            @include line-height(60);
        }

        h1 {
            display: inline;
            padding: 0 14px;
            box-decoration-break: clone;
            font: inherit;
            font-size: inherit;
            font-weight: inherit;
            color: $white;
            background: $grey_01;

            @include min-screen($lg) {
                padding: 0 8px;
            }

            @include min-screen($xlg) {
                padding: 0 20px;
            }

            span {
                position: relative;
            }
        }
    }

    &_sponsor {
        @include border-box;
        position: relative;
        display: inline-block;
        width: 200px;
        height: 116px;
        padding: 20px;
        font: $font_sec;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        background-color: transparent;
        margin: 36px 0 0 50%;
        transform: translateX(-50%);

        @include min-screen($lg) {
            width: 200px;
            height: 116px;
            margin: 29px 0 0 50%;
            @include font-size(18);
            padding: 20px;
        }

        @include min-screen($xlg) {
            width: 256px;
            height: 128px;
            margin: 38px 0 0 50%;
        }

        &__label {

            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $yellow_prim;
            color: $grey_01;
            padding: 0 5px;
            @include font-size(11);
            @include line-height(24);
        }

        &__sponsor {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;

            img  {
                display: inline-block;
                width: 100%;
                height: auto;
                max-width: 76px;

                @include min-screen($xlg) {
                    max-width: 88px;
                }
            }
        }
    }
}

.container__flex {
    @include min-screen($xlg) {
        @include flexbox;
        @include align-items(center);
        height: 100%;
    }
}
