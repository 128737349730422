.royalSlider {
    visibility: hidden;

    .jwplayer {
        height: 100% !important;
    }

    .jwcontrols {
        z-index: 50;
    }
}

.rsOverflow {
    height: 100% !important;
}

.rsSlide {
    overflow: visible;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    // image inside the slide
    .rsImg {
        height: 100%;
    }
}

.rsNavItem {
    position: relative;
    float: none;
    display: inline-block;
    width: 70px;
    padding: 0 5px;
    margin: 0 !important;
    font-size: 0;

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 5px;
        right: 5px;
        z-index: 0;
    }

    &:first-child {
        &:after {
            left: 0;
        }
    }

    &:first-child {
        padding-left: 0;
    }

    .rsTmb  {

        display: inline-block;
        position: relative;
        overflow: hidden;
    }

    picture {

        &::after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            border: 1px solid rgba(0,0,0, 0.13);
            z-index: 20;
        }
    }

    img {
        position: relative;
        width: 100%;
        z-index: 20;
        @include opacity(0.4);
    }

    &:hover,
    &.rsNavSelected {

        .rsTmb__icon,
        img {
            @include opacity(1);
        }

        &:first-child {
            &:before {
                left: 0;
            }
        }
    }
}

.rsThumbs {
    position: absolute;
    bottom: -94px;
    left: 0;
    padding: 0;
    width: 100%;
    display: none;

    @include min-screen(1024px) {
        left: 0;
        padding-left: 10px;
        bottom: 10px;
        right: 0;
        display: block;
    }

    @include min-screen(1024px) {
        position: relative;
        left: auto;
        bottom: auto;
        right: auto;
        padding: 10px 0;
        width: $slider__width;
    }
}

.rsThumbsContainer {
    position: relative;
    height: 100%;
    white-space: nowrap;
}

.rsVideoContainer {
    margin-left: 0 !important;
}

// Play btn na video slideu
$playBtnW: 60px;
$playBtnH: 60px;
$playBtnB: 2px;  // border

.rsBtnCenterer {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: calc($playBtnH / 2 * -1) 0 0 calc($playBtnW / 2 * -1);
    width: $playBtnW;
    height: $playBtnH;
}

.rsPlayBtn {

    width: $playBtnW - $playBtnB;
    height: $playBtnH - $playBtnB;
    border-radius: 50%;
    text-align: center;
    background: rgba(0, 0, 0, 0.85);
    border: $playBtnB solid #f4f4f4;
    border: $playBtnB solid rgba(255, 255, 255, 0.4);
    cursor: pointer;

    &:before {
        content: "\e6b0";
        display: inline-block;
        margin-top: 28px;
        margin-left: 8px;
        font-family: 'icomoon';
        color: #fff;
        @include font-size(40);
    }
}
