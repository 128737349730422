$header_background: $grey_05;
$text_color_dark: $grey_00;
$text_color_light: $grey_02;

$m: modal;
.#{$m} {

    min-width: 320px;
    background-color: #fff;
    box-shadow: 0 3px 8px rgba(0,0,0,.2);

    &--change_avatar{
        @include min-screen(360px) {
            width: 100%;
            max-width: 360px;
        }
    }

    @include min-screen(440px) {
        width: 400px;
        margin: 0 auto;
    }

    @include min-screen($sm) {
        width: $sm;
        margin: 0 auto;
    }

    &__header {
        position: relative;
        background-color: $header_background;

        @include min-screen($sm) {
            padding: 10px 0;
        }
    }

    &__title {
        position: relative;
        z-index: 1;
        margin: 0;
        font: $font_prim--bold;
        @include font-size(18);
        @include line-height(60);
        @include letter-spacing(25);
        text-align: center;
        color: $text_color_dark;
        text-transform: uppercase;

        span {
            color: $text_color_light;
        }
    }

    &__close_btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: 40px;
        height: 40px;
        margin: auto;
        text-align: center;

        .icon {
            @include font-size(24);
            @include line-height(40);
            text-align: center;
        }

        @include min-screen($sm) {
            right: 20px;
        }
    }

    &__content {

        padding: 0 14px;

        @include min-screen($sm) {
            padding: 0 30px;
        }

        .modal--login & {
            @include min-screen($sm) {
                width: 310px;
                margin: 0 auto;
            }
        }

        .modal--change_avatar & {
            position: relative;
            @include border-box();
            min-height: 100px;
            padding: 30px 0 0;
            text-align: center;
        }

        .btn--socialconnect {
            margin: 32px 0 0 0 !important;
        }
    }

    &__text {
        margin: 13px 0 0;
        font: $font_prim;
        @include font-size(16);
        @include line-height(30);
        color: $text_color_dark;

        span {
            color: $red;
        }

        &:first-child {
            margin: 19px 0 0;
        }

        .modal--reset & {
            text-align: center;
        }

        .modal--reset_finish &:first-child {
            margin: 13px 0;
            text-align: center;
        }

        .modal--register & {
            text-align: center;
        }

        .modal--delete & {
            text-align: center;
        }
    }

    &__btn_wrap {

        .modal--login &,
        .modal--reset &,
        .modal--register &,
        .modal--delete & {
            margin: 8px 0 0 0;

            @include min-screen($sm) {
                margin: 16px 0 0 0;
            }

            &--footer {
                text-align: center;
                margin: 20px 0 0;

                @include min-screen($sm) {
                    width: 40%;
                    margin: 0;
                    float: right;
                    text-align: right;

                    .btn--silver {
                        vertical-align: top;
                    }
                }
            }
        }

        .modal--reset_finish & {
            margin: 8px 0;

            @include min-screen($sm) {
                margin: 16px 0;
            }
        }
    }

    &__form {
        margin: 24px 0 0 0;

        .form__row {
            height: auto;
        }

        .modal--reset &,
        .modal--register &,
        .modal--delete & {
            margin: 24px auto 40px;

            @include min-screen($sm) {
                width: 310px;
            }
        }

        .modal--change_avatar & {
            margin: 0;
            padding: 0 14px;
        }

        .checkbox__label a {
            display: inline-block;
            @include line-height(14);
            text-shadow: 2px 0 0 #fff,
                        -2px 0 0 #fff;
        }
    }

    &__footer {
        padding: 20px 0 20px 0;
        margin: 39px 14px 0 14px;
        border-top: 1px solid $grey_04;

        .modal--reset & {
            margin: 0 14px;

            @include min-screen($sm) {
                margin: 0 30px;
            }
        }

        .modal--change_avatar & {
            margin: 26px 0 0 0;
            padding: 0;

            @include min-screen($sm) {
                margin: 26px 16px 0 16px;
            }
        }

        @include min-screen($sm) {
            margin: 39px 30px 0 30px;
        }
    }

    &__footer_links {
        text-align: center;

        @include min-screen($sm) {
            display: inline-block;
            width: 60%;
            text-align: left;
        }

        .modal--register &,
        .modal--reset & {
            @include min-screen($sm) {
                width: 100%;
                text-align: center;
            }
        }

        .modal--change_avatar & {
            width: 100%;
        }
    }

    &__link {
        display: inline-block;
        margin: 8px 0 0 0;
        padding-bottom: 3px;
        font: $font_prim;
        @include font-size(14);
        @include line-height(14);
        color: $text_color_dark;
        box-shadow: inset 0 -4px 0 $grey_04;

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            padding-bottom: 2px;
            box-shadow: inset 0 -3px 0 $grey_04;
        }

        &:first-child {
            margin: 0;
        }

        &--long {
            display: inline;
            position: relative;
            top: 8px;
        }
    }

    &__footer_text {
        margin: 12px 0 14px;
        font: $font_prim;
        @include font-size(14);
        @include line-height(30);
        color: $grey_00;
        text-align: center;
    }

    // promjena avatara

    &__avatar_preview {
        display: block;
        width: 130px;
        height: 130px;
        margin: 0 auto;
    }

    &__avatar_figure {
        position: relative;
        display: block;
        height: 130px;
        margin: 0;
        padding: 0;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            display: block;
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
            transform: translateY(-50%);
        }
    }

    &__loader_image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 200px;
        height: 200px;
        margin: auto;
    }

    &__note {

        &--text {
            display: block;
            padding: 28px 30px 12px 30px;
            font: $font_prim--med;
            @include font-size(12);
            @include line-height(16);
            @include letter-spacing(0);
            color: $grey_01;
            text-align: left;

            a {
                border-bottom: 1px solid #1e1e1e;
                text-shadow: 2px 0 0 #fff, -2px 0 0 #fff;

                &:hover {
                    color: #575757
                }
            }
        }
    }
}

// prebrikavanje buttona
.btn {

    &--icon {
        position: relative;
        padding: 0 60px 0 20px;

        .icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            @include font-size(20);
        }
    }

    .modal--register & .icon {
        @include font-size(24);
    }

    .modal--change_avatar .modal__avatar_preview + &--yellow {
        margin: 30px 0 5px 0;
    }
}

// prebrikavanje checkboxa
.checkbox {
    &__label {
        a {
            border-bottom: 1px solid $grey_00;
        }
    }
}
