@-webkit-keyframes glitter {

    0%, 12%, 100% {
        opacity: 0;
    }

    6% {
        opacity: 0.8;
    }
}

@keyframes glitter {

    0%, 12%, 100% {
        opacity: 0;
    }

    6% {
        opacity: 0.8;
    }

}

$m: funzone;

.#{$m} {

    &__wrap {
        position: fixed;
        bottom: 30px;
        right: 20px;
        width: 60px;
        height: 44px;
        z-index: 10;
    }

    &__link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    /* styling svg polygons */
    &__shape {

        &--1,
        &--10,
        &--11 {
            fill: #e1bf85;
        }

        &--2,
        &--12 {
            fill: #f9e8c7;
        }

        &--3 {
            fill: #e8cea7;
        }

        &--4 {
            fill: #e5ac5a;
        }

        &--5 {
            fill: #a07d4a;
        }

        &--6 {
            fill: #f6dbb4;
        }

        &--7 {
            fill: #bc934a;
        }

        &--8 {
            fill: #8e6d23;
        }

        &--9 {
            fill: #ad8234;
        }

        &--overlay {
            fill: #fff;
            opacity: 0;

            &.funzone__shape--5 {
                fill: rgba(255,255,255,0.5);
            }
        }

        &--12,
        &--3 {
            &.funzone__shape--overlay {
                animation: glitter 9s linear 4s infinite;
                -webkit-animation: glitter 9s linear 4s infinite;
            }
        }

        &--1,
        &--2 {
            &.funzone__shape--overlay {
                animation: glitter 9s linear 4.3s infinite;
                -webkit-animation: glitter 9s linear 4.3s infinite;
            }
        }

        &--5,
        &--6 {
            &.funzone__shape--overlay {
                animation: glitter 9s linear 4.6s infinite;
                -webkit-animation: glitter 9s linear 4.6s infinite;
            }
        }

        &--7,
        &--11 {
            &.funzone__shape--overlay {
                animation: glitter 9s linear 4.9s infinite;
                -webkit-animation: glitter 9s linear 4.9s infinite;
            }
        }
    }
}
