$m: alert;
.#{$m} {
    &__message {
        position: relative;
        margin: 10px 0 20px;
        padding: 10px 20px;
        color: #FFF;
        @include font-size(15);
        @include line-height(22);
        @include transition(0.2s);
        color: #fff;

        &--success {
            background: #7eab1d;
        }

        &--error {
            background: #f0371a;
        }

        &--neutral {
            background: #dedede;
            color: $grey_00;
        }

        &--info {
            background: #dedede;
            color: $grey_00;
        }

        &--hide {
            display: none;
        }
    }

    &__text{
        display: inline-block;
    }
}
