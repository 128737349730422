$m: related_article;

.#{$m} {
    display: block;
    margin: 26px -12px 26px;
    padding: 0;

    @include min-screen($sm) {
        margin: 26px 0 25px;
    }

    @include min-screen($lg) {
        width: 100%;
        margin: 26px 0 25px;
    }

    @include min-screen($xlg) {
        margin: 41px 0 40px;
    }

    a {
        padding: 0 !important;
        background-color: transparent;
        box-shadow: none;
        color: $white;
    }

    &__photo_wrap {
        display: block;
        width: 100px;
        height: 100px;
        float: left;

        a {
            display: block;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__data_wrap {
        @include border-box();
        display: block;
        width: 100%;
        padding: 0 0 0 112px;
        background: $grey_01;
    }

    &__overtitle {

        display: inline-block;
        box-sizing: border-box;
        height: 24px;
        padding: 4px 5px;
        margin-top: -5px;
        margin-bottom: 5px;
        position: relative;
        top: -5px;
        font: $font_prim--bold_it;
        @include font-size(11);
        @include letter-spacing(100);
        color: $white;
        text-transform: uppercase;

        @include min-screen($xlg) {
            height: 25px;
            padding: 5px 10px;
            @include font-size(12);
        }

        @each $site, $specs in $section_colors {
            $color: nth($specs, 1);

            .article--#{$site} & {

                background:  $color;

            }
        }
    }

    &__section {
        display: none;
    }

    &__lead_text {
        width: 100%;
        margin: 2px 0 0 !important;
        padding: 0 !important;
        float: left;
        font: $font_prim;
        @include font-size(18);
        @include line-height(20);
        color: $white;
        // Ogranicavanje fonta na 2 reda:
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 48px;
        overflow: hidden;

        a {
            display: block;
            padding: 0;
            color: $white;
            box-shadow: none !important;
        }

        @include min-screen($lg) {
            margin: 0 0 0 0 !important;
            @include font-size(20);
            @include line-height(30);
            max-height: 60px;
        }

        @include min-screen($xlg) {
            @include font-size(22);
        }
    }
}
