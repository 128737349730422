$m: recommend_widget;
.#{$m} {

    position: relative;
    padding: 30px 14px 30px 0;
    background-color: #f4f4f4;

    @include screen($sm, $lg - 1) {
        padding: 30px 30px 30px 0;
    }

    @include min-screen($lg) {
        padding: 30px 30px 30px 70px;
    }

    @include min-screen($xlg) {
        padding: 40px 40px 40px 170px;
    }

    &__outer_wrap {
        @include min-screen($lg) {
            padding: 0 15px;
        }
    }

    &__label {
        @include border-box();
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        background-color: $grey_00;

        @include min-screen($sm) {
            left: 0;
        }

        @include min-screen($xlg) {
            width: 130px;
        }
    }

    &__label_wrap {
        position: relative;
        top: 29px;
        display: block;
        height: 113px;
        width: 40px;

        span {
            position: absolute;
            left: 0;
            display: block;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(40);
            @include letter-spacing(25);
            color: #fff;
            text-transform: uppercase;
            -webkit-transform-origin: left top;
            transform-origin: left top;
            -moz-transform: rotate(-90deg) translateX(-100%) translateY(140%);
            -webkit-transform: rotate(-90deg) translateX(-100%) translateY(140%);
            transform: rotate(-90deg) translateX(-100%) translateY(0%);
        }

        @include min-screen($sm) {

            span {
                @include line-height(42);
            }
        }

        @include min-screen($xlg) {
            top: 38px;
            left: 67px;
            width: 50px;
            height: 312px;

            span {
                @include font-size(50);
                @include line-height(50);
            }
        }
    }

    &__inner_wrap {
        padding: 0 0 0 40px;

        @include min-screen($sm) {
            padding: 0 0 0 40px;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }
}
