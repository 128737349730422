.pagination {
    position: relative;
    display: block;
    padding: 14px 0;
    margin: 30px -15px 0;
    list-style: none;
    text-align: center;
    font-size: 0;
    background-color: $grey_05;

    @include min-screen($lg){
        margin: 30px 0 60px 0;
    }

    .comments__page & {
        @include min-screen($lg) {
            margin: 25px 0 16px 0;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: 0 10px;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(32);
        @include letter-spacing(10);
        color: $grey_01;
        text-transform: uppercase;
        text-align: center;

        @include max-screen(359px) {
            margin: 0 7px;
        }

        &:hover {
            .pagination__link {
                position: relative;

                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 4px;
                    background: $grey_01;
                }
            }
        }

        &--empty {
            display: none;
            width: 32px;
            border: 0;
            margin: 0;
            pointer-events: none;

            @include min-screen($md){
                display: inline-block;
            }
        }

        &--first,
        &--last {
            display: none;

            @include min-screen($md){
                display: inline-block;
            }
        }

        &--next,
        &--prev {
            cursor: pointer;
            background: none;
            border: none;
            margin: 0;
            width: auto;
            margin-right: 10px;
            @include font-size(12);

            &:hover {
                border: none;
                color: #414142;

                &::before {
                    display: none;
                }

                .pagination__link {

                    &:after {
                        display: none;
                    }

                    color: $grey_06;

                    @include max-screen($md){
                        @include font-size(0);
                    }
                }
            }
        }

        &--next {
            margin: 0 10px 0 0;
            float: right;
        }

        &--prev {
            margin: 0 0 0 10px;
            float: left;
        }

        &--active {

            background-color: $grey_01;

            .pagination__link {
                color: #fff;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &__icon {
        position: relative;
        margin: 0 5px;
        @include font-size(24);
        vertical-align: middle;
    }

    &__link {
        position: relative;
        display: block;
        height: 32px;
        z-index: 2;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(32);
        color: $grey_01;

        span {
            display: none;

            @include min-screen($lg) {
                position: relative;
                top: 1px;
                display: inline-block;
            }
        }

        .pagination__item--disabled & {
            color: #969696;
        }
    }
}
