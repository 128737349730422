// Flatpages (info/static stranice)
$m: fold;
$c: card;

.#{$m}_home_a--part1,
.#{$m}_home_a--part2,
.#{$m}_home_a--additional,
.#{$m}_home_a--recommend,
.#{$m}_home_b--part1,
.#{$m}_home_b--part2,
.#{$m}_home_b--part3,
.#{$m}_home_c--part1,
.#{$m}_home_c--part2,
.#{$m}_home_c--part3,
.#{$m}_home_d--part1,
.#{$m}_home_d--part2,
.#{$m}_home_d--part3,
.#{$m}_home--special,
.#{$m}_home--additional_four,
.#{$m}_article__latest_articles,
.#{$m}_section_feed {

    margin-top: 20px;

    .load_more {
        margin-top: 20px;
    }

    @include min-screen($lg) {
        margin-top: 30px !important;

        .load_more {
            margin-top: 0;
        }
    }

    @include max-screen($lg - 1) {
        margin-top: 0;

        .card {
            margin-top: 20px;
        }
    }

    .special_widget--with_sponsors .card.card--02b {

        @include max-screen($lg) {
            margin-top: 1px;
        }
    }

    .special_widget--with_sponsors .card_margin_top .card.card--02b {

        @include max-screen($lg) {
            margin-top: 20px;
        }
    }

    .special_widget--with_sponsors .special_widget__sponsor .card {

        @include max-screen($lg - 1) {
            margin-top: 0;
            padding-top: 18px;
        }
    }

    .special_widget--with_sponsors .special_widget__sponsor.hide_label .card {

        @include max-screen($lg - 1) {
            padding-top: 0;
        }
    }
}

.#{$m}_home_top--banner {
    .dfp_banner--Leaderboard {
        margin: 0;

        div[id$="__container__"],
        div[id$="_ad_container"] {
            margin: 40px auto 0;

            @include min-screen($lg) {
                margin: 55px auto -16px;
            }

            @include min-screen($xlg) {
                margin: 55px auto -26px;
            }
        }
    }
}

.#{$m}_home_mid--banner {
    .dfp_banner {
        margin: 0;

        div[id$="__container__"],
        div[id$="_ad_container"] {
            margin: 20px auto 0;

            @include min-screen($lg) {
                margin: 30px auto 0;
            }
        }
    }
}

.#{$m}_home_a {
    margin: 16px 0 0;
}

.#{$m}_home_a--recommend {

    @include max-screen($lg - 1) {

        margin-top: 37px;
        margin-bottom: 0;

        .cat_title, .main_label {
            padding-bottom: 8px;
        }
    }

    @include min-screen($lg) {
        margin-bottom: 20px;
    }

    @include min-screen($xlg) {
        margin-bottom: 30px;
    }
}

.#{$m}_home--additional_four,
.#{$m}_section--additional_news {

    @include max-screen($lg - 1) {

        margin-top: 30px;

        .main_label {
            margin-bottom: 0;
            padding-bottom: 7px;
        }

        .column_content {
            padding-left: 25px;
        }

        .card--09 .card__data {
            padding-left: 0;
        }

        .card--09 .card__label {
            padding-left: 0;
        }
    }

    @include max-screen($sm - 1) {
        .column_content {
            padding-left: 12px;
        }
    }

    .gr-12:last-child {
        .card:after {
            display: none;
        }
    }
}

.#{$m}_section--additional_news {
    .card--09 {
        margin-bottom: 20px;

        @include max-screen ($lg - 1) {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

.#{$m}_section_single_card {
    margin: 20px 0 0;

    @include min-screen($lg) {
        margin: 30px 0 0;
    }

    @include min-screen($xlg) {
        margin: 40px 0 0;
    }
}

.#{$m}_section__gorenjska_widgets {
    margin: 29px 0 0;
}

.#{$m}_section__avto_a--part1,
.#{$m}_section__avto_a--part2,
.#{$m}_section__avto_a--part3 {
    margin: 20px 0 0;
    
    @include min-screen($lg) {
        margin: 30px 0 0;
    }
}
.#{$m}_section__avto_a--part3,
.#{$m}_section__avto_a--part4 {

    .main_label {
        height: 50px;
        padding: 0;

        figure {
            height: 50px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.#{$m}_section__avto_a--part4,
.#{$m}_section__avto_single_card {
    margin: 30px 0 0;

    @include min-screen($xlg) {
        margin: 50px 0 0;
    }
}

.#{$m}_section__avto_a--part1,
.#{$m}_section__avto_a--part_flex {
    &.is-flex {
        @include max-screen($lg - 1) {
            display: flex;
            flex-direction: column;
        }

        .is-order-1 {
            @include max-screen($lg - 1) {
                order:  1;
            }
        }
        .is-order-2 {
            @include max-screen($lg - 1) {
                order:  2;
            }
        }
        .is-order-3 {
            @include max-screen($lg - 1) {
                order:  3;
            }
        }
        .is-order-4 {
            @include max-screen($lg - 1) {
                order:  4;
            }
        }
    }

    .card--03b {
        @include max-screen ($lg - 1) {
            margin-bottom: 20px;
        }
    }
}

.is-flex.direction-row {
    @include max-screen($lg - 1) {
        flex-direction: row !important;
    }
}

.#{$m}_home_b {

    &__outbrain {
        margin: 38px 0 0;

        @include min-screen($lg) {
            margin: 36px 0 0;
        }

        @include min-screen($xlg) {
            margin: 43px 0 0;
        }
    }

    .column_sidebar {

        .rss_feed_widget {
            .sidebar__feed--nano:after {
                @include min-screen($lg) {
                    display: none;
                }
            }

            .card--feed_sidebar {
                &:nth-child(1n + 4) {
                    @include min-screen($lg) {
                        display: none;
                        visibility: hidden;
                    }
                }

                &:nth-child(3) {
                    @include min-screen($lg) {
                        border-bottom: none;
                    }
                    .card__btn_wrap {
                        @include min-screen($lg) {
                            margin: 0 0 30px;
                        }

                        @include min-screen($xlg) {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .dfp_banner--Halfpage {
            div[id$="__container__"],
            div[id$="_ad_container"] {
                @include min-screen($lg) {
                    width: 300px;
                    margin-bottom: 30px;
                }
                @include min-screen($xlg) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.#{$m}_home_c {

    margin-top: 20px;

    @include min-screen($lg) {
        margin-top: 30px;
    }

    .column_sidebar {
        .dfp_banner--Halfpage {
            div[id$="__container__"],
            div[id$="_ad_container"] {
                @include min-screen($lg) {
                    width: 300px;
                    margin: 30px auto 0;
                }
            }
        }
    }
}

.#{$m}_home_d {

    margin-top: 20px;

    @include min-screen($lg) {
        margin-top: 30px;
    }
}

.card--03 + .card--03,
.card--03b + .card--03b,
.special_widget__sponsor + .card--03b,
.special_widget__sponsor + .special_widget__sponsor {

    @include min-screen($lg) {
        margin: 22px 0 0 0;
    }

    @include min-screen($xlg) {
        margin: 30px 0 0 0;
    }
}

.#{$m}_home_a--recommend {
    .card {
        margin-top: 0px;
    }
}

.#{$m}_home--newsletter {
    @include min-screen($lg) {
        margin-top: 20px;
    }
    @include min-screen($xlg) {
        margin-top: 30px;
    }
}

.#{$m}_home_b--part2,
.#{$m}_home_b--part3,
.#{$m}_home_c--part2,
.#{$m}_home_c--part3,
.#{$m}_home_d--part2,
.#{$m}_home_d--part3  {

    @include screen($lg, $xlg - 1) {

        .#{$c}__wrap {
            overflow: hidden;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid $grey_04;
        }

        .#{$c}__photo_wrap {
            float: left;
            margin-right: 20px;
        }

        .#{$c}__data_wrap {
            overflow: hidden;
        }

        .#{$c}__data.#{$c}__data {
            position: static;
            padding: 0;
        }

        .#{$c}__overtitle_wrap {
            overflow: hidden;
        }

        .#{$c}__title.#{$c}__title {
            display: inline-block;
            padding-top: 7px;
            font: $font_prim--bold;
            color: $grey_00;
            @include font-size(22);
            @include line-height(24);
            @include letter-spacing(-10);
            background: none;
        }

        .#{$c}__description_wrap {
            overflow: hidden;
            padding: 12px 0 0;
            margin-bottom: -6px;
        }

        .#{$c}__description {
            font: $font_prim;
            color: $grey_00;
            @include font-size(15);
            @include line-height(20);
        }

        .card:hover .#{$c}__title {
            color: #525252;
            background: none;
        }
    }

    @include max-screen($lg - 1) {

        .#{$c}__description_wrap {
            .card__description {
                display: none;
            }
        }

        .#{$c}--06,
        .#{$c}--02d {

            margin-top: 20px;

            .#{$c}__link {
                overflow: hidden;
            }

            .#{$c}__photo_wrap {
                float: right;
                width: 120px;
            }

            .#{$c}__data_wrap {
                position: static;
                overflow: hidden;
            }

            .#{$c}__data {
                position: relative;
                padding-top: 0;
            }

            .#{$c}__title,
            .#{$c}__title_highlight {
                color: $grey_00;
                background: none;
            }
        }
    }
}

.#{$m}_home_b--part2,
.#{$m}_home_c--part2,
.#{$m}_home_d--part2 {

    @include max-screen($lg - 1) {
        margin-top: 0;
    }
}

.#{$m}_home_b--part2,
.#{$m}_home_b--part3,
.#{$m}_home_c--part1,
.#{$m}_home_c--part2,
.#{$m}_home_c--part3,
.#{$m}_home_d--part1,
.#{$m}_home_d--part2,
.#{$m}_home_d--part3 {

    .card--01a,
    .card--02c,
    .card--05 {
        @include max-screen($lg - 1) {
            margin-top: 0;
        }
    }
}

.#{$m}_home_b--part3,
.#{$m}_home_c--part3,
.#{$m}_home_d--part3  {

    @include max-screen($lg - 1) {
        margin-top: 0;
    }

    @include screen($lg, $xlg - 1) {

        margin-top: 0;

        .#{$c}--02d .#{$c}__wrap {
            border: none;
            padding-bottom: 0;
        }
    }
}

.#{$m}_home_a--additional {

    @include max-screen($lg - 1) {
        .card {
            margin-top: 0;
        }

        .gr-12:first-child .card {
            margin-top: 20px;
        }
    }

    @include min-screen($lg) {

        .gr-12 { // additional razmaci
            &:nth-child(n+3) { // selekcija od 3ceg
                margin-top: 30px;
            }
        }
    }
}

.home__content_exchange {

    padding: 0 0 0 12px;

    @include min-screen($sm) {
        padding: 0 0 0 25px;
    }

    @include min-screen($lg) {
        padding: 0 15px;
    }
}

.#{$m}_article {
    &__outbrain {
        margin: 29px 0 0;

        @include min-screen($lg) {
            margin: 27px 0 0;
        }

        @include min-screen($xlg) {
            margin: 34px 0 0;
        }
    }
}

.page_title {
    h1 {
        text-transform: uppercase;
        font: $font_prim;
        color: $grey_00;
        padding: 5px 0;
        @include font-size(18);
        @include min-screen($lg) {
            @include font-size(20);
        }
    }
}

.section {
    .page_title {
        margin: 10px 12px -24px;
        
        @include min-screen($sm - 1) {
            margin: 10px 24px -24px;
        }
        
        @include min-screen($lg) {
            margin: 10px 15px -34px;
        }
    }

    &--avto { 
        .page_title {
            margin: 10px 12px -8px !important;

            @include min-screen($sm - 1) {
                margin: 10px 24px -8px !important;
            }

            @include min-screen($lg) {
                margin: 10px 15px -18px !important;
            }
        }
    }
}

.subsection {
    .page_title {
        margin: 10px 12px -8px;
        
        @include min-screen($sm - 1) {
            margin: 10px 24px -8px;
        }

        @include min-screen($lg) {
            margin: 10px 15px -18px;
        }
    }
}

.home, .home_gorenjska {
    .page_title {
        margin: 10px 12px -24px;
        
        @include min-screen($sm - 1) {
            margin: 10px 24px -24px;
        }

        @include min-screen($lg) {
            margin: 27px 15px -34px;
        }
    }
}
