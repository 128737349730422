$nav_height_sm: 56;
$nav_height_xl: 64;
$nav_link_color: #fff;
$nav_link_bg_active: #202020;
$nav_link_color_active: #fff;
$nav_link_border: #2d2d2d;
$site_link_color: #eee;
$site_height: 35;
$services_link_color: #999;

.top_navigation {

    position: absolute;
    top: 0;
    right: 0;
    z-index: 13;

    @include min-screen($lg) {
        right: auto;
        transform: translateX(-50%);
        padding: 0;
        margin: auto;
    }

    @include min-screen($xlg) {
        left: 50%;
    }

    @include screen($lg, $xlg) {
        left: 53.8%;
    }

    @include screen($xlg, 1450px) {
        left: 53%;
    }

    .home_gorenjska & {
        @include screen($lg, 1036px) {
            left: 54.5%;
        }

        @include screen(1370px, 1599px) {
            left: 55.5%;
        }

        @include min-screen(1600px) {
            left: 52.5%;
        }

        @include min-screen(1670px) {
            left: 50.5%;
        }
    }

    // glavna navigacija
    .main_nav {

        // position: relative; neka elementi budu relativni top nav elementu
        z-index: 200;
        height: $nav_height_sm + 0px;
        font-size: 0; // inline block gap fix
        -webkit-transition: padding 0.3s ease-in-out, opacity 0.3s ease-in-out;
                transition: padding 0.3s ease-in-out, opacity 0.3s ease-in-out;

        // main nav do xlg
        @include max-screen($lg - 1) {
            position: fixed;
            top: 56px;
            top: 0;
            left: 50%;
            width: 0;
            margin-left: 50%;
            height: 120%;
            z-index: 170;
            overflow: hidden;
            @include transition(all 0.4s);

            &:after {
                content: " ";
                position: absolute;
                top: 56px;
                left: 0;
                bottom: 0;
                right: 0;
                background: $grey_01;
            }

            @include min-screen($sm) {
                left: 50%;
                width: 0;
                margin-left: calc(540px / 2);
            }

            // uključena mob navigacija
            .has_navigation & {
                width: 100%;
                margin-left: -50%;

                @include min-screen($sm) {
                    left: 50%;
                    width: 540px;
                    margin-left: calc(540px / -2);
                }

                @include min-screen($lg) {
                    width: 100%;
                    margin-left: -50%;
                }
            }
        }

        @include min-screen($xlg) {
            height: $nav_height_xl + 0px;
        }

        &__btn {
            padding: 10px 0;
            text-align: center;
        }

        &__list {
            list-style-type: none;
            padding: 0;
            z-index: 10;
            white-space: nowrap;

            @include max-screen($lg - 1) {
                position: relative;
                margin-top: 64px;
                white-space: normal;
            }
        }

        &__item {
            display: inline-block;

            @include max-screen($lg - 1) {
                position: relative;
                display: block;
                text-align: center;
            }

            @include min-screen($lg) {
                margin: 0 0 0 10px;

                &:first-child {
                    margin: 0;
                }
            }

            @include min-screen($xlg) {
                margin: 0 0 0 19px;
            }

            @include screen($lg, 1036px) {
                .home_gorenjska & {
                    margin: 0 0 0 7px;
                }
            }
        }

        &__link {

            display: block;
            height: 40px;
            @include line-height ($nav_height_sm + 16);
            text-decoration: none;
            -webkit-transition: all 0.3s ease-in-out, color 0.1s ease-in-out;
                    transition: all 0.3s ease-in-out, color 0.1s ease-in-out;
            color: $grey_02;

            .home & {
                color: $white;
            }

            @each $site, $specs in $navigation_colors {
                $color: nth($specs, 1);

                .main_nav__item--#{$site} {

                    &.is_active,
                    &:hover {
                        .main_nav__link{
                            text-decoration: none;
                        }
                    }
                }
            }

            &.is_active,
            &:hover {
                text-decoration: none;
                color: $white;
            }

            @include max-screen($lg - 1) {
                @include line-height (40);
                height: 40px;
            }

            @include min-screen($lg) {

                @include border-box();
                height: 56px;
                @include line-height (56);

                &.is_active {
                    background-color: transparent;
                }

                &:hover {
                    background-color: transparent;
                }
            }

            @include min-screen($xlg) {
                height: $nav_height_xl + 0px;
                @include line-height ($nav_height_xl);
            }
        }

        &__span {
            display: inline-block;
            padding: 0 10px;
            font: $font_prim--bold;
            @include font-size(15);
            @include line-height(40);
            text-transform: uppercase;
            @include letter-spacing(0);
            -webkit-transition: font-size 0.3s ease-in-out;
                    transition: font-size 0.3s ease-in-out;

            @include min-screen($lg) {
                padding: 0 5px;
                @include font-size(12);
                @include line-height(60);
                @include letter-spacing(0);
            }

            @include screen($lg, 1185px) {
                @include font-size(13);

                .home_gorenjska & {
                    padding: 0;
                }
            }

            @include min-screen($xlg) {
                padding: 0;
                @include font-size(12);
                @include line-height($nav_height_xl+1);
            }

            @include min-screen(1600px) {
                padding: 0 -5px;
                @include font-size(15);
                @include line-height($nav_height_xl+1);
            }

            @include screen($lg, 1104px) {
                padding: 0 2px;
            }
        }

        .sub_nav {
            @include min-screen($lg) {
                display: none;
            }
        }

        .sub_nav--toggle {
            display: none;
            @include max-screen($lg - 1) {
                position: absolute;
                top: 0;
                right: 9px;
                z-index: 10;
                display: block;
                width: 40px;
                height: 100%;
                @include font-size(24);
                color: $white;
                @include line-height(40);
            }
        }
    }
}

// prikaz podnavigacije
.main_nav__link.is_active ~ .sub_nav--toggle ~ .main_nav__list--sub {
    display: block;
}

.sub_nav {

    text-align: center;

    &.main_nav__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -36px;
        z-index: 200;
        height: 36px;
        font-size: 0; // inline block gap fix

        .main_nav__item {

            .main_nav__link {

                &.sub_is_active {

                    .main_nav__span {
                        @each $site, $specs in $navigation_colors {
                            $hover_color: nth($specs, 2);

                            .main_nav__item--#{$site} & {
                                color: $hover_color;
                            }
                        }
                    }
                }
            }
        }

        @include min-screen($lg) {

            .main_nav__item {

                &.has_active,
                &:hover {

                    .main_nav__span {

                        @each $site, $specs in $navigation_colors {
                            $color: nth($specs, 2);

                            .main_nav__item--#{$site} & {
                                color: $color;
                                transition: color 0.1s ease-in-out;
                            }
                        }
                    }
                }
            }
        }

        @include max-screen($lg - 1) {
            position: fixed;
            top: 0px;
            left: 50%;
            margin-left: 50%;
            width: 0;
            height: 120%;
            z-index: 180;
            background: #fff;
            overflow: hidden;
            transform: none;
            text-decoration: none;
            -webkit-transition: left 0.4s ease-in-out, margin 0.4s ease-in-out, width 0.4s ease-in-out;
                    transition: left 0.4s ease-in-out, margin 0.4s ease-in-out, width 0.4s ease-in-out;

            @each $site, $specs in $navigation_colors {
                $color: nth($specs, 1);

                .main_nav__item--#{$site} & {

                    background: $color;
                }
            }

            @include min-screen($sm) {
                left: 50%;
                width: 0;
                margin-left: calc($sm / 2 + 0px);
            }

            @include min-screen($lg) {
                left: 50%;
                margin-left: 50%;
            }

            &.is_opened {

                width: 100%;
                margin-left: -50%;

                @include min-screen($sm) {
                    left: 50%;
                    width: $sm + 0px;
                    margin-left: calc($sm / -2 + 0px);
                }

                @include min-screen($lg) {

                    width: 100%;
                    margin-left: -50%;
                }
            }
        }
    }

    .main_nav__item {

        position: relative;
        display: inline-block;

        &:nth-child(1) .main_nav__link:before,
        &:nth-child(2) .main_nav__link:before {
            display: none;
        }

        @include max-screen($lg - 1) {
            display: block;
            text-align: center;
        }

        &--sub {

            .main_nav__link {

                @include max-screen($lg - 1) {
                    height: 40px;
                    @include line-height(40);
                }
            }
        }

        &--section {
            display: none;

            @include max-screen($lg - 1) {
                display: block;
                padding: 7px 40px 14px;
                background: $grey_01;

                .main_nav__span.main_nav__span {
                    color: $white;

                    &:hover {
                        color: $grey_02 !important;
                    }
                }
            }
        }

        &--last {
            display: none;

            @include max-screen($lg - 1) {
                display: block;
                margin-top: 7px;
            }
        }
    }

    .main_nav__link {

        display: block;
        height: 36px;
        @include line-height(36);
        color: #939393;
        text-decoration: none;
        border: none;

        @include min-screen($xlg) {
            height: 40px;
            @include line-height(40);
        }

        &:hover {

            .main_nav__span.main_nav__span {
                color: #fff;

                @include max-screen($lg - 1) {
                    color: #fff;
                }
            }
        }

        @include max-screen($lg - 1) {
            @include line-height (40);
            height: 40px;

            &:before {
                display: none;
            }
        }
    }

    .main_nav__span {

        display: inline-block;
        margin: 0 5px;
        padding: 0;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height (42);
        text-transform: uppercase;
        @include letter-spacing(0);

        @each $site, $specs in $navigation_colors {
            $color: nth($specs, 3);

            .main_nav__item--#{$site} & {

                color: $color;
            }
        }

        -webkit-transition: color 0.1s ease-in-out;
                transition: color 0.1s ease-in-out;

        @include max-screen($lg - 1) {
            padding: 0 10px;
            @include font-size(14);
            @include line-height(50);
            text-transform: uppercase;
            @include letter-spacing(0);
            color: #fff;
        }

        @include min-screen($lg) {
            font: $font_prim--med;
            @include font-size(14);
            @include line-height (36);
        }

        @include min-screen($xlg) {
            @include font-size(14);
            @include line-height (42);;
        }
    }

    &.has_active .main_nav__item {

        .main_nav__link.is_active,
        .main_nav__link:hover {

            .main_nav__span {
                color: #fff;
            }
        }
    }
}

// animacija podnavigacije
.sub_nav .main_nav__item--sub {

    &:nth-child(1) .main_nav__span--sub {
        font: $font_prim--bold;
        @include font-size(17);
        @include line-height(46);
        opacity: 0;
        transition: opacity 0.3s;
    }
}

.sub_nav.is_opened .main_nav__item--sub {

    &:nth-child(1) .main_nav__span--sub {
        opacity: 1;
        transition: opacity 0.3s ease-out 0.2s;
    }
}

.section_navigation {
    height: 0;

    @include min-screen($xlg) {
        height: 40px;
    }
}
