$m: fake_form_select;
.#{$m} {

    &__wrap {

        position: relative;
        z-index: 12;

        width: 100%;
        padding: 0;
        margin: 16px 0 0 0;

        float: left;

        &:first-child {
            margin: 0;
        }

        @include max-screen($lg - 1) {
            margin-right: 0;
            padding-right: 0;
            border: none;
            background-clip: no-clip;
        }

        @include min-screen($lg) {
            width: 20%;
            margin-right: 20px;
        }

        @include min-screen($xlg) {
            width: 18%;
            margin: 13px 20px 0 0;
        }
    }

    &__wrap + &__wrap {
        z-index: 11;
        margin: 16px 0 0 0;

        @include min-screen($lg) {
            margin: 48px 20px 0 0;
        }

        @include min-screen($xlg) {
            margin: 45px 20px 0 0;
        }
    }

    &__title {
        display: block;
        padding: 0 0 0 10px;
        margin: 6px 0 6px 0;
        font: $font_prim--bold;
        @include font-size(12);
        @include line-height(20);
        @include letter-spacing(100);
        color: $grey_00;
        text-transform: uppercase;

        &.is_hidden {
            display: none;
            visibility: hidden;

            @include min-screen($lg) {
                display: block;
            }
        }
    }

    &__list_wrap {
        position: relative;
        display: block;
        height: 40px;
        margin: 0;
        padding: 0;
        @include cf();
        background: #fff;
    }

    &__btn {
        position: relative;
        display: block;
        padding: 0 0 0 10px;
        font: $font_prim;
        @include font-size(14);
        @include line-height(40);
        cursor: pointer;
        color: $grey_01;
        z-index: 2;
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;

        .icon {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 40px;
            text-align: center;
            @include font-size(24);
            line-height: 4rem !important;
            line-height: 40px !important;
            z-index: 12;
            pointer-events: none;
            color: $grey_02;
        }
    }

    &__list.is_visible + &__icon {
        display: none;
    }

    &__list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        display: none;
        margin: 0;
        padding: 4px 0;

        list-style: none;
        background: #fff;
        z-index: 11; // paginacija ima veći
        box-shadow: 0 2px 5px rgba(0,0,0,.25);

        &.is_visible {
            display: block;
        }
    }

    &__item {
        position: relative;
        display: block;
        margin: 0;
        padding: 0 10px;
        margin: 1px 0 0 0;
        font: $font_prim;
        @include font-size(14);
        @include line-height(36);
        cursor: pointer;
        @include transition(0.12s);

        &:first-child {
            margin: 0;
        }

        &:hover {
            background: #f2f2f2;
        }

        a {

            &:hover {
                background: $grey_00;
            }
        }
    }

    &__box {

        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &.focusable {

            &:active, &:focus {
                clip: auto;
                height: auto;
                margin: 0;
                overflow: visible;
                position: static;
                width: auto;
            }
        }
    }
}
